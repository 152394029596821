import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setThirdWidthAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{
    runwayId: number;
    value: number;
  }>
) => {
  const index = state.runways.findIndex(
    (item) => item.runwayId === action.payload.runwayId
  );
  state.runways[index].width = action.payload.value;
  state.pause = true;
};

export default setThirdWidthAction;
