import { FC } from "react";
import { CounterInput } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { setPollutionDepth } from "../../../../../redux/snowtamformReducer";
import { useTranslation } from "react-i18next";

const PollutionDepth: FC<{ third: 1 | 2 | 3; runwayId: number }> = ({
  third,
  runwayId,
}) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamForm.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamForm.runways[index]?.thirds[third]?.pollutionDepth;
  });

  const dispatch = useDispatch();
  const { t } = useTranslation(["data_form", "common"]);

  return (
    <CounterInput
      _id={"pollution_depth"}
      name={"pollution_depth"}
      label={t`thirds.contaminant_depth`}
      value={value || 0}
      onChange={(val) =>
        dispatch(setPollutionDepth({ runwayId, third, value: val }))
      }
      inputProps={{
        readOnly: false,
        type: "tel",
      }}
      textAdornment={t`common:millimeter`}
      minValue={0}
      maxValue={999}
    />
  );
};

export default PollutionDepth;
