import API from "./API";
import {
  createFrictionEquipmentDto,
  FrictionEquipmentEntity,
} from "./friction-equipment.types";

class FrictionEquipment {
  private api: typeof API;

  constructor() {
    this.api = API;
  }

  async findAll(airport_code: string) {
    return this.api
      .get("/api/admin/airport/" + airport_code + "/friction-equipment")
      .then<FrictionEquipmentEntity[]>((res: any) => res.data);
  }

  async createOne(airport_code: string, data: createFrictionEquipmentDto) {
    return this.api
      .post("/api/admin/airport/" + airport_code + "/friction-equipment", {
        ...data,
      })
      .then((res: any) => res?.data);
  }

  async deleteOne(id: number, airport_code: string) {
    return this.api
      .delete(`/api/admin/airport/${airport_code}/friction-equipment/${id}`)
      .then((res: any) => res?.data);
  }
}

export default new FrictionEquipment();
