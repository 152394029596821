import { FC } from "react";
import { Collapse, DropDown, DropDownItem } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { useTranslation } from "react-i18next";
import { setRWYCC } from "../../../../../redux/snowtamform/snowtamformv2Reducer";
import { Row } from "./index";

export const rwyccValues: DropDownItem[] = [
  {
    id: 0,
    value: "0",
  },
  {
    id: 1,
    value: "1",
  },
  {
    id: 2,
    value: "2",
  },
  {
    id: 3,
    value: "3",
  },
  {
    id: 4,
    value: "4",
  },
  {
    id: 5,
    value: "5",
  },
  {
    id: 6,
    value: "6",
  },
];

type StatusProps = {
  third: 1 | 2 | 3;
  runwayId: number;
};

const Status: FC<StatusProps> = ({ third, runwayId }) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamFormV2.runways[index]?.thirds[third]?.manualRWYCC;
  });

  const dispatch = useDispatch();

  const { t } = useTranslation(["data_form", "common"]);

  return (
    <Collapse open={typeof value !== "undefined"} duration={300}>
      <Row>
        <DropDown
          name="third_status"
          label={t`data_form:thirds.condition_code`}
          items={rwyccValues}
          value={value}
          notChosenText={t`common:not_chosen`}
          onChoose={(val) =>
            dispatch(setRWYCC({ runwayId, third, value: val }))
          }
          style={{ width: "100%" }}
        />
      </Row>
    </Collapse>
  );
};

export default Status;
