import { FC, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { DropDownItem } from "@snowtam/ui";
import { useTranslation } from "react-i18next";
import SelectorDropdown from "./SelectorDropdown";

const RunwaysSelector: FC<{
  onSelect: (id: number) => void;
  runway_id?: number | null;
}> = ({ onSelect, runway_id }) => {
  const runways = useSelector(
    (state: RootState) => state.general.user?.airport.runways
  );
  const { t } = useTranslation("data_form");

  const items: DropDownItem[] | undefined = useMemo(
    () =>
      runways?.map((runway) => ({
        id: runway.id,
        value: runway.name,
      })),
    [runways]
  );

  const value = useMemo(() => {
    return items?.find((rwy) => rwy.id === runway_id);
  }, [items, runway_id]);

  return (
    <SelectorDropdown
      items={items ?? []}
      value={value}
      onChoose={(v) => {
        onSelect(v.id);
      }}
      _id={"runway_selector"}
      renderValue={(item) => (item ? `${t`rwy`} ${item.value}` : t`choose_rwy`)}
    />
  );
};
export default memo(RunwaysSelector);
