import { SnowtamFormStateTypeV2 } from "../../../@types/form";

const initialState: SnowtamFormStateTypeV2 = {
  snowbanks_taxiway: [],
  snowbanks_near_runway: [],
  taxiway_status: [],
  apron_status: [],
  comment: "",
  adhesion: "",
  snowbank_runway_position: [],
  treated: [],
  sand: [],
  snowstorm: [],
  reduced: [],
  runways: [],
  low_temperature: false,
  version: 2,
};

export default initialState;
