import { FC } from "react";
import ChangePassword from "../ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useTranslation } from "react-i18next";
import { DialogYesNo } from "@snowtam/ui";
import { reloadUser } from "../../redux/generalReducer";

const UserStatus: FC = () => {
  const status = useSelector((state: RootState) => state.general.user?.status);
  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  return (
    <>
      {status === 2 ? (
        <ChangePassword
          title={t`change_password`}
          onClose={() => null}
          onSuccess={() => dispatch(reloadUser())}
        />
      ) : null}
      {status === 0 ? (
        <>
          <DialogYesNo
            title={t`errors.warning`}
            caption={t`errors.suspended`}
            onApply={() => {
              localStorage.clear();
              sessionStorage.clear();
              window.location.replace("/");
            }}
            yesText="OK"
          />
        </>
      ) : null}
    </>
  );
};

export default UserStatus;
