import { useStore } from "react-redux";
import { RootState } from "../../../redux";
import {
  FrictionFormType,
  SnowtamFormStateTypeV2,
  ThirdData,
} from "../../../../@types/form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useCallback } from "react";
import pollutionDepth, {
  PollutionDepthSettingsType,
} from "../../../settings/pollutionDepth";

const useDataValidate = () => {
  const store = useStore();
  const { t } = useTranslation("data_form");

  const getActiveRunwayIds = (
    data?: SnowtamFormStateTypeV2
  ): number[] | undefined => {
    if (!data) return;
    return data.runways
      .filter((rwy) => typeof rwy.active === "undefined" || rwy.active)
      .map((rwy) => rwy.runwayId);
  };

  const validateFrictionForm = useCallback(
    (
      data: SnowtamFormStateTypeV2,
      activeRunways: number[]
    ): ErrorType | undefined => {
      const frictionForm = data.friction_form;
      if (!frictionForm) return;

      const isDeviceEmpty = (data: FrictionFormType): boolean => {
        return (
          ((data.first !== "0" && data.first !== "") ||
            (data.second !== "0" && data.second !== "") ||
            (data.third !== "0" && data.third !== "")) &&
          (!data.device || data.device.length === 0)
        );
      };
      const isFieldsEmpty = (data: FrictionFormType): boolean => {
        return (
          (data.first === "" || data.second === "" || data.third === "") &&
          !!data.device &&
          data.device.length !== 0
        );
      };

      for (const friction_data of frictionForm) {
        if (!activeRunways.includes(friction_data.runwayId)) {
          continue;
        }
        if (isDeviceEmpty(friction_data)) {
          return {
            error: true,
            message: t`validation.choose_device`,
          };
        }
        if (isFieldsEmpty(friction_data)) {
          return {
            error: true,
            message: t`validation.empty_friction`,
          };
        }
      }
    },
    [t]
  );

  const validateUTCTime = useCallback(
    (
      data: SnowtamFormStateTypeV2,
      activeRunways: number[]
    ): ErrorType | undefined => {
      const now = dayjs().utc();
      for (const runways of data.runways) {
        if (!activeRunways.includes(runways.runwayId)) {
          continue;
        }
        if (dayjs.utc(runways.timestamp, "X").isAfter(now)) {
          return {
            error: true,
            message: t`validation.utc_date`,
          };
        }
      }
      return;
    },
    [t]
  );

  const checkPollutionDepthValueAvailable = useCallback(
    (
      value: number | null | undefined,
      availableValues: PollutionDepthSettingsType | undefined
    ): boolean => {
      if (!availableValues) return false;
      const available = availableValues.depth;
      return (
        typeof value === "number" &&
        value >= available.min &&
        value <= available.max &&
        !available.exclude.includes(value)
      );
    },
    []
  );

  const checkPollutionDepthThird = useCallback(
    (third: ThirdData) => {
      const availableValues = pollutionDepth.find((item) => {
        return item.data === third.status?.value;
      }) as PollutionDepthSettingsType | undefined;
      return checkPollutionDepthValueAvailable(
        third.pollutionDepth,
        availableValues
      );
    },
    [checkPollutionDepthValueAvailable]
  );

  const validatePollutionDepth = useCallback(
    (
      data: SnowtamFormStateTypeV2,
      activeRunways: number[]
    ): ErrorType | undefined => {
      for (const runway of data.runways) {
        if (!activeRunways.includes(runway.runwayId)) {
          continue;
        }

        for (const [third_key, third_value] of Object.entries(runway.thirds)) {
          if (!checkPollutionDepthThird(third_value)) {
            return {
              error: true,
              message: t(`validation.pollution_depth`, {
                runway: runway.name,
                third: third_key,
              }),
            };
          }
        }
      }
      return;
    },
    [checkPollutionDepthThird, t]
  );

  return useCallback((): ErrorType => {
    const data: RootState = store.getState();
    const activeRunways = getActiveRunwayIds(data.snowtamFormV2);
    if (!activeRunways) {
      return {
        error: false,
      };
    }
    if (data.general.settings?.friction_form) {
      const friction_error = validateFrictionForm(
        data.snowtamFormV2,
        activeRunways
      );
      if (friction_error) {
        return friction_error;
      }
    }

    const utc_time_error = validateUTCTime(data.snowtamFormV2, activeRunways);

    if (utc_time_error) {
      return utc_time_error;
    }

    const pollution_depth_error = validatePollutionDepth(
      data.snowtamFormV2,
      activeRunways
    );

    if (pollution_depth_error) {
      return pollution_depth_error;
    }

    return {
      error: false,
    };
  }, [store, validateFrictionForm, validatePollutionDepth, validateUTCTime]);
};

type ErrorType = {
  error: boolean;
  message?: string;
};
export default useDataValidate;
