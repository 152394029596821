import { FC } from "react";
import { BorderedCard } from "./cardStyles";
import CardLeft from "./CardLeft";
import { IconNewDocument, Switch } from "@snowtam/ui";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { setNewForm } from "../../redux/generalReducer";
import API from "../../api/API";

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 1rem;
  }
`;
const NewForm: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation("settings");
  const new_form = useSelector(
    (state: RootState) => state.general.settings?.new_form
  );

  const dispatch = useDispatch();

  const handleCheck = () => {
    API.putSetting({ new_form: !new_form }).then();
    dispatch(setNewForm(!new_form));
  };

  return (
    <BorderedCard>
      <CardLeft
        icon={() => (
          <IconNewDocument color={theme.colors.active} width={24} height={24} />
        )}
        title={t`new_form`}
        caption={t`new_form_caption`}
      />
      <Container>
        <Switch
          label={t`new_form_checkbox`}
          checked={new_form}
          onCheck={handleCheck}
          name={"new_form"}
        />
      </Container>
    </BorderedCard>
  );
};

export default NewForm;
