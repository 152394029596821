import { FC } from "react";
import styled, { useTheme } from "styled-components";
import { IconBackArrow, IconSettings } from "@snowtam/ui";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const PaperTop = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  align-items: center;
  background: ${({ theme }) => theme.colors.blocks};
  border: 1px solid ${({ theme }) => theme.colors.stroke_block};
  color: ${({ theme }) => theme.colors.font1};
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  @media (max-width: 1199px) {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    border: none;
    box-shadow: ${({ theme }) => theme.shadows.header_main};
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const TopText = styled.h1`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.375rem;
  @media (max-width: 1199px) {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

const IconContainer = styled.div`
  display: flex;
  @media (max-width: 1199px) {
    display: none;
  }
`;

const BackLink = styled.button`
  position: absolute;
  left: 39px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  padding: 16px 0;
  border: none;
  margin-right: auto;
  color: ${(props) => props.theme.colors.active};
  font-size: 1rem;
  line-height: 1.5rem;
  outline: none;
  font-weight: 600;

  svg {
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    font-size: 0.875rem;
    left: 16px;
    padding: 13px 0;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  @media (min-width: 1200px) {
    display: none;
  }
`;

const Title: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(["common", "menu"]);
  const history = useHistory();

  return (
    <PaperTop>
      <BackLink onClick={() => history.goBack()} type="button">
        <IconBackArrow />
        {t`common:back`}
      </BackLink>
      <IconContainer>
        <IconSettings
          color={theme.colors.active}
          width="1.5rem"
          height="1.5rem"
          style={{ marginRight: "1rem" }}
        />
      </IconContainer>
      <TopText>{t`menu:settings`}</TopText>
    </PaperTop>
  );
};

export default Title;
