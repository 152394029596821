import { FC } from "react";
import  { DropDown, DropDownItem } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setSnowbankRunwayPosition } from "../../../../redux/snowtamformReducer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const data: DropDownItem<{ data: string }>[] = [
  {
    id: 0,
    value: "",
    data: "",
  },
  {
    id: 1,
    value: "L",
    data: "L",
  },
  {
    id: 2,
    value: "R",
    data: "R",
  },
  {
    id: 3,
    value: "LR",
    data: "LR",
  },
];

const PositionContainer = styled.div`
  width: 48%;
  @media (max-width: 767px) {
    margin-right: 0;
    width: 100%;
  }
`;
const Position: FC<{ runwayId: number }> = ({ runwayId }) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamForm.snowbank_runway_position.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamForm.snowbank_runway_position[index]?.position;
  });
  const dispatch = useDispatch();
  const { t } = useTranslation(["data_form","common"]);

  return (
    <PositionContainer>
      <DropDown
        name="snowbank_runway_position"
        label={t`data_form:snowbanks_position`}
        items={data}
        value={value}
        notChosenText={t`common:not_chosen`}
        onChoose={(val) =>
          dispatch(
            setSnowbankRunwayPosition({
              runwayId: runwayId,
              value: val,
            })
          )
        }
      />
    </PositionContainer>
  );
};

export default Position;
