import { useCallback, useEffect, useState } from "react";
import FrictionEquipment from "../../../api/friction-equipment";
import { FrictionEquipmentEntity } from "../../../api/friction-equipment.types";

type UseGetFrictionEquipmentOptions = {
  airport_code: string;
};

const useGetFrictionEquipment = ({
  airport_code,
}: UseGetFrictionEquipmentOptions) => {
  const [data, setData] = useState<FrictionEquipmentEntity[]>();
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(() => {
    setLoading(true);
    return FrictionEquipment.findAll(airport_code)
      .then((res) => setData(res))
      .finally(() => setLoading(false));
  }, [airport_code]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { friction_equipment: data, loading, refetch: fetch };
};

export default useGetFrictionEquipment;
