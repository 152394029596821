import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const useSettings = () => {
  const fontSize = useSelector(
    (state: RootState) => state.general.settings?.fontSize
  );
  const language = useSelector(
    (state: RootState) => state.general.settings?.language
  );
  const firstLoad = useRef(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (fontSize) {
      document.documentElement.style.fontSize = fontSize;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontSize]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language).then(() => null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (!firstLoad.current) {
      firstLoad.current = true;
    }
  }, []);
  return null;
};
export default useSettings;
