const templates = {
  templates: "Шаблоны",
  create: "Добавить",
  name: "Название шаблона",
  actions: "Действия",
  new_template: "Новый шаблон",
  edit_template: "Редактировать",
  delete_template: "Удалить шаблон?",
  text: "Введите текст",
};

export default templates;
