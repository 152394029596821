export enum RequestStatus {
  Void = -10,
  NotSent = -5,
  Rejected = -2,
  Declined = -1,
  New = 0,
  InProgress = 1,
  Sent = 2,
  Published = 5,
  Expired = 10,
}
