const data_form = {
  rwy: "RWY",
  rwy_active: "Send data",
  choose_rwy: "Choose RWY",
  runway_data: "Runway",
  clean: "Clear",
  clean_desktop: "Clear",
  no_rwy_selected_title: "Choose RWY",
  no_rwy_selected_caption: "Please choose runway to continue.",
  thirds: {
    first: "First third",
    first_title: "First third",
    second: "Second third",
    second_title: "Second third",
    third: "Third third",
    third_title: "Third third",
    condition_code: "Condition code",
    condition_description: "Condition description",
    contaminant_depth: "Contaminant depth",
    coverage: "Percent coverage",
  },
  conditions: {
    NR: "NR",
    DRY: "DRY",
    WET_SNOW_ON_TOP_OF_ICE: "WET SNOW ON TOP OF ICE",
    WET_SNOW_ON_TOP_OF_COMPACTED_SNOW: "WET SNOW ON TOP OF COMPACTED SNOW",
    WET_SNOW: "WET SNOW",
    WET_ICE: "WET ICE",
    WET: "WET",
    WATER_ON_TOP_OF_COMPACTED_SNOW: "WATER ON TOP OF COMPACTED SNOW",
    STANDING_WATER: "STANDING WATER",
    SLUSH: "SLUSH",
    ICE: "ICE",
    FROST: "FROST",
    DRY_SNOW_ON_TOP_OF_ICE: "DRY SNOW ON TOP OF ICE",
    DRY_SNOW_ON_TOP_OF_COMPACTED_SNOW: "DRY SNOW ON TOP OF COMPACTED SNOW",
    DRY_SNOW: "DRY SNOW",
    COMPACTED_SNOW: "COMPACTED SNOW",
  },
  runway_dimensions: "Runway dimensions",
  runway_width: "RWY width",
  reduced_rwy: "Reduced RWY",
  meteo_conditions: "Meteo conditions",
  snowstorm: "Drifting snow",
  sand: "Loose sand",
  treated: "Chem. treated",
  snowbank: "Snow bank",
  snowbanks: "Runway snow banks",
  snowbanks_distance: "Distance",
  snowbanks_position: "Position",
  general_data: "General data",
  snowbanks_twy: "TWY Snowbanks ",
  status_twy: "TWY condition",
  status_apron: "Apron condition",
  adhesion: "Friction coefficient",
  comment: "Remarks",
  comment_text: "Enter text",
  choose_template: "Fill by template",
  choose_template_mobile: "By template",

  send: "Send",
  send_request: "Send request?",
  creating_request: "Creating request",
  measuring_device: "Measuring device",
  hide_text: "Hide text",
  show_text: "Show request text",
  not_chosen: "Not chosen",
  low_temperature: "Temp. ≤  -15°C",
  twy_poor: "TWY condition poor",
  apron_poor: "Apron condition poor",
  twy_snowbanks: "Snowbanks on twy",
  pollution_depth: {
    nr: "lower than 10%",
    25: "from 10% to 25%",
    50: "from 25% to 50%",
    75: "from 50% to 75%",
    100: "from 75% to 100%",
  },
  validation: {
    choose_device: "Select friction measurement device",
    empty_friction: "Please enter friction coefficient values",
    pollution_depth:
      "Wrong pollution depth value on RWY {{ runway }} Third №{{ third }}",
    utc_date:
      "Feeding SNOWTAM in future time is not allowed. Please enter time in UTC format.",
  },
  manualRWYCC: `Manual RWYCC`,
  snowtam_text: "SNOWTAM Message",
  include_runways: "Include runway data",
};

export default data_form;
