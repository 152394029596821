import { FC, memo, PropsWithChildren } from "react";
import { Body } from "../RunwayFields";
import { GroupTitle, IconAirport } from "@snowtam/ui";
import SnowbanksTaxiway from "./SnowbanksTaxiway";
import TaxiwayStatus from "./TaxiwayStatus";
import ApronStatus from "./ApronStatus";
import Comment from "./Comment";
import { useTranslation } from "react-i18next";

const GeneralFields: FC<PropsWithChildren<any>> = ({ children }) => {
  const { t } = useTranslation("data_form");
  return (
    <Body>
      <GroupTitle label={t`general_data`} icon={<IconAirport />} />
      <SnowbanksTaxiway />
      <TaxiwayStatus />
      <ApronStatus />
      <Comment />
      {children}
    </Body>
  );
};

export default memo(GeneralFields);
