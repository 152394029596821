import { FC, memo, SVGProps } from "react";
import { useTheme } from "styled-components";

const Chevron: FC<SVGProps<any>> = (props) => {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={theme.colors.active}
      {...props}
    >
      <path
        d="M10 8L14 12L10 16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(Chevron);
