import { FC, MutableRefObject, useRef, useState } from "react";
import AddModal from "../../../AddModal";
import {
  CommonButton,
  MoreParagraph,
  InfoPopupWindow,
  useOutsideClicker,
} from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const PollutionStatusPopup: FC<{
  onClose: () => void;
  toggler?: MutableRefObject<any>;
}> = ({ onClose, toggler }) => {
  const popupRef = useRef(null);
  const [more, setMore] = useState(false);
  const { t } = useTranslation("helper");

  useOutsideClicker(popupRef, onClose, toggler);
  return (
    <>
      <InfoPopupWindow
        title={t`pollution_status.title`}
        text={
          <>
            {t`pollution_status.line1`} <br />
            <br />
            {t`pollution_status.line2`} <br />
            {t`pollution_status.line3`} <br />
            {t`pollution_status.line4`} <br />
            {t`pollution_status.line5`} <br />
            {t`pollution_status.line6`} <br />
            {t`pollution_status.line7`} <br />
          </>
        }
        onClose={onClose}
        more
        moreText={t`common.annotation`}
        onMoreClick={() => setMore(true)}
        hide={more}
        noOutSideClick
      />
      {more ? (
        <AddModal title={t`common.annotation`} onClose={() => onClose()}>
          <MoreParagraph>{t`pollution_status.note1`}</MoreParagraph>
          <MoreParagraph>{t`pollution_status.note2`}</MoreParagraph>
          <MoreParagraph>{t`pollution_status.note3`}</MoreParagraph>
          <CommonButton label={t`common.close`} onClick={() => onClose()} />
        </AddModal>
      ) : null}
    </>
  );
};

export default PollutionStatusPopup;
