import { FC, useCallback, useEffect, useState } from "react";
import Title from "./Title/";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import { RootState } from "../../redux";
import {
  initializeForm,
  setPause,
  setServerTime,
} from "../../redux/snowtamformReducer";
import { DialogYesNo } from "@snowtam/ui";
import ExistDialog from "./ExistDialog";
import RunwayFields from "./RunwayFields";
import GeneralFields from "./GeneralFields";
import { useTranslation } from "react-i18next";
import SaveButton from "./SaveButton";
import useServerTime from "../../hooks/useServerTime";
import useCheckRequestExist from "../../hooks/useCheckRequestExist";

const Body = styled.div`
  padding: 16px;
  @media (max-width: 767px) {
    padding: 0;
  }
`;
const NewForm: FC = () => {
  const user_id = useSelector((state: RootState) => state.general.user?.id);
  const formUser = useSelector((state: RootState) => state.snowtamForm.user_id);
  const runways = useSelector(
    (state: RootState) => state.general.user?.airport.runways
  );

  const existData = useCheckRequestExist();
  const time = useServerTime();

  const [errorDialog, setErrorDialog] = useState(false);
  const [runwayId, setRunwayId] = useState<number>();
  const [runwaysError, setRunwaysError] = useState<boolean>(false);

  const store = useStore();
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation("common");

  const handleInitializeForm = useCallback(() => {
    if (time) {
      dispatch(
        initializeForm({
          data: store.getState().general,
          time,
          user_id: user_id,
        })
      );
    }
  }, [dispatch, time, store, user_id]);

  useEffect(() => {
    if (!time || !user_id) return;
    if (!formUser || user_id !== formUser) {
      handleInitializeForm();
    } else {
      const state: RootState = store.getState();
      let outdated = false;
      state.snowtamForm.runways.forEach((rwy) => {
        if (
          rwy.timestamp &&
          parseInt(rwy.timestamp, 10) <= time / 1000 - 8 * 60 * 60
        ) {
          outdated = true;
        }
      });
      if (outdated) {
        dispatch(setServerTime(time));
      }
      dispatch(setPause());
    }
  }, [dispatch, formUser, handleInitializeForm, time, store, user_id]);

  const handleSetFirstRunway = useCallback(
    (id: number) => {
      if (typeof runwayId === "undefined") {
        setRunwayId(id);
      }
    },
    [runwayId]
  );

  useEffect(() => {
    if (typeof runways !== "undefined" && runways.length > 0) {
      handleSetFirstRunway(runways[0].id);
    }
  }, [handleSetFirstRunway, runways]);

  useEffect(() => {
    if (typeof runways === "undefined" || runways?.length === 0) {
      setRunwaysError(true);
    }
  }, [runways]);

  return (
    <>
      <>
        <Title
          onSetRunway={(id) => {
            window.scroll({ top: 0, behavior: "smooth" });
            setRunwayId(id);
          }}
        />
        {runwayId && formUser && user_id === formUser ? (
          <Body>
            <RunwayFields runwayId={runwayId} key={runwayId} />
            <GeneralFields>
              <SaveButton />
            </GeneralFields>
          </Body>
        ) : null}
      </>
      {errorDialog ? (
        <>
          <DialogYesNo
            title={t`errors.warning`}
            caption={t`errors.connection_lost`}
            onApply={() => setErrorDialog(false)}
            yesText={t`continue`}
            onClose={() => history.push("/menu")}
            noText={t`cancel`}
          />
        </>
      ) : null}
      {existData?.exist && existData.exist_date ? (
        <ExistDialog data={existData} />
      ) : null}

      {runwaysError ? (
        <DialogYesNo
          onClose={() => history.goBack()}
          title={t`error`}
          caption={t`errors.rwys_empty`}
          onApply={() => history.goBack()}
          yesText={"OK"}
        />
      ) : null}
    </>
  );
};
export default NewForm;
