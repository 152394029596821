import { FC } from "react";
import styled from "styled-components";
import { Row } from "./style";
import { useTranslation } from "react-i18next";

const Header = styled.thead``;
const Column = styled.th`
  padding: 24px 20px;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5rem;
  text-align: left;
  color: ${(props) => props.theme.colors.font3};

  @media (max-width: 767px) {
    font-size: 0.875rem;
    &:first-child {
      padding-left: 16px;
    }
    &:last-child {
      padding-right: 16px;
    }
  }
`;
const TableHeader: FC = () => {
  const { t } = useTranslation("templates");
  return (
    <Header>
      <Row>
        <Column>{t`name`}</Column>
        <Column style={{ textAlign: "center" }}>{t`actions`}</Column>
      </Row>
    </Header>
  );
};

export default TableHeader;
