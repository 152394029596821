import { IconAirport } from "@snowtam/ui";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import TopMenu from "../TopMenu";
import AddEquipmentDialog from "./AddEquipmentDialog";
import List from "./List";
import DataProvider, { DataContext } from "./Providers/DataProvider";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

type FrictionEquipmentProps = {
  code: string;
};

const FrictionEquipment: FC<FrictionEquipmentProps> = ({ code }) => {
  return (
    <DataProvider code={code}>
      <Page />
    </DataProvider>
  );
};

const Page: FC = () => {
  const airport = useSelector(
    (state: RootState) => state.general.user?.airport
  );
  const { refetch } = useContext(DataContext);
  const { t } = useTranslation("common");
  const [addEntityDialog, setAddEntityDialog] = useState(false);

  if (!airport) return null;

  return (
    <>
      <TopMenu
        icon={() => (airport ? <IconAirport /> : <></>)}
        title={
          airport
            ? `${airport.name} - ${t`friction:friction_equipment_title`}`
            : ""
        }
        onAddPress={() => setAddEntityDialog(true)}
      />
      <List />
      {addEntityDialog ? (
        <>
          <AddEquipmentDialog
            airport_code={airport.code}
            onClose={() => setAddEntityDialog(false)}
            onSuccess={() => {
              setAddEntityDialog(false);
              refetch();
            }}
          />
        </>
      ) : null}
    </>
  );
};
export default FrictionEquipment;
