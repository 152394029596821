import { FC } from "react";
import { CounterInput } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setSnowbankRunwayLength } from "../../../../redux/snowtamform/snowtamformv2Reducer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DistanceContainer = styled.div`
  width: 48%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const Distance: FC<{ runwayId: number }> = ({ runwayId }) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.snowbank_runway_position.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamFormV2.snowbank_runway_position[index]?.value;
  });
  const width = useSelector((state: RootState) => {
    if (!state.general.user?.airport.runways) return 0;
    const index = state.general.user?.airport.runways.findIndex(
      (item) => item.id === runwayId
    );
    return state.general.user?.airport.runways[index]?.width;
  });
  const dispatch = useDispatch();
  const { t } = useTranslation(["data_form", "common"]);

  return (
    <DistanceContainer>
      <CounterInput
        name={"snowbank_distance_runway"}
        label={t`snowbanks_distance`}
        minValue={0}
        maxValue={Math.round(width / 2)}
        value={value || 0}
        fullWidth
        textAdornment={t`common:meter`}
        onChange={(val) =>
          dispatch(
            setSnowbankRunwayLength({
              runwayId: runwayId,
              value: val,
            })
          )
        }
      />
    </DistanceContainer>
  );
};

export default Distance;
