import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

const useActiveRunwayMonitor = () => {
  const [runwayId, setRunwayId] = useState<number | null>();

  const runways = useSelector(
    (state: RootState) => state.general.user?.airport.runways
  );

  const active_runways = useSelector(
    (state: RootState) => state.snowtamFormV2.runways
  );

  useEffect(() => {
    const current = active_runways.find((rwy) => rwy.runwayId === runwayId);
    if (!current || current.active === false) {
      const active_rwy = active_runways.filter((rwy) => rwy.active)[0];
      setRunwayId(active_rwy?.runwayId || null);
    } else if (current.runwayId !== runwayId) {
      setRunwayId(current.runwayId);
    }
  }, [active_runways, runwayId]);

  useEffect(() => {
    if (
      (typeof runwayId === "undefined" || runwayId === null) &&
      runways &&
      active_runways &&
      active_runways.length > 0
    ) {
      const active_rwy = active_runways.filter(
        (rwy) => typeof rwy.active === "undefined" || rwy.active
      )[0];
      setRunwayId(active_rwy?.runwayId || null);
    }
  }, [runwayId, active_runways, runways]);

  return useMemo(
    () => ({
      runwayId,
      setRunwayId,
    }),
    [runwayId, setRunwayId]
  );
};
export default useActiveRunwayMonitor;
