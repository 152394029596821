import styled from "styled-components";

export const TwoColumnsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > div {
    width: 48%;
  }
`;

export const OneColumnRow = styled.div`
  width: 100%;
`;

export const DropDownContainer = styled.div`
  flex: unset;
  max-width: 48%;
  width: 48%;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const Group = styled.div`
  padding-bottom: 16px;
`;
