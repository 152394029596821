import { FC } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useTranslation } from "react-i18next";
import { DataCard, IconAirport } from "@snowtam/ui";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px 8px 16px;
  @media (max-width: 767px) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -24px;
  }
`;

const Container = styled.div`
  background: ${(props) => props.theme.colors.blocks};
  border: 1px solid ${(props) => props.theme.colors.stroke_block};
  box-sizing: border-box;
  border-radius: 4px;
`;

const Title = styled.div`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};
  align-items: center;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};

  svg {
    margin-right: 16px;
    width: 1.5rem;
    height: 1.5rem;
  }

  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

const Card = styled(DataCard)`
  @media (max-width: 767px) {
    margin-left: 24px;
  }
`;
const Information: FC = () => {
  const username = useSelector((state: RootState) => state.general.user?.name);
  const airport = useSelector(
    (state: RootState) => state.general.user?.airport
  );

  const { t } = useTranslation("information");
  return (
    <Container>
      <Title>
        <IconAirport />
        {`${airport?.name} (${airport?.code})`}
      </Title>

      <Body>
        <Card caption={username || ""} title={t`operator`} />
        <Card
          caption={airport?.runways?.map((item) => item.name).join(" | ") || ""}
          title={`${t`runways`} (${airport?.runways?.length})`}
        />
      </Body>
    </Container>
  );
};
export default Information;
