import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setSnowbanksNearRunway } from "../../../../redux/snowtamform/snowtamformv2Reducer";
import { CheckButton } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const SnowBankItem: FC<{
  runwayId: number;
}> = ({ runwayId }) => {
  const selected = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.snowbanks_near_runway.findIndex(
      (obj) => obj.id === runwayId
    );
    return state.snowtamFormV2.snowbanks_near_runway[index];
  });
  const dispatch = useDispatch();
  const { t } = useTranslation("data_form");

  return (
    <>
      <CheckButton
        _id={"adj_snowbanks"}
        label={t`snowbank`}
        value={selected?.value}
        onChange={(value) =>
          dispatch(
            setSnowbanksNearRunway({
              runwayId: runwayId,
              value,
            })
          )
        }
      />
    </>
  );
};
export default SnowBankItem;
