const settings = {
  profile: "Profile",
  personal_data: "Personal data",
  language: "Language",
  language_caption: "Language of application",
  language_input: "Select language",
  theme: "Theme",
  theme_auto: "Auto select theme",
  theme_auto_mobile: "Auto",
  theme_caption: "Application color theme",
  font: "Font size",
  font_caption: "Choose comfortable font size",
  font_input: "Font size",
  new_form: "New request form",
  new_form_caption: "Use new request form",
  new_form_checkbox: "New form",
  about: "About",
  about_caption: "About the app",
  about_description: "Airport runways condition message publish application",
  fonts: {
    "100": "Standard",
    "110": "Enlarged",
    "120": "Large",
    "130": "Very large",
  },
  themes: {
    main: "Light",
    dark: "Dark",
    night: "Night",
  },
};

export default settings;
