const helper = {
  adhesion: {
    title: "Measured friction coefficient",
    line1:
      "Measured friction coefficient. Where reported, insert the measured friction coefficient and\n" +
      "friction measuring device.",
    line2: "Latin characters only",
  },
  aprons: {
    caption:
      "The value of aprons is entered separated by commas, to select all aprons, enter the value ALL",
    title: "Apron conditions",
    line1:
      "To be filled in if there is information about bad conditions on the apron.",
    line2:
      "Select from the list a specific apron with bad conditions or all aprons (by selecting the ALL column)",
  },
  snowbanks_twy: {
    caption:
      "The value of taxiway is entered separated by commas, to select all taxiways, enter the value ALL",
    title: "Snow banks on a taxiway",
    line1:
      "Select from the list a specific taxiway with snowbanks or all taxiways (by selecting the ALL column)",
  },
  twy_status: {
    caption:
      "The value of taxiway is entered separated by commas, to select all taxiways, enter the value ALL",
    title: "Taxiway conditions",
    line1: "To be completed if bad taxiway conditions are reported.",
    line2:
      "Select from the list a specific taxiway with poor condition or all taxiways (by selecting the ALL column)",
  },
  dimensions: {
    information_window: "Information",
    rwy_width: "Runway width",
    rwy_reduced: "Reduced runway",
    reduced_text:
      "Available runway length in meters. Enter a value using the controls",
    width_text: "Runway width in meters. Enter a value using the controls",
  },
  common: {
    close: "Close",
    annotation: "Note",
    information_window: "Information",
  },
  meteo: {
    information_window: "Information",
    drifting_snow: "Drifting snow",
    drifting_snow_title: "Drifting snow",
    loose_sand: "Loose sand",
    adj_snowbanks: "Snow banks adjacent RWY",
    treated: "Chemically treated",
    loose_sand_text: "Check if there is sand on the runway.",
    adj_snowbanks_text: "Check if there are snowbanks near the runway.",
    treated_text: "Check if the runway has been chemically treated",
    drifting_snow_text: "Check if there is a drifting snow on the runway.",
    drifting_snow_annotation:
      "Note 1.— Drifting snow is an ensemble of snow particles raised by the wind to small heights above the ground (WMO definition)",
  },
  snowbanks_rwy: {
    title: "Snow banks RWY",
    line1:
      "Indicate the position(L - to the left, R - to the right, LR - on both sides) and the distance from the centerline in meters",
  },
  statuscode: {
    title: "RWY condition code",
    line1:
      "Select a value between 0 and 6. The runway condition code is determined by assessing the condition of the runway surface, in accordance with the provisions of the Aeronautical Services for Aerodrome Services and in accordance with the Runway Condition Assessment Table (RCAM).",
    snow_rcam: "Show table (RCAM)",
    rcam: "Table (RCAM)",
  },
  condition: {
    title: "RWY Condition",
    line1:
      "Select a value from the drop-down list about the current state of the runway.",
    note:
      "Note 1.— When the conditions are not reported, this will be signified by the insertion of “NR” for the\n" +
      "appropriate runway third(s).",
  },
  pollution_depth: {
    title: "Depth of the RWY contaminant",
    line1:
      "Depth of loose contaminant for each runway third. When provided, insert in millimetres for each runway third.",
    note1:
      "Note 1.— This information is only provided for the following contamination types:",
    note2: "— standing water",
    note3: "— slush",
    note4: "— wet snow",
    note5: "— dry snow",
    note6:
      "Note 2.— When the conditions are not reported, this will be signified by the insertion of “NR” for the appropriate runway third(s).",
    note7:
      "Note 3.— NR also includes the situations when the depth of the contaminant is less than the minimum values to be reported (as indicated above) or that part of runway is dry, etc.",
    note8:
      "Note 4. – For contaminants other than STANDING WATER, SLUSH, WET SNOW or DRY SNOW, the depth is not reported. The position of this type of information in the information string is then identified by /NR/.",
  },
  pollution_status: {
    title: "Percent coverage RWY",
    line1: "Choose value from dropdown list",
    line2: "NR - not resolved",
    line3: "DRY – dry RWY or percent coverage lower than 10%",
    line4: "25 – percent coverage from 10 to 25%",
    line5: "50 – percent coverage from 26 to 50%",
    line6: "75 – percent coverage from 51 to 75%",
    line7: "100 – percent coverage from 76 to 100%",
    note1:
      "Note 1.— This information is provided only when the runway condition for each runway third (Item D) has been reported as other than 6 and there is a condition description for each runway third (Item G) that has been reported other than DRY",
    note2:
      "Note 2.— When the conditions are not reported, this will be signified by the insertion of “NR” for the appropriate runway third(s).",
    note3:
      "Note 3. — When the runway condition is “DRY” or the coverage is less than 10%, item E shall be reported by inserting “NR”.",
  },
  rcam: {
    maintitle: "Runway condition assessment matrix (RCAM)",
    subtitle1: "Assessment",
    subtitle2: "Downgrade assessment criteria",
    rwy_status_code: "Runway condition code",
    rwy_descr: "Runway surface description",
    rwy_comment: "Aeroplane deceleration or directional control observation",
    pilot_report: "Pilot report of runway braking action",
    dry_rwy: "DRY",
    frost: "FROST",
    wet: "WET (The runway surface is covered by any visible dampness or water up to and including 3 mmdepth)",
    under3mm: "Up to and including 3 mm depth",
    slush: "SLUSH",
    drysnow: "DRY SNOW",
    wetsnow: "WET SNOW",
    good: "GOOD",
    comment1:
      "Braking deceleration is normal for the wheel braking effort applied AND directional control is normal.",
    temp15: "−15ºC and Lower outside air temperature",
    comp_snow: "COMPACTED SNOW",
    comment2:
      "Braking deceleration OR directional control is between Good and Medium.",
    fromgoodtomiddle: "GOOD TO MEDIUM",
    wet_rwy: "WET (“slippery wet” runway)",
    dry_wet_snow: "DRY SNOW or WET SNOW (any depth) ON TOP OF COMPACTED SNOW",
    more3mm: "More than 3 mm depth",
    tempmore15: "Higher than −15ºC outside air temperature",
    comment3:
      "Braking deceleration is noticeably reduced for the wheel braking effort applied OR directional control is noticeably reduced.",
    medium: "MEDIUM",
    more3mmwater: "More than 3 mm depth of water or slush",
    std_water: "STANDING WATER",
    comment4:
      "Braking deceleration OR directional control is between Medium and Poor.",
    frommedtobad: "MEDIUM TO POOR",
    ice: "ICE",
    comment5:
      "Braking deceleration is significantly reduced for the wheel braking effort applied OR directional control is significantly reduced.",
    bad: "POOR",
    wetice: "WET ICE",
    water_comp_snow: "WATER ON TOP OF COMPACTED SNOW",
    dry_wet_comp_snow: "DRY SNOW or WET SNOW ON TOP OF ICE",
    comment6:
      "Braking deceleration is minimal to non- existent for the wheel braking effort applied OR directional control is uncertain",
    verybad: "LESS THAN POOR",
    very: "LESS THAN",
    mobile: {
      mediumtopoor1: "MEDIUM",
      mediumtopoor2: "TO",
      mediumtopoor3: "POOR",
      mediumtogood1: "GOOD",
      mediumtogood2: "TO",
      mediumtogood3: "MEDIUM",
    },
    annotation: {
      header: "Notes",
      header1: "Note.1",
      header2: "Note.2",
      annotation1:
        "The RCAM is a tool to be used when assessing runway surface conditions. It is not a standalone" +
        "document and shall be used in compliance with the associated assessment procedures including" +
        "downgrade and upgrade criteria detailed in ICAO DOC 9881 (PANS-Aerodrome),Part II, Chapter 1.",
      annotation2:
        "States that follow EASA Regulations additionally use SPECIALLY PREPARED WINTER" +
        "RUNWAY for runway condition code 4 and the descriptor WET for runway condition code 3 is replaced" +
        "by SLIPPERY WET.",
    },
  },
};

export default helper;
