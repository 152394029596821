import { FC } from "react";
import { LabeledCheckbox } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setSand } from "../../../../redux/snowtamformReducer";

const SandCheck: FC<{ title: string; name: string; runwayId: number }> = ({
  title,
  name,
  runwayId,
}) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamForm.sand.findIndex(
      (obj) => obj.id === runwayId
    );
    return state.snowtamForm.sand[index];
  });
  const dispatch = useDispatch();
  return (
    <LabeledCheckbox
      _id={"loose_sand"}
      title={title}
      caption={name}
      value={value?.value}
      onChange={(val) => dispatch(setSand({ runwayId, value: val }))}
    />
  );
};
export default SandCheck;
