import { FC, useMemo } from "react";
import { LabeledBigCheckbox } from "@snowtam/ui";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { switchActive } from "../../../../redux/snowtamform/snowtamformv2Reducer";

const Checkbox = styled(LabeledBigCheckbox)`
  margin-right: 16px;
`;

interface RunwayItemProps {
  name: string;
  runway_id: number;
  onChange: () => void;
}

const RunwayItem: FC<RunwayItemProps> = (props) => {
  const runways = useSelector(
    (state: RootState) => state.snowtamFormV2.runways
  );

  const selected = useMemo(
    () => runways.find((item) => item.runwayId === props.runway_id),
    [props.runway_id, runways]
  );

  const dispatch = useDispatch();

  const handleChange = (val: boolean) => {
    dispatch(
      switchActive({
        runway_id: props.runway_id,
        active: val,
      })
    );
    props.onChange();
  };

  return (
    <Checkbox
      name={`runway_${props.runway_id}`}
      checked={selected?.active ?? false}
      onChange={handleChange}
      label={props.name}
    />
  );
};
export default RunwayItem;
