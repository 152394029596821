import { FC } from "react";
import { RunwaysType } from "../../../@types/models";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Table = styled.table`
  border: none;
  border-collapse: collapse;
`;

const Column = styled.td`
  span {
    display: flex;
    padding: 0 0.5rem;
    margin: 0.25rem 0;
  }

  &:first-child {
    span {
      padding: 0 0.5rem 0 0;
    }
  }
  &:not(:last-child) {
    span {
      border-right: 1px solid ${({ theme }) => theme.colors.inactive};
    }
  }
`;

const RunwaysTable: FC<{ runways: RunwaysType[] }> = ({ runways }) => {
  const { t } = useTranslation("information");
  return (
    <Table>
      <tbody>
        {runways.map((runway) => (
          <tr key={`rwy${runway.id}`}>
            <Column>
              <span>{runway.name}</span>
            </Column>
            <Column>
              <span>
                {t`length`} {runway.length} {t`meter`}
              </span>
            </Column>
            <Column>
              <span>
                {t`width`} {runway.width} {t`meter`}
              </span>
            </Column>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default RunwaysTable;
