import { FC, useCallback, useMemo, useState } from "react";
import { CounterInput } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { setPollutionDepth } from "../../../../../redux/snowtamform/snowtamformv2Reducer";
import { useTranslation } from "react-i18next";
import pollutionDepth, {
  PollutionDepthSettingsType,
} from "../../../../../settings/pollutionDepth";

type PollutionDepthProps = {
  third: 1 | 2 | 3;
  runwayId: number;
};

const PollutionDepth: FC<PollutionDepthProps> = ({ third, runwayId }) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamFormV2.runways[index]?.thirds[third]?.pollutionDepth;
  });

  const rwy_pollution = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamFormV2.runways[index]?.thirds[third]?.status;
  });

  const availableValues = useMemo(
    () =>
      pollutionDepth.find((item) => {
        return item.data === rwy_pollution?.value;
      }) as PollutionDepthSettingsType | undefined,
    [rwy_pollution?.value]
  );

  const isValueAvailable = useCallback((): boolean => {
    if (!availableValues) return false;
    const available = availableValues.depth;
    return (
      typeof value === "number" &&
      value >= available.min &&
      value <= available.max &&
      !available.exclude.includes(value)
    );
  }, [availableValues, value]);
  const [inputFocused, setInputFocused] = useState(false);
  const [manualInput, setManualInput] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation(["data_form", "common"]);

  const renderValue = (val: number): string => {
    if (inputFocused && val === 0) return "0";

    if (val === null) return "";

    return val === 0 ? "NR" : val.toString();
  };

  return (
    <CounterInput
      _id={"pollution_depth"}
      name={"pollution_depth"}
      label={t`thirds.contaminant_depth`}
      value={value}
      onChange={(val) => {
        if (manualInput) setManualInput(false);
        dispatch(setPollutionDepth({ runwayId, third, value: val }));
      }}
      inputProps={{
        readOnly: false,
        type: "text",
        inputMode: "numeric",
        onFocus: () => {
          if (!manualInput) setManualInput(true);
          if (!inputFocused) setInputFocused(true);
        },
        onBlur: () => {
          if (inputFocused) setInputFocused(false);
        },
      }}
      textAdornment={value !== 0 && value !== null ? t`common:millimeter` : ""}
      minValue={availableValues?.depth.min ?? 0}
      maxValue={availableValues?.depth.max ?? 999}
      excludedValues={availableValues?.depth.exclude}
      renderValue={renderValue}
      error={!isValueAvailable()}
      onInputChange={(e) => {
        const newVal = parseInt(e.target.value, 10);
        const result = !isNaN(newVal) ? newVal : null;
        if (!manualInput) setManualInput(true);
        dispatch(setPollutionDepth({ runwayId, third, value: result }));
        return;
      }}
    />
  );
};

export default PollutionDepth;
