import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setFrictionFormDeviceAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{
    runwayId: number;
    value: string;
  }>
) => {
  if (typeof state.friction_form === "undefined") {
    state.friction_form = [];
  }
  const item = state.friction_form?.find(
    (item) => item.runwayId === action.payload.runwayId
  );
  if (!item) {
    state.friction_form.push({
      runwayId: action.payload.runwayId,
      first: "",
      second: "",
      third: "",
      device: action.payload.value,
    });
  } else {
    item.device = action.payload.value;
  }
  state.pause = true;
};

export default setFrictionFormDeviceAction;
