import { useStore } from "react-redux";
import { RootState } from "../../../redux";
import { FrictionFormType } from "../../../../@types/form";
import { useTranslation } from "react-i18next";

const useDataValidate = () => {
  const store = useStore();
  const { t } = useTranslation("data_form");

  const validateFrictionForm = (
    data?: FrictionFormType[]
  ): ErrorType | undefined => {
    if (!data) return;

    for (const friction_data of data) {
      if (
        ((friction_data.first !== "0" && friction_data.first !== "") ||
          (friction_data.second !== "0" && friction_data.second !== "") ||
          (friction_data.third !== "0" && friction_data.third !== "")) &&
        (!friction_data.device || friction_data.device.length === 0)
      ) {
        return {
          error: true,
          message: t`validation.choose_device`,
        };
      }
    }
  };

  return (): ErrorType => {
    const data: RootState = store.getState();
    if (data.general.settings?.friction_form) {
      const friction_error = validateFrictionForm(
        data.snowtamForm.friction_form
      );
      if (friction_error) {
        return friction_error;
      }
    }

    return {
      error: false,
    };
  };
};

type ErrorType = {
  error: boolean;
  message?: string;
};
export default useDataValidate;
