import API from "./API";

export type ListSearchOptions = {
  date?: string;
  status?: string;
  page?: number;
  limit?: number;
};

type ExistsResponse = {
  exist: boolean;
  exist_date?: string;
};

class Requests {
  private api: typeof API;

  constructor() {
    this.api = API;
  }

  async declineRequest(id: number) {
    return this.api
      .patch(`/api/requests/${encodeURIComponent(id)}/decline`)
      .then((res: any) => res.data);
  }

  async findOneByID(options: { id: number }) {
    const url = `/api/requests/${encodeURIComponent(options.id)}`;
    return this.api.get(url).then((res: any) => res.data);
  }

  async generateMessage(data: any) {
    return this.api
      .post("/api/message/generate", { data })
      .then((res: any) => res.data);
  }

  async getList(options?: ListSearchOptions) {
    const url = "/api/requests/airport/list?";
    const searchParams = new URLSearchParams();
    if (options?.date) {
      searchParams.set("date", String(options?.date));
    }
    if (options?.status) {
      searchParams.set("status", String(options.status));
    }
    if (options?.page) {
      searchParams.set("page", String(options.page));
    }
    if (options?.limit) {
      searchParams.set("limit", String(options.limit));
    }
    return this.api.get(url + searchParams.toString());
  }

  async getLast() {
    return this.api
      .get("/api/requests/airport/last")
      .then((res: any) => res.data);
  }

  async checkExist() {
    return this.api
      .get("/api/requests/airport/exist")
      .then<ExistsResponse>((res: any) => res.data);
  }
}

export default new Requests();
