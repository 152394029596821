import { FC, PropsWithChildren } from "react";
import { Body, BoldSpan, Container, TextBlock, Title } from "./labelStyles";
import { useTranslation } from "react-i18next";
import { IconSend } from "@snowtam/ui";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { UTC_DATETIME_FORMAT } from "../../settings/app.constants";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

interface CreatedProps extends PropsWithChildren<any> {
  operator: string;
  date: string;
}

const Created: FC<CreatedProps> = ({ operator, date, children }) => {
  const { t } = useTranslation("requests");
  return (
    <Container>
      <Title>
        <IconSend />
        {t`sending_data`}
      </Title>
      <Body>
        <TextBlock>
          <span style={{ marginRight: "16px" }}>{t`operator`}</span>
          <BoldSpan>{operator}</BoldSpan>
        </TextBlock>
        <TextBlock>
          <span style={{ marginRight: "16px" }}>{t`send_at`}</span>
          <BoldSpan>
            {dayjs.utc(date, "X").format(UTC_DATETIME_FORMAT)}
          </BoldSpan>
        </TextBlock>
        {children}
      </Body>
    </Container>
  );
};

export default Created;
