import { FC, ReactElement } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IconBackArrow } from "@snowtam/ui";

const TitleContainer = styled.div`
  padding: 24px 39px;
  box-shadow: ${({ theme }) => theme.shadows.header_main};
  background: ${(props) => props.theme.colors.blocks};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 1000;
  border-left: none;
  @media (max-width: 767px) {
    padding: 12px 0;
    height: 56px;
    max-height: 56px;
    z-index: 1000;
  }
`;

const BackLink = styled.button`
  position: absolute;
  left: 39px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  padding: 16px 0;
  border: none;
  margin-right: auto;
  color: ${(props) => props.theme.colors.active};
  font-size: 1rem;
  line-height: 1.5rem;
  outline: none;
  font-weight: 600;

  svg {
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    font-size: 0.875rem;
    left: 16px;
    padding: 13px 0;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const Title = styled.h1`
  margin: 0 auto;
  padding: 0;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

const EndAdornment = styled.div`
  position: absolute;
  right: 39px;
  @media (max-width: 767px) {
    right: 16px;
  }
`;

interface TopBarProps {
  onBack?: () => void;
  title: string;
  endAdornment?: ReactElement;
}

const TopBar: FC<TopBarProps> = ({ onBack, title, endAdornment }) => {
  const { t } = useTranslation("common");
  return (
    <TitleContainer>
      <BackLink onClick={onBack} type="button" name="back">
        <IconBackArrow />
        {t`back`}
      </BackLink>
      <Title>{title}</Title>
      {endAdornment ? <EndAdornment>{endAdornment}</EndAdornment> : null}
    </TitleContainer>
  );
};

export default TopBar;
