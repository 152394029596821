import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Body, BoldSpan, Container, TextBlock } from "./labelStyles";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { UTC_DATETIME_FORMAT } from "../../settings/app.constants";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

type SnowtamProps = {
  snowtam: string;
  date?: string;
};

const Snowtam: FC<SnowtamProps> = ({ snowtam, date }) => {
  const { t } = useTranslation("requests");
  return (
    <Container>
      <Body>
        <TextBlock>
          <span style={{ marginRight: "16px" }}>{t`snowtam_number`}</span>
          <BoldSpan>{snowtam}</BoldSpan>
        </TextBlock>
        <TextBlock>
          <span style={{ marginRight: "16px" }}>{t`published_date`}</span>
          <BoldSpan>
            {date ? dayjs.utc(date, "X").format(UTC_DATETIME_FORMAT) : null}
          </BoldSpan>
        </TextBlock>
      </Body>
    </Container>
  );
};

export default Snowtam;
