import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 0;
`;
export const Title = styled.div`
  display: flex;
  padding: 0 0 16px 0;
  align-items: center;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};
  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    margin-right: 16px;
  }

  @media (max-width: 767px) {
    font-size: 0.938rem;
  }
`;

export const Body = styled.div`
  padding: 2px 0 2px 0;
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const TextBlock = styled.p`
  display: flex;
  padding: 0.25rem 0.25rem 0.25rem 0;
  margin: 0;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};
  flex-wrap: wrap;
  flex-direction: column;
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
`;

export const BoldSpan = styled.span`
  font-weight: 600;
  line-height: 1.5rem;
`;
