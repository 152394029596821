import { FC, FormEventHandler, useState } from "react";
import AddModal from "../AddModal";
import styled from "styled-components";
import { OutlinedInput } from "@snowtam/ui";
import { useTranslation } from "react-i18next";
import User from "../../api/user";

const Form = styled.form`
  display: flex;
  width: 100%;
  padding: 24px 0;
  flex-direction: column;
`;

const CreateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: ${(props) => props.theme.colors.active};
  border-radius: 4px;
  padding: 16px;
  width: 276px;
  margin: 16px auto 0 auto;
  color: ${(props) => props.theme.colors.font_active};
`;

const Error = styled.span`
  text-align: center;
  color: ${(props) => props.theme.colors.error};
  padding: 12px 0;
`;

interface ChangePasswordProps {
  title: string;
  onClose: () => void;
  onSuccess: () => void;
}

const ChangePassword: FC<ChangePasswordProps> = ({
  title,
  onClose,
  onSuccess,
}) => {
  const [password, setPassword] = useState<string>();
  const [password_repeat, setPasswordRepeat] = useState<string>();
  const [error, setError] = useState<string>();
  const { t } = useTranslation("common");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!password) {
      setError(t`errors.fill_all`);
      return;
    }
    if (password !== password_repeat) {
      setError(t`errors.passwords_match`);
      return;
    }
    User.changePassword({ password }).then(onSuccess);
  };

  return (
    <AddModal title={title} onClose={onClose}>
      <Form onSubmit={handleSubmit}>
        <OutlinedInput
          name={"password"}
          label={t`password`}
          value={password || ""}
          onChange={(e) => setPassword(e.target.value)}
          inputProps={{ type: "password" }}
        />
        <OutlinedInput
          name={"repeat_password"}
          label={t`re_password`}
          value={password_repeat || ""}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          inputProps={{ type: "password" }}
        />
        {error ? <Error>{error}</Error> : null}
        <CreateButton name={"submit"}>{t`change`}</CreateButton>
      </Form>
    </AddModal>
  );
};

export default ChangePassword;
