import { FC } from "react";
import FrictionEquipment from "../../components/FrictionEquipment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import styled from "styled-components";

const Container = styled.div`
  padding: 1rem;
  position: relative;
  @media (max-width: 1199px) {
    padding: 0;
  }
`;

const FrictionEquipmentPage: FC = () => {
  const code = useSelector(
    (state: RootState) => state.general.user?.airport.code
  );
  if (!code) return null;
  return (
    <Container>
      <FrictionEquipment code={code} />
    </Container>
  );
};

export default FrictionEquipmentPage;
