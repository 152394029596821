import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IconBack, IconPrint, IconButton } from "@snowtam/ui";

const Container = styled.div`
  display: flex;
  padding: 0.5rem 1.5rem;
  background: ${(props) => props.theme.colors.blocks};
  border: 1px solid ${(props) => props.theme.colors.stroke_block};
  box-sizing: border-box;
  margin-bottom: 1rem;
  justify-content: flex-end;
  align-items: center;
`;

const PrintLabel = styled.p`
  padding: 0;
  margin: 0 8px 0 0;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.font1};
`;

const Button = styled.button`
  display: flex;
  border: none;
  padding: 0.5rem;
  color: ${(props) => props.theme.colors.font_passive};
  outline: none;
  background: ${(props) => props.theme.colors.active_light};
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Open Sans", serif;
  cursor: pointer;
  transition: all 0.125s ease;

  &:hover {
    background: ${(props) => props.theme.colors.active};
    color: ${(props) => props.theme.colors.font_active};
  }
`;

const StartAdornmentContainer = styled.div`
  display: flex;
  margin-right: auto;
`;

const EndAdornmentContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.font1};
  padding: 8px 0;
`;

const BackLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.active};
  margin-right: 24px;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.active};
    margin-right: 8px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin: auto 0;
  align-items: center;

  svg {
    margin-right: 1rem;
    color: ${({ theme }) => theme.colors.active};
    width: 1.5rem;
    height: 1.5rem;
  }
`;

type TopMenuProps = {
  onAddPress?: () => void;
  onPrintPress?: () => void;
  startAdornment?: () => ReactElement;
  icon?: () => ReactElement;
  endAdornment?: () => ReactElement;
  withBackLink?: boolean;
  title: string;
};

const TopMenu: FC<TopMenuProps> = ({
  onAddPress,
  onPrintPress,
  startAdornment,
  endAdornment,
  withBackLink,
  title,
  icon,
}) => {
  const { t } = useTranslation("common");

  const history = useHistory();

  return (
    <Container>
      <StartAdornmentContainer>
        {withBackLink ? (
          <BackLink
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              history.goBack();
            }}
            type={"button"}
          >
            <IconBack />
            {t`common:back`}
          </BackLink>
        ) : null}
        <TitleContainer>
          {icon ? icon() : null}
          <Label>{title}</Label>
        </TitleContainer>
      </StartAdornmentContainer>
      {startAdornment ? (
        <StartAdornmentContainer>{startAdornment()}</StartAdornmentContainer>
      ) : null}
      {typeof onPrintPress === "function" ? (
        <>
          <PrintLabel>{t`print`}</PrintLabel>
          <IconButton
            style={{ marginRight: "3rem" }}
            onClick={onPrintPress}
            type="button"
            color="blue"
            icon={() => <IconPrint />}
            size="small"
            name={"print"}
          />
        </>
      ) : null}
      {typeof endAdornment === "function" ? (
        <EndAdornmentContainer>{endAdornment()}</EndAdornmentContainer>
      ) : null}
      {typeof onAddPress === "function" ? (
        <Button
          style={{ padding: "0.5rem 1rem" }}
          onClick={onAddPress}
          type="button"
          name={"add"}
        >
          {t`create`}
        </Button>
      ) : null}
    </Container>
  );
};
export default TopMenu;
