import API from "./API";

type ChangePasswordInput = {
  password: string;
};

class User {
  private api: typeof API;

  constructor() {
    this.api = API;
  }

  async changePassword(data: ChangePasswordInput) {
    return this.api.put("/api/user/password", {
      ...data,
    });
  }
}

export default new User();
