import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  line-height: 1rem;
  b {
    padding: 4px 0;
  }
`;
const Annotation = styled.h4`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.font1};
  padding: 8px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};
  margin-bottom: 4px;
`;

const Text = styled.p`
  padding: 4px 0;
  margin: 0;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: ${(props) => props.theme.colors.font1};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${(props) => props.theme.colors.rcam_outline};
  color: ${(props) => props.theme.colors.font1};
  margin-top: 16px;
  ul {
    padding: 0 0 0 20px;
    margin: 0;
  }
`;

const THeadCol = styled.th`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  background: ${(props) => props.theme.colors.rcam_header};
  text-align: center;
`;

const TColYellow = styled.td`
  background: ${(props) => props.theme.colors.rcam_subheader1};
  padding: 8px 9px;
  border: 1px solid ${(props) => props.theme.colors.rcam_outline};
  vertical-align: top;
`;

const TColNormal = styled(TColYellow)`
  background: ${(props) => props.theme.colors.blocks};
`;
const CenterSpan = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
`;

const AnnotationText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  b {
    padding: 8px 0;
  }
  hr {
    border: none;
    height: 1px;
    background: ${(props) => props.theme.colors.separator};
    margin: 16px 0 8px 0;
  }
`;

const RCAMMobile: FC = () => {
  const { t } = useTranslation("helper");
  return (
    <Body>
      <Annotation>{t`common.annotation`}</Annotation>
      <Text>{t`rcam.subtitle1`} = 1</Text>
      <Text>{t`rcam.subtitle2`} = 2</Text>
      <Table>
        <thead>
          <tr>
            <THeadCol colSpan={3}> {t`rcam.maintitle`}</THeadCol>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TColYellow rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>1</CenterSpan>
            </TColYellow>
            <TColYellow>{t`rcam.rwy_status_code`}</TColYellow>
            <TColYellow>6</TColYellow>
          </tr>
          <tr>
            <TColYellow>{t`rcam.rwy_descr`}</TColYellow>
            <TColYellow>
              <ul>
                <li>{t`rcam.dry_rwy`}</li>
              </ul>
            </TColYellow>
          </tr>
          <tr>
            <TColNormal rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>2</CenterSpan>
            </TColNormal>
            <TColNormal>{t`rcam.rwy_comment`}</TColNormal>
            <TColNormal
              style={{ verticalAlign: "middle", textAlign: "center" }}
            >
              --------
            </TColNormal>
          </tr>
          <tr>
            <TColNormal>{t`rcam.pilot_report`}</TColNormal>
            <TColNormal
              style={{ verticalAlign: "middle", textAlign: "center" }}
            >
              --------
            </TColNormal>
          </tr>
          <tr>
            <TColYellow rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>1</CenterSpan>
            </TColYellow>
            <TColYellow>{t`rcam.rwy_status_code`}</TColYellow>
            <TColYellow>5</TColYellow>
          </tr>
          <tr>
            <TColYellow>{t`rcam.rwy_descr`}</TColYellow>
            <TColYellow>
              <ul>
                <li>{t`rcam.frost`}</li>
                <li>{t`rcam.wet`}</li>
              </ul>
              <b>
                <i>{t`rcam.under3mm`}:</i>
              </b>
              <ul>
                <li>{t`rcam.slush`}</li>
                <li>{t`rcam.drysnow`}</li>
                <li>{t`rcam.wetsnow`}</li>
              </ul>
            </TColYellow>
          </tr>
          <tr>
            <TColNormal rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>2</CenterSpan>
            </TColNormal>
            <TColNormal>{t`rcam.rwy_comment`}</TColNormal>
            <TColNormal>{t`rcam.comment1`}</TColNormal>
          </tr>
          <tr>
            <TColNormal>{t`rcam.pilot_report`}</TColNormal>
            <TColNormal
              style={{ verticalAlign: "middle", textAlign: "center" }}
            >
              {t`rcam.good`}
            </TColNormal>
          </tr>
          <tr>
            <TColYellow rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>1</CenterSpan>
            </TColYellow>
            <TColYellow>{t`rcam.rwy_status_code`}</TColYellow>
            <TColYellow>4</TColYellow>
          </tr>
          <tr>
            <TColYellow>{t`rcam.rwy_descr`}</TColYellow>
            <TColYellow>
              <b>
                <i>{t`rcam.temp15`}:</i>
              </b>
              <ul>
                <li>{t`rcam.comp_snow`}</li>
              </ul>
            </TColYellow>
          </tr>
          <tr>
            <TColNormal rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>2</CenterSpan>
            </TColNormal>
            <TColNormal>{t`rcam.rwy_comment`}</TColNormal>
            <TColNormal>{t`rcam.comment2`}</TColNormal>
          </tr>
          <tr>
            <TColNormal>{t`rcam.pilot_report`}</TColNormal>
            <TColNormal
              style={{ verticalAlign: "middle", textAlign: "center" }}
            >
              {t`rcam.mobile.mediumtogood1`}
              <br />
              {t`rcam.mobile.mediumtogood2`} <br />
              {t`rcam.mobile.mediumtogood3`}
            </TColNormal>
          </tr>
          <tr>
            <TColYellow rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>1</CenterSpan>
            </TColYellow>
            <TColYellow>{t`rcam.rwy_status_code`}</TColYellow>
            <TColYellow>3</TColYellow>
          </tr>
          <tr>
            <TColYellow>{t`rcam.rwy_descr`}</TColYellow>
            <TColYellow>
              <ul>
                <li>{t`rcam.wet_rwy`}</li>
                <li>{t`rcam.dry_wet_snow`}</li>
              </ul>
              <b>
                <i>{t`rcam.more3mm`}:</i>
              </b>
              <ul>
                <li>{t`rcam.drysnow`}</li>
                <li>{t`rcam.wetsnow`}</li>
              </ul>
              <b>
                <i>{t`rcam.tempmore15`}:</i>
              </b>
              <ul>
                <li>{t`rcam.comp_snow`}</li>
              </ul>
            </TColYellow>
          </tr>
          <tr>
            <TColNormal rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>2</CenterSpan>
            </TColNormal>
            <TColNormal>{t`rcam.rwy_comment`}</TColNormal>
            <TColNormal>{t`rcam.comment3`}</TColNormal>
          </tr>
          <tr>
            <TColNormal>{t`rcam.pilot_report`}</TColNormal>
            <TColNormal
              style={{ verticalAlign: "middle", textAlign: "center" }}
            >
              {t`rcam.medium`}
            </TColNormal>
          </tr>
          <tr>
            <TColYellow rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>1</CenterSpan>
            </TColYellow>
            <TColYellow>{t`rcam.rwy_status_code`}</TColYellow>
            <TColYellow>2</TColYellow>
          </tr>
          <tr>
            <TColYellow>{t`rcam.rwy_descr`}</TColYellow>
            <TColYellow>
              <b>
                <i>{t`rcam.more3mmwater`}:</i>
              </b>
              <ul>
                <li>{t`rcam.std_water`}</li>
                <li>{t`rcam.slush`}</li>
              </ul>
            </TColYellow>
          </tr>
          <tr>
            <TColNormal rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>2</CenterSpan>
            </TColNormal>
            <TColNormal>{t`rcam.rwy_comment`}</TColNormal>
            <TColNormal>{t`rcam.comment4`}</TColNormal>
          </tr>
          <tr>
            <TColNormal>{t`rcam.pilot_report`}</TColNormal>
            <TColNormal
              style={{ verticalAlign: "middle", textAlign: "center" }}
            >
              {t`rcam.mobile.mediumtopoor1`} <br />
              {t`rcam.mobile.mediumtopoor2`} <br />
              {t`rcam.mobile.mediumtopoor3`}
            </TColNormal>
          </tr>
          <tr>
            <TColYellow rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>1</CenterSpan>
            </TColYellow>
            <TColYellow>{t`rcam.rwy_status_code`}</TColYellow>
            <TColYellow>1</TColYellow>
          </tr>
          <tr>
            <TColYellow>{t`rcam.rwy_descr`}</TColYellow>
            <TColYellow>
              <ul>
                <li>{t`rcam.ice`}</li>
              </ul>
            </TColYellow>
          </tr>
          <tr>
            <TColNormal rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>2</CenterSpan>
            </TColNormal>
            <TColNormal>{t`rcam.rwy_comment`}</TColNormal>
            <TColNormal>{t`rcam.comment5`}</TColNormal>
          </tr>
          <tr>
            <TColNormal>{t`rcam.pilot_report`}</TColNormal>
            <TColNormal
              style={{ verticalAlign: "middle", textAlign: "center" }}
            >
              {t`rcam.bad`}
            </TColNormal>
          </tr>
          <tr>
            <TColYellow rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>1</CenterSpan>
            </TColYellow>
            <TColYellow>{t`rcam.rwy_status_code`}</TColYellow>
            <TColYellow>0</TColYellow>
          </tr>
          <tr>
            <TColYellow>{t`rcam.rwy_descr`}</TColYellow>
            <TColYellow>
              <ul>
                <li>{t`rcam.wetice`}</li>
                <li>{t`rcam.water_comp_snow`}</li>
                <li>{t`rcam.dry_wet_comp_snow`}</li>
              </ul>
            </TColYellow>
          </tr>
          <tr>
            <TColNormal rowSpan={2} style={{ verticalAlign: "middle" }}>
              <CenterSpan>2</CenterSpan>
            </TColNormal>
            <TColNormal>{t`rcam.rwy_comment`}</TColNormal>
            <TColNormal>{t`rcam.comment6`}</TColNormal>
          </tr>
          <tr>
            <TColNormal>{t`rcam.pilot_report`}</TColNormal>
            <TColNormal
              style={{ verticalAlign: "middle", textAlign: "center" }}
            >
              {t`rcam.very`} <br />
              {t`rcam.bad`}
            </TColNormal>
          </tr>
        </tbody>
      </Table>
      <Annotation>{t`rcam.annotation.header`}</Annotation>
      <AnnotationText>
        <b>
          <i>{t`rcam.annotation.header1`}</i>
        </b>
        <i>{t`rcam.annotation.annotation1`}</i>
        <hr />
        <b>
          <i>{t`rcam.annotation.header2`}</i>
        </b>
        <i>{t`rcam.annotation.annotation2`}</i>
      </AnnotationText>
    </Body>
  );
};

export default RCAMMobile;
