import { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SelectItem from "./SelectItem";
import { DropDownItem, useOutsideClicker } from "@snowtam/ui";

const PopupContainer = styled.div<{ left?: number }>`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: ${({ theme }) => theme.colors.dropdown_background};
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  border: 1px solid ${({ theme }) => theme.colors.dropdown_border};
  border-radius: 4px;
  z-index: 1200;
  @media (max-width: 767px) {
    left: ${({ left }) => (left ? `-${left - 16}px` : 0)};
    max-width: ${({ left }) => (left ? `${window.innerWidth - 32}px` : 0)};
    visibility: ${({ left }) => (left ? `visible` : "hidden")};
  }

  .simplebar-track.simplebar-horizontal {
    display: none;
  }
`;
const ItemsContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 16px;
  width: max-content;
`;

const SelectPopup: FC<{
  togglerRef: MutableRefObject<any>;
  onClose: () => void;
  items: DropDownItem[];
  onSelect: (value: DropDownItem) => void;
  selected?: number;
}> = ({ togglerRef, onClose, items, onSelect, selected }) => {
  const containerRef = useRef(null);
  const [left, setLeft] = useState<number>();

  useEffect(() => {
    setLeft(getElementOffsetLeft(containerRef.current));
  }, [containerRef]);

  useOutsideClicker(containerRef, onClose, togglerRef);

  function getElementOffsetLeft(element: any): number {
    let actualOffset = element.offsetLeft;
    let current = element.offsetParent;
    while (current != null) {
      actualOffset += current.offsetLeft;
      current = current.offsetParent;
    }
    return actualOffset;
  }

  return (
    <PopupContainer ref={containerRef} left={left}>
      <ItemsContainer>
        {items.map((item) => (
          <SelectItem
            key={`select${item.id}`}
            data={item}
            onClick={() => onSelect(item)}
            active={item.id === selected}
          />
        ))}
      </ItemsContainer>
    </PopupContainer>
  );
};

export default SelectPopup;
