import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IconPlus } from "@snowtam/ui";
import TopBar from "../../components/TopBar";
import TemplateModal from "../../components/TemplateModal";
import TemplateList from "../../components/TemplateList";
import Templates from "../../api/templates";

const AddButton = styled.button`
  border: none;
  padding: 16px 24px;
  background: ${(props) => props.theme.colors.active_light};
  border-radius: 4px;
  outline: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font_passive};
  transition: all 0.125s ease-in;
  display: flex;
  align-items: center;

  svg {
    transition: all 0.125s ease-in;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.font_passive};
  }

  &:hover,
  &:active {
    background: ${(props) => props.theme.colors.active};
    color: ${(props) => props.theme.colors.font_active};

    svg {
      color: ${(props) => props.theme.colors.font_active};
    }
  }
  @media (max-width: 767px) {
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0;
      width: 20px;
      height: 20px;
    }
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.blocks};
  @media (min-width: 1200px) {
    margin: 16px;
    border: 1px solid ${(props) => props.theme.colors.stroke_block};
    padding: 0 24px 24px;
  }
`;

const AddSpan = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;
const TemplatesPage: FC = () => {
  const [data, setData] = useState();
  const history = useHistory();
  const [addTemplate, setAddTemplate] = useState(false);
  const { t } = useTranslation("templates");

  const fetchData = () => {
    Templates.getList().then((res) => setData(res));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <>
        <TopBar
          title={t`templates`}
          onBack={() => history.push("/menu")}
          endAdornment={
            <AddButton onClick={() => setAddTemplate(true)} name={"add"}>
              <AddSpan>{t`create`}</AddSpan>
              <IconPlus />
            </AddButton>
          }
        />
        <Body>
          <TemplateList data={data} refetch={fetchData} />
        </Body>
        {addTemplate ? (
          <TemplateModal
            onClose={() => setAddTemplate(false)}
            onApply={() => {
              fetchData();
              window.scroll({ top: 0 });
              setAddTemplate(false);
            }}
          />
        ) : null}
      </>
    </>
  );
};
export default TemplatesPage;
