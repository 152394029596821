const friction = {
  name: "Name",
  create: "Create",
  no_friction_equipment: "Friction equipment list empty",
  friction_equipment: "Friction equipment",
  friction_equipment_title: "Friction equipment",
  friction_minimal_value: "Friction max value",
  friction_downgrade_rwycc: "Downgrade RWYCC",
  designation: "Designation",
  action: "Actions",
  delete_equipment: "Delete equipment",
  new_equipment: "New equipment",
  delete: "Delete",
  cancel: "Cancel",
  errors: {
    fill_all: "Fill all fields!",
    code_incorrect: "Incorrect airport code!",
    rwy_name_length: "Runway name must be at least 2 characters long.",
  },
};

export default friction;
