import { Redirect, Route, RouteProps } from "react-router-dom";
import React, { FC, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { ErrorPage, Layout } from "@snowtam/ui";
import AdminMenu from "../components/AdminMenu";
import UserDesktopMenu from "../components/UserDesktopMenu";
import UserStatus from "../components/UserStatus/UserStatus";
import { UserRoles } from "../Router";
import { ErrorBoundary } from "react-error-boundary";
import API from "../api/API";
import useSettings from "../hooks/useSettings";

interface PrivateRouteProps extends RouteProps {
  component: any;
  role: UserRoles | UserRoles[];
}

export const AuthRoute: FC<PrivateRouteProps> = ({
  component: Component,
  role,
  ...rest
}) => {
  useSettings();
  const loading = useSelector((state: RootState) => state.general.userLoading);
  const user = useSelector((state: RootState) => state.general.user);

  useEffect(() => {
    if (!loading && user) {
      API.fetchSettings();
    }
  }, [loading, user]);

  const isRoleAcceptable = () => {
    if (!user) return false;
    if (!Array.isArray(role)) {
      return user.role === role;
    } else {
      return role.includes(user.role);
    }
  };

  const checkRole = (role: UserRoles) => {
    if (!user) return false;

    return user.role === role;
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? (
          <></>
        ) : isRoleAcceptable() ? (
          <>
            {checkRole(UserRoles.Manager) ? (
              <Suspense fallback={<></>}>
                <Layout desktopMenu={() => <AdminMenu />}>
                  <ErrorBoundary FallbackComponent={ErrorPage}>
                    <Component {...props} />
                  </ErrorBoundary>
                </Layout>
              </Suspense>
            ) : null}
            {checkRole(UserRoles.Operator) ? (
              <Suspense fallback={<></>}>
                <Layout desktopMenu={() => <UserDesktopMenu />}>
                  <ErrorBoundary FallbackComponent={ErrorPage}>
                    <UserStatus />
                    <Component {...props} />
                  </ErrorBoundary>
                </Layout>
              </Suspense>
            ) : null}
          </>
        ) : (
          <Redirect to={"/"} />
        )
      }
    />
  );
};
export default AuthRoute;
