import { FC } from "react";
import { Switch } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { setRWYCC } from "../../../../../redux/snowtamform/snowtamformv2Reducer";
import styled from "styled-components";
import { rwyccValues } from "./Status";
import { RootState } from "../../../../../redux";
import { useTranslation } from "react-i18next";

const StyledSwitch = styled(Switch)`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

type ManualRWYCCSwitchProps = {
  third: 1 | 2 | 3;
  runwayId: number;
};

const ManualRWYCCSwitch: FC<ManualRWYCCSwitchProps> = ({ third, runwayId }) => {
  const { t } = useTranslation(["data_form"]);

  const dispatch = useDispatch();
  const value = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamFormV2.runways[index]?.thirds[third]?.manualRWYCC;
  });
  const handleChange = (val: boolean) => {
    if (val) {
      dispatch(
        setRWYCC({
          runwayId,
          third,
          value: rwyccValues.find((val) => val.id === 6),
        })
      );
    } else {
      dispatch(
        setRWYCC({
          runwayId,
          third,
          value: undefined,
        })
      );
    }
  };
  return (
    <StyledSwitch
      label={t`manualRWYCC`}
      onCheck={handleChange}
      name={"manual_rwycc"}
      checked={typeof value !== "undefined"}
    />
  );
};

export default ManualRWYCCSwitch;
