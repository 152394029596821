import { FC, useState } from "react";
import styled from "styled-components";
import ThirdsMenu from "./ThirdsMenu";
import ThirdForm from "./ThirdForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const TabMenu = styled.div`
  display: flex;
  border: 2px solid ${(props) => props.theme.colors.runways.stroke};
  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px 24px 16px 24px;
  @media (max-width: 767px) {
    padding: 16px;
  }
`;
const Thirds: FC<IRunway> = ({ runwayId }) => {
  const [third, setThird] = useState<1 | 2 | 3>(1);
  return (
    <Container>
      <ThirdsMenu active={third} onChange={(v) => setThird(v)} />
      <TabMenu>
        <ThirdForm active={third === 1} third={1} runwayId={runwayId} />
        <ThirdForm active={third === 2} third={2} runwayId={runwayId} />
        <ThirdForm active={third === 3} third={3} runwayId={runwayId} />
      </TabMenu>
    </Container>
  );
};

interface IRunway {
  runwayId: number;
}

export default Thirds;
