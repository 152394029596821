import { FC, useState } from "react";
import { TemplateModel } from "../../../@types/models";
import { Column, Row } from "./style";
import TemplateModal from "../TemplateModal";
import styled from "styled-components";
import { DialogYesNo, IconDelete, IconEdit, IconButton } from "@snowtam/ui";
import { useTranslation } from "react-i18next";
import Templates from "../../api/templates";

const ButtonsRow = styled.div`
  display: flex;

  & button:first-child {
    margin-right: 10px;
  }
`;

interface TemplateRowProps {
  data: TemplateModel;
  refetch: () => void;
}

const TemplateRow: FC<TemplateRowProps> = ({ data, refetch }) => {
  const [edit, setEdit] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const { t } = useTranslation(["common", "templates"]);
  const handleDelete = () => {
    Templates.deleteOne(data.id).then(() => {
      refetch();
      setDeleteDialog(false);
    });
  };

  return (
    <Row>
      <Column style={{ width: "100%" }}>{data.name}</Column>
      <Column>
        <ButtonsRow>
          <IconButton
            size="small"
            icon={() => <IconEdit />}
            color="blue"
            onClick={() => setEdit(true)}
          />
          <IconButton
            size="small"
            icon={() => <IconDelete />}
            color="red"
            onClick={() => setDeleteDialog(true)}
          />
        </ButtonsRow>
      </Column>
      {edit ? (
        <TemplateModal
          onClose={() => setEdit(false)}
          data={data}
          onApply={() => {
            setEdit(false);
            refetch();
          }}
        />
      ) : null}
      {deleteDialog ? (
        <>
          <DialogYesNo
            title={t`templates:delete_template`}
            caption={data.name}
            onApply={handleDelete}
            yesText={t`yes`}
            noText={t`no`}
            onClose={() => setDeleteDialog(false)}
          />
        </>
      ) : null}
    </Row>
  );
};
export default TemplateRow;
