import { Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  SnowbanksTaxiwayFormType,
  SnowtamFormStateTypeV2,
} from "../../../../@types/form";

const addApronStatusAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<SnowbanksTaxiwayFormType>
) => {
  if (action.payload.value === "ALL") {
    state.apron_status = [action.payload];
    return;
  }
  state.apron_status.push(action.payload);
  state.pause = true;
};

export default addApronStatusAction;
