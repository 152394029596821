import { useEffect, useState } from "react";
import API from "../api/API";

const useServerTime = () => {
  const [time, setTime] = useState<number>();

  const fetchTime = () => {
    API.getServerTime().then((res) => setTime(res.time));
  };

  useEffect(() => {
    fetchTime();
  }, []);

  return time;
};

export default useServerTime;
