import { FC } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useCountdown from "./hooks/useCountDown";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

type ExpirationCountdownProps = {
  snowtam_expire: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    font-size: 0.875rem;
  }
`;
const TextSpan = styled.span`
  font-weight: 600;
`;

const TimeSpan = styled.span`
  margin-left: 0.5rem;
  font-weight: normal;
`;

const ExpirationCountdown: FC<ExpirationCountdownProps> = ({
  snowtam_expire,
}) => {
  const countdown = useCountdown(snowtam_expire);
  const { t } = useTranslation("requests");
  return (
    <Container>
      <TextSpan>{t`until_expire`}:</TextSpan>
      <TimeSpan>{countdown}</TimeSpan>
    </Container>
  );
};

export default ExpirationCountdown;
