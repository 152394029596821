import { FC } from "react";
import styled from "styled-components";
import RCAMTablet from "./RCAMTablet";
import RCAMMobile from "./RCAMMobile";

const Tablet = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;
const Mobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const RCAM: FC = () => {
  return (
    <>
      <Tablet>
        <RCAMTablet />
      </Tablet>
      <Mobile>
        <RCAMMobile />
      </Mobile>
    </>
  );
};

export default RCAM;
