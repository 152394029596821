import { FC } from "react";
import Empty from "./Empty";
import styled from "styled-components";
import TableHeader from "./TableHeader";
import TemplateRow from "./TemplateRow";
import { TemplateModel } from "../../../@types/models";

const TemplateTable = styled.table`
  border-collapse: collapse;
`;

interface TemplateListProps {
  data?: TemplateModel[];
  refetch: () => void;
}

const TemplateList: FC<TemplateListProps> = ({ data, refetch }) => {
  return data && data.length > 0 ? (
    <TemplateTable>
      <TableHeader />
      <tbody>
        {data.map((item) => (
          <TemplateRow data={item} key={item.id} refetch={refetch} />
        ))}
      </tbody>
    </TemplateTable>
  ) : (
    <Empty />
  );
};

export default TemplateList;
