import { IconMonitorSoftLogo } from "@snowtam/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";
import preval from "preval.macro";
import packageJson from "../../../package.json";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  margin-top: auto;
`;

const Copyright = styled.p`
  margin: 16px 0 0 0;
  padding: 0;
  font-size: 0.625rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.font3};
`;

const Build = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.625rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.font3};
`;

const buildDate = preval`module.exports = new Date().toUTCString()`;

const pipeLineId = preval`module.exports = process.env.CI_PIPELINE_ID`;

const version = `${packageJson.version}${
  pipeLineId ? "-" + pipeLineId : ""
} ${buildDate}`;

const Info: FC = () => {
  const theme = useTheme();

  return (
    <Container>
      <IconMonitorSoftLogo
        color={theme.colors.inactive}
        height={32}
        width={88}
      />
      <Copyright>Copyright © 2009–2022 Monitorsoft s.r.o.</Copyright>
      <Build>Build {version}</Build>
    </Container>
  );
};

export default Info;
