import { OutlinedInput, PasswordInput } from "@snowtam/ui";
import { FC, FormEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Users from "../../../api/users";
import AddModal from "../../../components/AddModal";
import { UserRoles } from "../../../Router";

const Form = styled.form`
  display: flex;
  width: 100%;
  padding: 0;

  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CreateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: ${(props) => props.theme.colors.active};
  border-radius: 4px;
  padding: 16px;
  width: 276px;
  margin: 5rem auto 0 auto;
  color: ${(props) => props.theme.colors.font_active};
`;

const Error = styled.span`
  text-align: center;
  color: ${(props) => props.theme.colors.error};
  padding: 12px 0;
`;

interface ModalProps {
  title: string;
  onClose: () => void;
  onSuccess: () => void;
}

const Modal: FC<ModalProps> = ({ title, onClose, onSuccess }) => {
  const [name, setName] = useState<string>("");
  const [login, setLogin] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>();
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const { t } = useTranslation("common");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!name || !login || !password) {
      setError(t`errors.fill_all`);
      return;
    }
    Users.createOne({
      name,
      login,
      password,
      phone,
      role: UserRoles.Operator,
    })
      .then(() => onSuccess())
      .catch((error) => {
        if (error.data?.message) {
          setError(error.data.message);
        }
        if (error.data.error_fields) {
          setErrorFields(error.data.error_fields);
        }
      });
  };

  return (
    <AddModal title={title} onClose={onClose}>
      <Form onSubmit={handleSubmit}>
        <OutlinedInput
          name={"name"}
          label={t`user`}
          value={name}
          onChange={(e) => setName(e.target.value)}
          focusOnEnter
        />
        <OutlinedInput
          name={"phone"}
          label={t`phone`}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Row>
          <OutlinedInput
            name={"login"}
            label={t`login`}
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            style={{ maxWidth: "48%" }}
            error={!!error && errorFields.includes("login")}
          />
          <PasswordInput
            generateOnMount
            error={false}
            name={"password"}
            label={t`password`}
            value={password || ""}
            onChange={(value) => setPassword(value)}
            style={{ maxWidth: "48%" }}
          />
        </Row>
        {error ? <Error>{error}</Error> : null}
        <CreateButton name={"save"}>{t`create`}</CreateButton>
      </Form>
    </AddModal>
  );
};

export default Modal;
