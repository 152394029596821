import { OutlinedInput, PasswordInput } from "@snowtam/ui";
import { FC, FormEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Users from "../../../api/users";
import AddModal from "../../../components/AddModal";
import { UserRoles } from "../../../Router";

const Form = styled.form`
  display: flex;
  width: 100%;
  padding: 24px 0;
  flex-direction: column;
`;

const CreateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: ${(props) => props.theme.colors.active};
  border-radius: 4px;
  padding: 16px;
  width: 276px;
  margin: 16px auto 0 auto;
  color: ${(props) => props.theme.colors.font_active};
`;

const Error = styled.span`
  text-align: center;
  color: ${(props) => props.theme.colors.error};
  padding: 12px 0;
`;

interface PasswordProps {
  title: string;
  onClose: () => void;
  onSuccess: () => void;
  data: any;
}

const Password: FC<PasswordProps> = ({ title, onClose, onSuccess, data }) => {
  const [password, setPassword] = useState<string>();
  const [error, setError] = useState<string>();
  const { t } = useTranslation("common");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!password) {
      setError(t`errors.fill_all`);
      return;
    }
    Users.updateOne({
      id: data.id,
      password,
      role: UserRoles.Operator,
      status: 2,
    }).then(() => onSuccess());
  };

  return (
    <AddModal title={title} onClose={onClose}>
      <Form onSubmit={handleSubmit}>
        <OutlinedInput
          name={"name"}
          label={t`user`}
          value={data?.name || ""}
          inputProps={{ readOnly: true }}
        />
        <PasswordInput
          name={"password"}
          label={t`password`}
          value={password || ""}
          generateOnMount
          onChange={(value) => setPassword(value)}
          inputProps={{ type: "password" }}
          error={false}
        />
        {error ? <Error>{error}</Error> : null}
        <CreateButton name={"save"}>{t`change`}</CreateButton>
      </Form>
    </AddModal>
  );
};

export default Password;
