import { FC } from "react";
import { InfoPopup } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const Helper: FC = () => {
  const { t } = useTranslation("helper");

  return (
    <>
      <InfoPopup
        title={t`snowbanks_rwy.title`}
        text={<>{t`snowbanks_rwy.line1`}</>}
      />
    </>
  );
};
export default Helper;
