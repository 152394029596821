import API from "./API";

type CreateTemplateInput = {
  name: string;
  text: string;
};

type UpdateTemplateInput = {
  id: number;
  name?: string;
  text?: string;
};

class Templates {
  private api: typeof API;

  constructor() {
    this.api = API;
  }

  async createOne(data: CreateTemplateInput) {
    return this.api.post("/api/template", {
      ...data,
    });
  }

  async updateOne(data: UpdateTemplateInput) {
    return this.api.put("/api/template", {
      ...data,
    });
  }

  async deleteOne(id: number) {
    return this.api.delete("/api/template", {
      id,
    });
  }

  async getList() {
    return this.api.get("/api/template").then((res: any) => res?.data);
  }
}

export default new Templates();
