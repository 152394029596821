import { FC, useMemo } from "react";
import styled from "styled-components";
import { DropDownItem, LabeledBigCheckbox } from "@snowtam/ui";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { switchActive } from "../../../../redux/snowtamform/snowtamformv2Reducer";

const Item = styled.div<{ active: boolean }>`
  padding: 8px 12px;
  font-size: 1.125rem;
  line-height: 1.5rem;
  min-height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.dropdown_hover : theme.colors.dropdown_background};
  color: ${({ active, theme }) =>
    active ? theme.colors.dropdown_text : theme.colors.font1};
  font-weight: ${({ active }) => (active ? "600" : "400")};
  margin: 4px 0;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    transition: all 0.2s ease-out;
    background-color: ${({ theme }) => theme.colors.dropdown_hover};
    color: ${({ theme }) => theme.colors.dropdown_text};
  }

  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

const Container = styled.li`
  display: flex;
  align-items: center;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  position: relative;
  margin-left: auto;
  padding-left: 20px;
`;
const SelectItem: FC<{
  active?: boolean;
  data: DropDownItem;
  onClick: () => void;
}> = ({ active = false, data, onClick }) => {
  const runways = useSelector(
    (state: RootState) => state.snowtamFormV2.runways
  );

  const { t } = useTranslation("data_form");

  const dispatch = useDispatch();

  const isActive = useMemo(() => {
    const active_item = runways.find((rwy) => rwy.runwayId === data.id);
    return !!(active_item && active_item.active);
  }, [runways, data.id]);

  const handleChange = (val: boolean) => {
    dispatch(
      switchActive({
        runway_id: data.id,
        active: val,
      })
    );
  };

  const handleChoose = () => {
    if (isActive) onClick();
  };

  return (
    <Container>
      <Item active={active} onClick={handleChoose}>
        {data.value}
      </Item>
      <CheckBoxContainer>
        <LabeledBigCheckbox
          name={`rwy_active_${data.id}`}
          checked={isActive}
          onChange={handleChange}
          label={t`rwy_active`}
        />
      </CheckBoxContainer>
    </Container>
  );
};

export default SelectItem;
