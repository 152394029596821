import { DropDownItem } from "@snowtam/ui";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FrictionEquipment from "../../../api/friction-equipment";
import { RootState } from "../../../redux";

const EMPTY_ITEM = {
  id: 0,
  value: "",
  data: "",
};

const useGetFrictionDevices = (): DropDownItem[] => {
  const airport_code = useSelector(
    (state: RootState) => state.general.user?.airport.code
  );
  const [items, setItems] = useState<DropDownItem[]>([]);

  const fetch = useCallback(() => {
    if (!airport_code) return;
    return FrictionEquipment.findAll(airport_code).then((res) => {
      setItems([
        EMPTY_ITEM,
        ...res.map((item) => ({
          id: item.id,
          value: item.name,
          data: item.id,
        })),
      ]);
    });
  }, [airport_code]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return items;
};

export default useGetFrictionDevices;
