import { FC, useState } from "react";
import Created from "./Created";
import Navigator from "./Navigator";
import Comment from "./Comment";
import { RequestVersionsModel } from "../../../@types/models";
import styled from "styled-components";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";
import { Collapse } from "@snowtam/ui";

const ShowTextButton = styled.button`
  display: flex;
  margin: 0;
  padding: 0.25rem 0;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.active};
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
`;

const SnowTamText = styled.div`
  padding: 0.25rem 0;
  display: flex;
  flex-direction: column;
`;

type VersionDataProps = {
  data: RequestVersionsModel;
};

const VersionData: FC<VersionDataProps> = ({ data }) => {
  const [showText, setShowText] = useState(false);
  const { t } = useTranslation("data_form");

  return (
    <>
      <Created operator={data.operator.name} date={data.created_date}>
        <ShowTextButton onClick={() => setShowText((prevState) => !prevState)}>
          {showText ? t`hide_text` : t`show_text`}
        </ShowTextButton>
        <Collapse open={showText} duration={300}>
          <SimpleBar autoHide={false} style={{ paddingBottom: "24px" }}>
            <SnowTamText>
              {data.data.snowtam_initial
                .split("#")
                .map((line: string, index: number) => (
                  <span key={index}>{line}&nbsp;</span>
                ))}
            </SnowTamText>
          </SimpleBar>
        </Collapse>{" "}
      </Created>
      {data.navigator_id ? (
        <Navigator
          navigator={data.navigator_name || ""}
          phone={data.navigator_phone || ""}
        />
      ) : null}
      {data.decline_comment ? <Comment comment={data.decline_comment} /> : null}
    </>
  );
};
export default VersionData;
