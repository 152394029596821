import { FC, Fragment } from "react";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DialogYesNo } from "@snowtam/ui";
import RunwaysSelector from "./RunwaysSelector";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";

const Dialog = styled(DialogYesNo)`
  padding: 0 40px 24px 40px;
  margin: 20vh auto auto auto;
  @media (max-width: 767px) {
    padding: 0 16px 24px 16px !important;
  }
`;

const SnowTamMessageWrapper = styled.div`
  width: 100%;
`;
const SnowTamMessage = styled.div`
  padding-bottom: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const RunwaysBlock = styled.div``;

const MessageBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 16px 0;
`;
const Title = styled.span`
  display: flex;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.separator};
  font-size: 0.875rem;
`;

const Line = styled.hr`
  display: flex;
  width: 100%;
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.separator};
  margin: auto 0 auto 4px;
`;

interface SendDialogWithRunwaysProps {
  onSend: () => void;
  onClose: () => void;
  onChange: () => void;
  message?: string[];
}

const SendDialogWithRunways: FC<SendDialogWithRunwaysProps> = (props) => {
  const { t } = useTranslation(["data_form", "common"]);
  const total_runways = useSelector(
    (state: RootState) => state.general.user?.airport.runways.length || 0
  );
  const active_runways = useSelector(
    (state: RootState) =>
      state.snowtamFormV2.runways.filter(
        (item) => typeof item.active === "undefined" || item.active
      ).length
  );

  return (
    <Dialog
      title={t`data_form:creating_request`}
      onApply={props.onSend}
      yesText={t`common:yes`}
      yesDisabled={active_runways === 0}
      noText={t`common:cancel`}
      onClose={props.onClose}
    >
      <Container>
        {total_runways > 1 ? (
          <RunwaysBlock>
            <Label>
              <Title>{t`data_form:include_runways`}</Title>
              <Line />
            </Label>
            <RunwaysSelector onChange={props.onChange} />
          </RunwaysBlock>
        ) : null}
        <MessageBlock>
          <Label>
            <Title>{t`data_form:snowtam_text`}</Title>
            <Line />
          </Label>
          {active_runways > 0 ? (
            <SnowTamMessageWrapper>
              <SimpleBar autoHide={false}>
                <SnowTamMessage>
                  {props.message?.map((item: string, index: number) => (
                    <Fragment key={index}>
                      <span style={{ whiteSpace: "nowrap" }}>{item}</span>
                      <br />
                    </Fragment>
                  ))}
                </SnowTamMessage>
              </SimpleBar>
            </SnowTamMessageWrapper>
          ) : null}
        </MessageBlock>
      </Container>
    </Dialog>
  );
};

export default SendDialogWithRunways;
