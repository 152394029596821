import { FC } from "react";
import SubTitle from "../../SubTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import styled from "styled-components";
import AddTaxiway from "./AddTaxiway";
import TaxiwayItem from "./TaxiwayItem";
import Helper from "./Helper";
import { Group } from "../../styles";
import { useTranslation } from "react-i18next";
import SoloInput from "./SoloInput";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const TaxiwayStatus: FC = () => {
  const selected = useSelector(
    (state: RootState) => state.snowtamForm.taxiway_status
  );
  const taxiways = useSelector(
    (state: RootState) => state.general.user?.airport.taxiways
  );
  const { t } = useTranslation("data_form");

  const rendered: number[] = [];

  return (
    <Group>
      <SubTitle label={t`status_twy`} endAdornment={<Helper />} />
      <Row>
        {taxiways && taxiways.length > 0 ? (
          <>
            {selected && selected.length > 0
              ? selected.map((item, index) => {
                  const selected = [...rendered];
                  rendered.push(item.id);
                  return (
                    <TaxiwayItem
                      key={`sbi${index}`}
                      item={item}
                      index={index}
                      selected={selected}
                    />
                  );
                })
              : null}
            <AddTaxiway />
          </>
        ) : (
          <SoloInput />
        )}
      </Row>
    </Group>
  );
};

export default TaxiwayStatus;
