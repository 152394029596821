import { FC, FormEvent, memo, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { IconInput, Button, IconLogoV2, DialogYesNo } from "@snowtam/ui";
import { useDispatch } from "react-redux";
import { setUserLoading } from "../../redux/generalReducer";
import { useTranslation } from "react-i18next";

import About from "./About";
import Auth from "../../api/auth";
import useGetInitialError from "./hooks/useGetInitiialError";

// noinspection CssUnknownTarget
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: center / cover no-repeat
    url("/assets/${({ theme }) => theme.value}/desktopBackground.svg");
  @media (max-width: 1199px) {
    background: center / cover no-repeat
      url("/assets/${({ theme }) => theme.value}/tabletBackground.svg");
    padding: 0 4.5rem;
  }
  @media (max-width: 767px) {
    background: center / cover no-repeat
      url("/assets/${({ theme }) => theme.value}/mobileBackground.svg");
    padding: 0 1rem;
  }
  @media (max-width: 467px) {
    padding: 0 0.5rem;
  }
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 2.625rem;
  line-height: 2.625rem;
  color: ${(props) => props.theme.colors.font1};
  margin: 1rem;
  padding: 0;
  @media (max-width: 992px) {
    font-size: 2rem;
    line-height: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem;
  margin: auto;
  width: 738px;
  max-width: 100vw;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.blocks};
  border: 1px solid ${({ theme }) => theme.colors.stroke_block};
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  border-radius: 8px;
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 467px) {
    padding: 3rem 1.5rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ErrorMessage = styled.p`
  padding: 0;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.error};
`;

const LoginButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 1;
  }
`;

const LogoTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;

  svg {
    margin-right: 0.5rem;
  }

  margin-bottom: 0.5rem;
  @media (max-width: 992px) {
    font-size: 1.25rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

const SubTitle = styled.p`
  margin: 0 0 1rem;
  padding: 0;
  color: ${({ theme }) => theme.colors.font3};
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  @media (max-width: 992px) {
    font-size: 0.875rem;
  }
  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
`;

type LoginState = {
  login: string;
  password: string;
};

const initialState: LoginState = {
  login: "",
  password: "",
};

const LoginForm: FC = () => {
  const initialError = useGetInitialError();
  const [state, setLocalState] = useState<LoginState>(initialState);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const dispatch = useDispatch();
  const [t] = useTranslation(["login", "common"]);
  const theme = useTheme();

  useEffect(() => {
    if (initialError) {
      setError(t("login:error." + initialError));
    }
  }, [initialError, t]);

  const handleLogin = (user_login: string, password: string, force = false) => {
    setLoading(true);
    Auth.login({
      login: user_login,
      password,
      type: "operator",
      force,
    })
      .then((res) => {
        if (res.token) {
          localStorage.setItem("token", res.token);
          localStorage.setItem("refresh_token", res.refresh_token);
          dispatch(setUserLoading(true));
        }
      })
      .catch((error: any) => {
        if (error?.response.data.authorized) {
          setAuthorized(true);
          return;
        }
        if (error?.response?.data?.message) {
          setError(error?.response?.data?.message);
        } else {
          setError(t`login:unauthorized`);
        }
        setLocalState((prev) => ({ ...prev, password: "" }));
      })
      .finally(() => setLoading(false));
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin(state.login, state.password);
  };

  return (
    <Container>
      <LoginBox>
        <LogoTitle>
          <IconLogoV2 color={theme.colors.active} />
          SNOWTAM
        </LogoTitle>
        <Title>Вход в систему</Title>
        <SubTitle>
          Программа для предоставления сообщений о данных по состоянию
          взлетно-посадочных полос аэродромов
        </SubTitle>
        <Form onSubmit={handleSubmit} name="login" method="POST">
          <IconInput
            style={{ margin: "0.5rem 0" }}
            placeholder={t`user`}
            name="login"
            value={state.login}
            focusOnEnter
            onChange={(event) =>
              setLocalState((prev) => ({ ...prev, login: event.target.value }))
            }
          />
          <IconInput
            placeholder={t`password`}
            style={{ margin: "0.5rem 0" }}
            name="password"
            type="password"
            value={state.password}
            onChange={(event) =>
              setLocalState((prev) => ({
                ...prev,
                password: event.target.value,
              }))
            }
          />
          {!!error ? <ErrorMessage>{error}</ErrorMessage> : null}
          <LoginButton type="submit" disabled={loading}>
            {t`sign_in`}
          </LoginButton>
        </Form>
      </LoginBox>
      <About />
      {authorized ? (
        <>
          <DialogYesNo
            title={"Пользователь уже в системе!"}
            caption={
              "Вы уверены что хотите войти? Текущий авторизованный пользователь будет отключен!"
            }
            onApply={() => handleLogin(state.login, state.password, true)}
            yesText={"Да"}
            noText={"Нет"}
            onClose={() => setAuthorized(false)}
            onCancel={() => setAuthorized(false)}
          />
        </>
      ) : null}
    </Container>
  );
};
export default memo(LoginForm);
