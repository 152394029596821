import { FC, useMemo } from "react";
import { DropDownItem, DropDownNaked } from "@snowtam/ui";
import { useDispatch } from "react-redux";
import { setComment } from "../../../../redux/snowtamform/snowtamformv2Reducer";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

const DropDown = styled(DropDownNaked)`
  min-width: unset;
`;

const SelectorSpan = styled.span`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.active};
`;

const Templates: FC<{ data: any }> = ({ data }) => {
  const items: DropDownItem[] | undefined = useMemo(() => {
    if (data) {
      return data.map((item: any) => ({
        id: item.id,
        value: item.name,
        data: item.text,
      }));
    }
  }, [data]);
  const dispatch = useDispatch();

  const { t } = useTranslation(["data_form", "common"]);

  return (
    <>
      {items && items.length > 0 ? (
        <>
          <DropDown
            _id={"select_template"}
            items={items}
            small={true}
            minItems={0}
            renderValue={() => (
              <SelectorSpan>
                {window.innerWidth > 767
                  ? t`choose_template`
                  : t`choose_template_mobile`}
              </SelectorSpan>
            )}
            options={{
              containerStyle: css`
                top: unset;
                bottom: calc(100% + 8px);
                left: unset;
                width: 320px;
              `,
            }}
            onChoose={(item) => {
              dispatch(setComment(item.data));
            }}
          />
        </>
      ) : null}
    </>
  );
};
export default Templates;
