import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  OutlinedTextarea,
  IconSave,
  IconEditText,
  GroupTitle,
  IconButton,
} from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setComment } from "../../../../redux/snowtamformReducer";
import TemplatesList from "./Templates";
import { Group as GroupStyle } from "../../styles";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Templates from "../../../../api/templates";

const Group = styled(GroupStyle)`
  &:after {
    content: none;
  }
`;

const TemplateButton = styled(IconButton)`
  background: ${({ theme }) => theme.sub_colors.template_save.main.background};
  transition: all 0.125s ease-out;

  svg {
    color: ${({ theme }) => theme.sub_colors.template_save.main.icon};
  }

  &:hover {
    background: ${({ theme }) =>
      theme.sub_colors.template_save.hover.background};

    svg {
      color: ${({ theme }) => theme.sub_colors.template_save.hover.icon};
    }
  }
`;
const Comment: FC = () => {
  const value = useSelector((state: RootState) => state.snowtamForm.comment);
  const [templates, setTemplates] = useState();
  const { t } = useTranslation(["data_form", "common"]);
  const dispatch = useDispatch();
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.replace(/[~@#$%^&*{}\]["]/g, "");
    dispatch(setComment(value));
  };

  const fetchTemplates = () => {
    Templates.getList().then((res) => setTemplates(res));
  };

  const handleSave = () => {
    if (value.length === 0) {
      return;
    }
    Templates.createOne({
      name: value.length > 50 ? value.slice(0, 50) + "..." : value,
      text: value,
    }).then(fetchTemplates);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <Group>
      <GroupTitle
        label={t`data_form:comment`}
        icon={<IconEditText />}
        endAdornmentButton={
          <TemplateButton
            id={"save_template"}
            icon={() => <IconSave />}
            color="blue"
            onClick={handleSave}
          />
        }
      />
      <TemplatesList data={templates} />
      <OutlinedTextarea
        name={"comment"}
        label={t`data_form:comment_text`}
        maxLength={1200}
        value={value || ""}
        onChange={handleChange}
        style={{ marginTop: "24px" }}
        autoHeight
        totalCharactersText={t`common:total_characters`}
      />
    </Group>
  );
};

export default Comment;
