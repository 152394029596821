import {
  DialogYesNo,
  IconButton,
  IconDelete,
  IconRefresh,
  IconSettings,
  PasswordLock,
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from "@snowtam/ui";
import dayjs from "dayjs";
import printJS from "print-js";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import Users from "../../api/users";
import TopMenu from "../TopMenu";
import Empty from "./Empty";
import Modal from "./Modal";
import Password from "./Modal/Password";
import Status from "./Modal/Status";

const Container = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.blocks};
  border: 1px solid ${(props) => props.theme.colors.stroke_block};
  box-sizing: border-box;
  padding: 0 24px;
  width: 100%;
`;

const StatusSpan = styled.span<{ status: number }>`
  padding: 4px 8px;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 4px;
  ${({ status }) => {
    switch (status) {
      case 0:
        return css`
          background: ${(props) => props.theme.colors.error_background};
          color: ${(props) => props.theme.colors.error};
        `;
      case 1:
        return css`
          background: ${(props) => props.theme.colors.success_background};
          color: ${(props) => props.theme.colors.success};
        `;
      case 2:
        return css`
          background: ${(props) => props.theme.colors.active_background};
          color: ${(props) => props.theme.colors.active};
        `;
    }
  }}
`;

const UsersList: FC = () => {
  const [addModal, setAddModal] = useState(false);
  const [data, setData] = useState<any>();

  const [deleteForm, setDeleteForm] = useState<{ show: boolean; data: any }>({
    show: false,
    data: {},
  });
  const [errorDialog, setErrorDialog] = useState({ show: false, message: "" });
  const [editForm, setEditForm] = useState<{
    show: boolean;
    type?: "status" | "password";
    data?: any;
  }>({
    show: false,
  });

  const { t } = useTranslation("common");

  const fetchUsers = () => {
    Users.findAll().then((res) => setData(res));
  };
  const handleShowDelete = (item: any) => {
    setDeleteForm({
      show: true,
      data: item,
    });
  };

  const handleDelete = (item: any) => {
    setDeleteForm({ show: false, data: {} });
    Users.deleteOne(item.id).then(fetchUsers);
    // TODO catch
  };

  const handlePrint = () => {
    const printableData = data.map((item: any) => {
      let status;
      switch (item.status) {
        case 0:
          status = t`suspended`;
          break;
        case 1:
          status = t`accepted`;
          break;
        case 2:
          status = t`password`;
          break;
        default:
          status = "";
      }
      return {
        ...item,
        status,
        status_changed: dayjs(item.updated_at).format("DD.MM.YYYY HH:mm"),
      };
    });
    printJS({
      printable: printableData,
      properties: [
        { field: "name", displayName: t`operator` },
        { field: "login", displayName: t`login` },
        { field: "password", displayName: t`password` },
        { field: "phone", displayName: t`phone` },
        { field: "status_changed", displayName: t`status_changed` },
        { field: "status", displayName: t`status` },
      ],
      documentTitle: t`print_title_users`,
      type: "json",
      gridStyle: `padding: 0.5rem; border: 1px solid black`,
      gridHeaderStyle: `padding: 0.5rem;border: 1px solid black`,
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <TopMenu
        title={""}
        onAddPress={() => setAddModal(true)}
        onPrintPress={handlePrint}
      />
      <Container>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderColumn>{t`operator`}</TableHeaderColumn>
              <TableHeaderColumn>{t`login`}</TableHeaderColumn>
              <TableHeaderColumn>{t`password`}</TableHeaderColumn>
              <TableHeaderColumn>{t`phone`}</TableHeaderColumn>
              <TableHeaderColumn>{t`status_changed`}</TableHeaderColumn>
              <TableHeaderColumn
                style={{ width: "1%", textAlign: "center" }}
              >{t`status`}</TableHeaderColumn>
              <TableHeaderColumn style={{ width: "1%", textAlign: "center" }}>
                {t`change_status_action`}
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: "1%", textAlign: "center" }}>
                {t`change_password_action`}
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: "1%", textAlign: "center" }}>
                {t`delete`}
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((item: any) => (
                <TableRow key={item.id}>
                  <TableColumn>{item.name}</TableColumn>
                  <TableColumn>{item.login}</TableColumn>
                  <TableColumn>
                    <PasswordLock password={item.password} />
                  </TableColumn>
                  <TableColumn>{item.phone}</TableColumn>
                  <TableColumn>
                    {dayjs(item.updated_at).format("DD.MM.YYYY HH:mm")}
                  </TableColumn>
                  <TableColumn>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <StatusSpan status={item.status}>
                        {item.status === 0 ? t`suspended` : null}
                        {item.status === 1 ? t`accepted` : null}
                        {item.status === 2 ? t`password` : null}
                      </StatusSpan>
                    </div>
                  </TableColumn>
                  <TableColumn>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton
                        type="button"
                        color="blue"
                        size="small"
                        name="edit"
                        icon={() => <IconRefresh />}
                        onClick={() =>
                          setEditForm({
                            show: true,
                            type: "status",
                            data: item,
                          })
                        }
                      />
                    </div>
                  </TableColumn>
                  <TableColumn>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton
                        type="button"
                        color="orange"
                        size="small"
                        name="password"
                        icon={() => <IconSettings />}
                        onClick={() =>
                          setEditForm({
                            show: true,
                            type: "password",
                            data: item,
                          })
                        }
                      />
                    </div>
                  </TableColumn>
                  <TableColumn>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton
                        type="button"
                        color="red"
                        size="small"
                        name="delete"
                        icon={() => <IconDelete />}
                        onClick={() => handleShowDelete(item)}
                      />
                    </div>
                  </TableColumn>
                </TableRow>
              ))
            ) : (
              <Empty />
            )}
          </TableBody>
        </Table>
      </Container>
      {addModal ? (
        <Modal
          title={t`new_operator_title`}
          onClose={() => setAddModal(false)}
          onSuccess={() => {
            fetchUsers();
            setAddModal(false);
          }}
        />
      ) : null}
      {editForm.show && editForm?.type === "status" ? (
        <Status
          title={t`change_status_title`}
          onClose={() => setEditForm({ show: false })}
          data={editForm.data}
          onSuccess={() => {
            fetchUsers();
            setEditForm({ show: false });
          }}
        />
      ) : null}
      {editForm.show && editForm?.type === "password" ? (
        <Password
          title={t`change_password_title`}
          onClose={() => setEditForm({ show: false })}
          data={editForm.data}
          onSuccess={() => {
            fetchUsers();
            setEditForm({ show: false });
          }}
        />
      ) : null}
      {deleteForm.show ? (
        <DialogYesNo
          title={t`delete_operator_title`}
          caption={deleteForm.data.name}
          onClose={() => setDeleteForm({ show: false, data: {} })}
          yesText={t`delete`}
          noText={t`cancel`}
          onApply={() => handleDelete(deleteForm.data)}
        />
      ) : null}
      {errorDialog.show ? (
        <DialogYesNo
          title={t`error`}
          onApply={() => setErrorDialog({ show: false, message: "" })}
          yesText={t`OK`}
          caption={errorDialog.message}
        />
      ) : null}
    </>
  );
};
export default UsersList;
