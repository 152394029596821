import { FC } from "react";
import { RequestVersionsModel } from "../../../@types/models";
import VersionData from "./VersionData";
import styled from "styled-components";

const Separator = styled.hr`
  margin: 12px 0;
  padding: 0;
  border: none;
  height: 1px;
  background: ${({ theme }) => theme.colors.separator};
`;

type HistoryProps = {
  versions: RequestVersionsModel[];
};

const History: FC<HistoryProps> = ({ versions }) => {
  return (
    <>
      {versions.map((version) => (
        <>
          <VersionData data={version} />
          <Separator />
        </>
      ))}
    </>
  );
};

export default History;
