import {
  FC,
  memo,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import useFormInitializer from "./hooks/useFormInitializer";
import { RootState } from "../../redux";
import useQueryParam from "./hooks/useQueryParam";
import { DialogYesNo } from "@snowtam/ui";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorSpan = styled.span`
  margin-top: 1rem;
  text-align: center;
`;

const Initializer: FC<PropsWithChildren<any>> = (props) => {
  const formPaused = useSelector(
    (state: RootState) => state.snowtamFormV2.pause
  );

  const [paused, setPaused] = useState(false);

  const query = useQueryParam();

  const from = useMemo(() => query.get("from"), [query]);
  const request = useMemo(() => query.get("request"), [query]);

  const firstLoad = useRef<boolean>(true);

  const history = useHistory();

  const { t } = useTranslation("common");

  const [initForm, initialized] = useFormInitializer();

  useEffect(() => {
    if (!firstLoad.current) return;
    if ((from || request) && formPaused) {
      setPaused(true);
    } else {
      initForm();
    }
    firstLoad.current = false;
  }, [formPaused, from, initForm, request]);

  return (
    <>
      {paused ? (
        <DialogYesNo
          title={t`errors.warning`}
          caption={t`errors.paused_form`}
          onApply={() => {
            setPaused(false);
            initForm();
          }}
          yesText={t`continue`}
          onClose={() => history.goBack()}
          noText={t`cancel`}
        >
          <ErrorSpan>{t`errors.are_you_sure`}</ErrorSpan>
        </DialogYesNo>
      ) : null}
      {initialized ? props.children : null}
    </>
  );
};

export default memo(Initializer);
