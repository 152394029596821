import { FC } from "react";
import { BorderedCard } from "./cardStyles";
import CardLeft from "./CardLeft";
import { IconNewDocument, IconMonitorSoftLogo } from "@snowtam/ui";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 767px) {
    margin-top: 1rem;
  }
`;

const Text = styled.p`
  margin: 16px 0 0 0;
  line-height: 1.5rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font1};
  padding: 0;
`;

const About: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation("settings");

  return (
    <BorderedCard>
      <CardLeft
        icon={() => (
          <IconNewDocument color={theme.colors.active} width={24} height={24} />
        )}
        title={t`about`}
        caption={t`about_caption`}
      />
      <Container>
        <IconMonitorSoftLogo />
        <Text>{t`about_description`}</Text>
      </Container>
    </BorderedCard>
  );
};

export default About;
