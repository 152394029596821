import { FC, MutableRefObject } from "react";
import { InfoPopupWindow } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const ReducedPopup: FC<{
  onClose: () => void;
  toggler?: MutableRefObject<any>;
}> = ({ onClose, toggler }) => {
  const { t } = useTranslation("helper");
  return (
    <>
      <InfoPopupWindow
        title={t`dimensions.rwy_reduced`}
        text={<>{t`dimensions.reduced_text`}</>}
        onClose={onClose}
        toggler={toggler}
      />
    </>
  );
};

export default ReducedPopup;
