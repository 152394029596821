import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { GeneralStateType } from "../../generalReducer";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";
import { appendRunwayToState } from "../utils/runwayDataGenerator";
import initialState from "../initialState";

const initializeFormAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{
    data: GeneralStateType;
    time: number;
    user_id: number | undefined;
  }>
) => {
  let initialData: SnowtamFormStateTypeV2 = {
    ...initialState,
    runways: [],
    reduced: [],
    snowstorm: [],
    sand: [],
    treated: [],
    snowbanks_near_runway: [],
    snowbank_runway_position: [],
    friction_form: [],
    user_id: action.payload.user_id,
  };

  action.payload.data.user?.airport.runways?.forEach((runway) => {
    initialData = appendRunwayToState(initialData, runway, action.payload.time);
  });
  return initialData;
};

export default initializeFormAction;
