import { FC } from "react";
import styled, { css, useTheme } from "styled-components";
import { RequestModel } from "../../../@types/models";
import {
  IconRoundCheck,
  IconRoundDecline,
  IconClock,
  IconInformation,
} from "@snowtam/ui";
import { useTranslation } from "react-i18next";
import { RequestStatus } from "../../types/status";

const StatusContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
  }

  @media (max-width: 767px) {
    margin: 0 auto;
    svg {
      display: none;
    }
  }
`;

const Text = styled.p<StatusProps>`
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${({ status, theme }) => {
    switch (status) {
      case RequestStatus.Void:
      case RequestStatus.Rejected:
      case RequestStatus.Declined:
        return theme.colors.error;
      case RequestStatus.New:
        return theme.colors.warning;
      case RequestStatus.InProgress:
      case RequestStatus.Sent:
      case RequestStatus.NotSent:
        return theme.colors.active;
      case RequestStatus.Published:
        return theme.colors.success;
      case RequestStatus.Expired:
        return theme.colors.inactive;
    }
  }};
  @media (max-width: 767px) {
    padding: 4px 8px;
    font-size: 0.75rem;
    line-height: 1rem;
    border-radius: 4px;
    display: inline-block;

    ${({ status }) => {
      switch (status) {
        case RequestStatus.Void:
        case RequestStatus.Rejected:
        case RequestStatus.Declined:
          return css`
            background: ${(props) => props.theme.colors.error_background};
            color: ${(props) => props.theme.colors.error};
          `;
        case RequestStatus.New:
          return css`
            background: ${(props) => props.theme.colors.warning_background};
            color: ${(props) => props.theme.colors.warning};
          `;
        case RequestStatus.InProgress:
        case RequestStatus.Sent:
        case RequestStatus.NotSent:
          return css`
            background: ${(props) => props.theme.colors.active_background};
            color: ${(props) => props.theme.colors.active};
          `;
        case RequestStatus.Published:
          return css`
            background: ${(props) => props.theme.colors.success_background};
            color: ${(props) => props.theme.colors.success};
          `;
        case RequestStatus.Expired:
          return css`
            background: ${(props) => props.theme.colors.inactive_background};
            color: ${(props) => props.theme.colors.inactive};
          `;
      }
    }}
  }
`;
type StatusProps = Pick<RequestModel, "status"> & {
  className?: string;
};

const Status: FC<StatusProps> = ({ status, className }) => {
  const { t } = useTranslation("requests");
  const theme = useTheme();
  return (
    <StatusContainer className={className}>
      <Text status={status}>
        {status === RequestStatus.Void ? t`status.canceled` : null}
        {status === RequestStatus.Declined ? t`status.declined` : null}
        {status === RequestStatus.Rejected ? t`status.rejected` : null}
        {status === RequestStatus.New ? t`status.new` : null}
        {status === RequestStatus.InProgress ||
        status === RequestStatus.Sent ||
        status === RequestStatus.NotSent
          ? t`status.in_progress`
          : null}
        {status === RequestStatus.Published ? t`status.published` : null}
        {status === RequestStatus.Expired ? t`status.expired` : null}
      </Text>
      {status === RequestStatus.Void ||
      status === RequestStatus.Declined ||
      status === RequestStatus.Rejected ? (
        <IconRoundDecline />
      ) : null}
      {status === RequestStatus.New ? (
        <IconRoundCheck color={theme.colors.warning} />
      ) : null}
      {status === RequestStatus.InProgress ||
      status === RequestStatus.Sent ||
      status === RequestStatus.NotSent ? (
        <IconClock />
      ) : null}
      {status === RequestStatus.Published ? (
        <IconRoundCheck color={theme.colors.success} />
      ) : null}
      {status === RequestStatus.Expired ? (
        <IconInformation color={theme.colors.inactive} />
      ) : null}
    </StatusContainer>
  );
};

export default Status;
