import { IconMonitorSoftLogo } from "@snowtam/ui";
import { FC } from "react";
import styled, { useTheme } from "styled-components";
import preval from "preval.macro";
import packageJson from "../../../package.json";

const Container = styled.div`
  display: flex;
  padding: 24px 0;
  margin: 0 auto;
  align-items: flex-end;
  @media (max-width: 767px) {
    svg {
      max-width: 100px;
    }
  }
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.625rem;
  line-height: 1rem;
  opacity: 0.5;
  color: ${({ theme }) => theme.colors.runways.stroke};
  @media (max-width: 767px) {
    font-size: 0.5rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 8px;
`;
const buildDate = preval`module.exports = new Date().toUTCString()`;

const pipeLineId = preval`module.exports = process.env.CI_PIPELINE_ID`;

const version = `${packageJson.version}${
  pipeLineId ? "-" + pipeLineId : ""
} ${buildDate}`;

const About: FC = () => {
  const theme = useTheme();

  return (
    <Container>
      <IconMonitorSoftLogo
        color={theme.colors.runways.stroke}
        style={{ opacity: "0.5" }}
      />
      <TextWrapper>
        <Text>Copyright © 2009–2022 Monitorsoft s.r.o.</Text>
        <Text>Build {version}</Text>
      </TextWrapper>
    </Container>
  );
};

export default About;
