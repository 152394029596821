import { FC, useState } from "react";
import RunwayFields from "./RunwayFields";
import GeneralFields from "./GeneralFields";
import SendButton from "./SendRequest";
import NoRunwaysSelected from "./NoRunwaysSelected";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Body = styled.div`
  padding: 16px;
  height: 100%;
  @media (max-width: 767px) {
    padding: 0;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: ${(props) => props.theme.colors.active};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.font_active};
  margin: 0 auto;
  padding: 16px 88px;
  font-weight: bold;
`;

const Form: FC<{ runwayId?: number | null; isUpdate?: boolean }> = ({
  runwayId,
  isUpdate,
}) => {
  const [sendRequest, setSendRequest] = useState(false);
  const { t } = useTranslation(["data_form", "common"]);

  return (
    <>
      <Body>
        {runwayId ? (
          <>
            <RunwayFields runwayId={runwayId} key={runwayId} />
            <GeneralFields>
              <Button
                id={"send_form"}
                name={"send_form"}
                type="button"
                onClick={() => setSendRequest(true)}
              >
                {t`data_form:send`}
              </Button>
            </GeneralFields>
          </>
        ) : (
          <NoRunwaysSelected />
        )}
        {sendRequest ? (
          <SendButton
            isUpdate={isUpdate}
            onClose={() => setSendRequest(false)}
          />
        ) : null}
      </Body>
    </>
  );
};

export default Form;
