import { Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  SnowbanksTaxiwayFormType,
  SnowtamFormStateTypeV2,
} from "../../../../@types/form";

const updateTaxiwayStatusAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{ data: SnowbanksTaxiwayFormType; prevId: number }>
) => {
  if (action.payload.data.value === "ALL") {
    state.taxiway_status = [action.payload.data];
    return;
  }

  const itemIndex = state.taxiway_status.findIndex(
    (item) => item.id === action.payload.prevId
  );

  state.taxiway_status[itemIndex] = action.payload.data;

  state.pause = true;
};

export default updateTaxiwayStatusAction;
