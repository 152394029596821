import { FC } from "react";
import { RequestModel } from "../../../@types/models";
import styled, { css } from "styled-components";
import Created from "./Created";
import Navigator from "./Navigator";
import Snowtam from "./Snowtam";
import DeclineButton from "./DeclineButton";
import Title from "./Title";
import Declined from "./Declined";
import Refill from "./Refill";
import { UserRoles } from "../../Router";
import CanAccess from "../CanAccess";
import { RequestStatus } from "../../types/status";
import ExpirationCountdown from "./ExpirationCountdown";

export const DataRow = styled.tr`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: ${(props) => props.theme.colors.separator};
  }

  @media (max-width: 767px) {
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    &:last-child:after {
      content: none;
    }
  }
`;

const DataWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px 24px 24px 24px;
  position: relative;
  @media (max-width: 1199px) {
    padding: 12px 24px 24px 24px;
  }
  @media (max-width: 767px) {
    padding: 4px 16px 16px 16px;
  }
`;
const Container = styled.div<{ noLastBorder?: boolean }>`
  width: 100%;
  ${({ noLastBorder }) =>
    noLastBorder
      ? css`
          ${DataRow}:last-child:after {
            content: none;
          }
        `
      : null}
`;

interface PublicationCardProps {
  data: RequestModel;
  noLastBorder?: boolean;
  refetch?: () => void;
}

const PublicationCard: FC<PublicationCardProps> = ({
  data,
  noLastBorder,
  refetch,
}) => {
  return (
    <Container noLastBorder={noLastBorder}>
      <Title
        status={data.status}
        request_id={data.id}
        snowtam_expire={data.snowtam_expire}
      />
      <DataWrapper>
        {data.status === RequestStatus.Rejected ? (
          <>
            <Navigator
              navigator={data.navigator_name || ""}
              phone={data.navigator_phone || ""}
            />
            <Declined
              operator={data.operator}
              date={data.created_date}
              comment={data.decline_comment}
            />
            <CanAccess roles={[UserRoles.Operator]}>
              <Refill request_id={data.id} />
            </CanAccess>
          </>
        ) : null}
        {data.status === RequestStatus.Declined ? (
          <Declined
            operator={data.operator}
            date={data.created_date}
            comment={data.decline_comment}
          />
        ) : null}
        {data.status === RequestStatus.New ? (
          <>
            <Created operator={data.operator} date={data.created_date} />
            {typeof refetch === "function" ? (
              <DeclineButton onDecline={() => refetch()} />
            ) : null}
          </>
        ) : null}
        {data.status === RequestStatus.Void ? (
          <>
            <Created operator={data.operator} date={data.created_date} />
          </>
        ) : null}
        {data.status === RequestStatus.InProgress ||
        data.status === RequestStatus.Sent ||
        data.status === RequestStatus.NotSent ? (
          <Navigator
            navigator={data.navigator_name || ""}
            phone={data.navigator_phone || ""}
          />
        ) : null}
        {data.status === RequestStatus.Published ||
        data.status === RequestStatus.Expired ? (
          <Snowtam snowtam={data.snowtam_id || ""} date={data.published_date} />
        ) : null}
        {window.innerWidth < 767 &&
        data.status === RequestStatus.Published &&
        data.snowtam_expire ? (
          <>
            <ExpirationCountdown snowtam_expire={data.snowtam_expire} />
          </>
        ) : null}
      </DataWrapper>
    </Container>
  );
};

export default PublicationCard;
