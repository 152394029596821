import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const removeSnowbanksTaxiwayByIdAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<number>
) => {
  const index = state.snowbanks_taxiway.findIndex(
    (item) => item.id === action.payload
  );
  if (index === -1) return;

  state.snowbanks_taxiway.splice(index, 1);
  state.pause = true;
};

export default removeSnowbanksTaxiwayByIdAction;
