import { DropDownItem } from "@snowtam/ui";
import i18next from "../i18n";
import { RWYPollutionCoverage } from "./runwayStates.constants";

class FormFields {
  constructor() {
    i18next.on("languageChanged", () => {
      this.pollutionDepthItems = this.getPollutionDepthItems();
    });
  }

  pollutionDepthItems = this.getPollutionDepthItems();

  protected getPollutionDepthItems(): DropDownItem[] {
    return [
      {
        id: 0,
        value: i18next.t`data_form:pollution_depth.nr`,
        data: RWYPollutionCoverage.NR,
      },
      {
        id: 2,
        value: i18next.t`data_form:pollution_depth.25`,
        data: RWYPollutionCoverage.TwentyFive,
      },
      {
        id: 3,
        value: i18next.t`data_form:pollution_depth.50`,
        data: RWYPollutionCoverage.Fifty,
      },
      {
        id: 4,
        value: i18next.t`data_form:pollution_depth.75`,
        data: RWYPollutionCoverage.SeventyFive,
      },
      {
        id: 5,
        value: i18next.t`data_form:pollution_depth.100`,
        data: RWYPollutionCoverage.OneHundred,
      },
    ];
  }
}

export default new FormFields();
