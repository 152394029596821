import { useSelector } from "react-redux";
import { theme as themeData } from "@snowtam/ui";
import { useMemo } from "react";
import { DefaultTheme } from "styled-components";
import { RootState } from "../redux";

type ThemesType = "main" | "dark" | "night";

const useThemeSetting = () => {
  const themeSelected: string | undefined = useSelector(
    (state: RootState) => state.general.settings?.theme
  );

  const theme: DefaultTheme = useMemo(() => {
    if (!themeSelected || themeSelected === "auto") {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        return themeData.dark;
      } else {
        return themeData.main;
      }
    } else if (themeSelected) {
      const key = Object.keys(themeData)
        .filter((key) => key === themeSelected)
        .pop() as ThemesType;
      if (key) return themeData[key];
    }
    return themeData.main;
  }, [themeSelected]);

  return theme;
};

export default useThemeSetting;
