import { DropDownItem, IconExpand } from "@snowtam/ui";
import { FC, ReactElement, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import SelectPopup from "./SelectPopup";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  font-size: 1rem !important;

  @media (max-width: 767px) {
    min-width: 170px;
    max-width: 170px;
  }
  @media (max-width: 374px) {
    min-width: 160px;
    max-width: 160px;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const SelectorContainer = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.font1};
  padding: 0 0 0 24px;
  display: flex;
  cursor: pointer;
  align-items: center;
  @media (max-width: 767px) {
    font-size: 1rem;
    padding: 0;
  }
  @media (max-width: 374px) {
    font-size: 0.875rem;
  }
`;

const SelectIcon = styled.button<{ active?: boolean }>`
  border: none;
  background: rgba(0, 0, 0, 0);
  outline: none;
  width: 48px;
  height: 48px;
  padding: 0;
  margin: 0 0 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ active }) => (active ? "rotate(180deg)" : "")};
  transition: transform 0.2s ease;
  pointer-events: none;
  @media (max-width: 767px) {
    //margin-left: 8px;
  }
  @media (max-width: 374px) {
    margin-left: 4px;
  }
`;

const SelectorDropdown: FC<{
  endAdornment?: ReactElement;
  items: DropDownItem[];
  value?: DropDownItem;
  onChoose: (val: DropDownItem) => void;
  renderValue: (item?: DropDownItem) => string | JSX.Element;
  _id: string;
  className?: string;
}> = ({
  items,
  endAdornment,
  value,
  onChoose,
  renderValue,
  _id,
  className,
}) => {
  const [select, setSelect] = useState<boolean>(false);
  const toggleRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const handleSelect = (item: DropDownItem) => {
    onChoose(item);
    setSelect(false);
  };

  return (
    <Wrapper id={_id} className={className}>
      <Container>
        <DropDownContainer>
          <SelectorContainer
            ref={toggleRef}
            id={_id}
            onClick={() => {
              if (items.length > 1 || !value) setSelect((prev) => !prev);
            }}
          >
            {renderValue(value)}
            {items.length > 1 || !value ? (
              <SelectIcon
                active={select}
                onClick={() => setSelect((prev) => !prev)}
              >
                <IconExpand color={theme.colors.active} />
              </SelectIcon>
            ) : null}
          </SelectorContainer>
          {select ? (
            <SelectPopup
              onClose={() => setSelect(false)}
              togglerRef={toggleRef}
              items={items}
              onSelect={(item) => {
                handleSelect(item);
                setSelect(false);
              }}
              selected={value?.id}
            />
          ) : null}
        </DropDownContainer>
        {endAdornment}
      </Container>
    </Wrapper>
  );
};

export default SelectorDropdown;
