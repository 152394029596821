import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setApronStatusRawAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<string>
) => {
  state.apron_status_raw = action.payload;
  state.pause = true;
};

export default setApronStatusRawAction;
