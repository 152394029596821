import { DialogYesNo } from "@snowtam/ui";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import FrictionEquipment from "../../../api/friction-equipment";
import { FrictionEquipmentEntity } from "../../../api/friction-equipment.types";
import useMounted from "../../../hooks/useMounted";
import { DataContext } from "../Providers/DataProvider";

type DeleteDialogProps = {
  data: FrictionEquipmentEntity;
  onCancel: () => void;
  onSuccess: () => void;
};

const DeleteItem: FC<DeleteDialogProps> = ({ onCancel, onSuccess, data }) => {
  const { t } = useTranslation("friction");
  const [processing, setProcessing] = useState(false);
  const { airport_code } = useContext(DataContext);
  const mounted = useMounted();

  const handleDelete = () => {
    setProcessing(true);
    return FrictionEquipment.deleteOne(data.id, airport_code)
      .then(() => {
        onSuccess();
      })
      .finally(() => {
        if (mounted.current) setProcessing(false);
      });
  };

  return (
    <DialogYesNo
      title={t`delete_equipment`}
      caption={data.name}
      yesText={t`delete`}
      noText={t`cancel`}
      onClose={onCancel}
      yesDisabled={processing}
      onApply={handleDelete}
    />
  );
};

export default DeleteItem;
