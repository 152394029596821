import { FC } from "react";
import SubTitle from "../../SubTitle";
import Helper from "./Helper";
import styled from "styled-components";
import { Group } from "../../styles";
import { useTranslation } from "react-i18next";
import Values from "./Values";
import DeviceSelector from "./DeviceSelector";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
    position: relative;
  }
`;
const FrictionForm: FC<{ runwayId: number }> = ({ runwayId }) => {
  const { t } = useTranslation("data_form");

  return (
    <Group>
      <SubTitle label={t`adhesion`} endAdornment={<Helper />} />
      <Row>
        <Values runwayId={runwayId} />
        <DeviceSelector runwayId={runwayId} />
      </Row>
    </Group>
  );
};
export default FrictionForm;
