import { FC } from "react";
import { GroupTitle } from "@snowtam/ui";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding-bottom: 24px;
`;

const Empty: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  return (
    <Container>
      <GroupTitle
        label={t`empty_templates`}
        labelStyle={{
          fontSize: "14px",
          color: theme.colors.font3,
          fontWeight: "normal",
        }}
      />
    </Container>
  );
};
export default Empty;
