import { FC, useState } from "react";
import LoginForm from "../../components/LoginForm";
import { DialogYesNo } from "@snowtam/ui";
import styled from "styled-components";

const Text = styled.text`
  text-align: center;
`;
const LoginPage: FC = () => {
  const [warning, setWarning] = useState(true);

  return (
    <>
      {warning ? (
        <DialogYesNo
          title={"Внимание!"}
          onApply={() => setWarning(false)}
          yesText={"OK"}
        >
          <Text>
            Заявки, отправленные после <br />
            15го октября 2022г, не будут приниматся до введения системы в
            эксплуатацию Росавиацией
          </Text>
        </DialogYesNo>
      ) : null}
      <LoginForm />
    </>
  );
};

export default LoginPage;
