import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { RunwaysType } from "../../../../@types/models";
import { appendRunwayToState } from "../utils/runwayDataGenerator";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const appendRunwayAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{
    runway: RunwaysType;
    time: number;
  }>
) => {
  state = appendRunwayToState(
    state,
    action.payload.runway,
    action.payload.time
  );
  return state;
};

export default appendRunwayAction;
