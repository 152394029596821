import { FC, PropsWithChildren } from "react";
import { UserRoles } from "../../Router";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

interface CanAccessProps extends PropsWithChildren<any> {
  roles: UserRoles[];
}

const CanAccess: FC<CanAccessProps> = (props) => {
  const role = useSelector((state: RootState) => state.general.user?.role);

  if (!role) return null;

  if (!props.children) return null;

  return props.roles.includes(role) ? <>{props.children}</> : null;
};

export default CanAccess;
