import { OutlinedInput } from "@snowtam/ui";
import { FC, FormEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FrictionEquipment from "../../../api/friction-equipment";
import { createFrictionEquipmentDto } from "../../../api/friction-equipment.types";
import AddModal from "../../../components/AddModal";
import useMounted from "../../../hooks/useMounted";

const Form = styled.form`
  display: flex;
  width: 100%;
  padding: 24px 0;
  flex-direction: column;
`;

const CreateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: ${(props) => props.theme.colors.active};
  border-radius: 4px;
  padding: 16px;
  width: 276px;
  margin: 16px auto 0 auto;
  color: ${(props) => props.theme.colors.font_active};
`;

const Error = styled.span`
  text-align: center;
  color: ${(props) => props.theme.colors.error};
  padding: 12px 0;
`;

type AddApronDialogProps = {
  airport_code: string;
  onClose: () => void;
  onSuccess: () => void;
};

const AddEquipmentDialog: FC<AddApronDialogProps> = ({
  onClose,
  onSuccess,
  airport_code,
}) => {
  const [processing, setProcessing] = useState(false);
  const [name, setName] = useState<string>("");
  const [minimal_value, setMinimalValue] = useState<string>();
  const [rwycc, setRwycc] = useState<string>();
  const [error, setError] = useState<string>();
  const [errorFields] = useState<string[]>([]);
  const mounted = useMounted();

  const { t } = useTranslation("friction");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!name) {
      setError(t`errors.fill_all`);
      return;
    }
    const data: createFrictionEquipmentDto = {
      name,
    };
    if (rwycc && !isNaN(parseInt(rwycc, 10))) {
      data.rwycc = parseInt(rwycc, 10);
    }
    if (minimal_value && !isNaN(parseInt(minimal_value, 10))) {
      data.minimal_value = parseInt(minimal_value, 10);
    }
    setProcessing(true);
    FrictionEquipment.createOne(airport_code, data)
      .then((res) => {
        if (res?.success) {
          onSuccess();
        } else {
          setError("Undefined server error!");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          setError(err?.response?.data?.message);
        } else {
          setError("Undefined server error!");
        }
      })
      .finally(() => {
        if (mounted.current) setProcessing(false);
      });
  };

  return (
    <AddModal title={t`new_equipment`} onClose={onClose}>
      <Form onSubmit={handleSubmit}>
        <OutlinedInput
          name={"name"}
          label={t`name`}
          value={name}
          onChange={(e) =>
            setName(
              e.target.value.toUpperCase().replace(/[^A-Z\d-?:().,'=/+]/gi, "")
            )
          }
          focusOnEnter
          error={(!!error && name.length === 0) || errorFields.includes("name")}
        />
        <OutlinedInput
          name={"friction_minimal_value"}
          label={t`friction_minimal_value`}
          value={minimal_value}
          inputProps={{
            maxLength: 3,
            inputMode: "numeric",
          }}
          onChange={(e) =>
            setMinimalValue(e.target.value.replace(/[^0-9]/gi, ""))
          }
          error={(!!error && name.length === 0) || errorFields.includes("name")}
        />
        <OutlinedInput
          name={"friction_downgrade_rwycc"}
          label={t`friction_downgrade_rwycc`}
          inputProps={{
            maxLength: 1,
            inputMode: "numeric",
          }}
          value={rwycc}
          onChange={(e) => setRwycc(e.target.value.replace(/[^0-6]/gi, ""))}
          error={(!!error && name.length === 0) || errorFields.includes("name")}
        />
        {error ? <Error>{error}</Error> : null}
        <CreateButton
          name="save"
          disabled={processing}
        >{t`create`}</CreateButton>
      </Form>
    </AddModal>
  );
};

export default AddEquipmentDialog;
