const history = {
  history: "История заявок",
  date_time: "Дата/Время",
  user: "Оператор",
  snowtam_number: "Номер SNOWTAM",
  publish_date: "Дата публикации",
  status: "Статус",
  show: "Просмотр",
};

export default history;
