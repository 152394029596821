import { FC, memo } from "react";
import Preload from "./Preload";
import Title from "./Title";
import useActiveRunwayMonitor from "./hooks/useActiveRunwayMonitor";
import Form from "./Form";
import Initializer from "./Initializer";

const DataForm: FC = () => {
  const { runwayId, setRunwayId } = useActiveRunwayMonitor();

  return (
    <Preload>
      <Initializer>
        <Title
          onSetRunway={(id) => {
            window.scroll({ top: 0, behavior: "smooth" });
            setRunwayId(id);
          }}
          runway_id={runwayId}
        />
        <Form runwayId={runwayId} />
      </Initializer>
    </Preload>
  );
};

export default memo(DataForm);
