import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { DropDownItem } from "@snowtam/ui";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setRWYCCAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{
    runwayId: number;
    third: 1 | 2 | 3;
    value?: DropDownItem;
  }>
) => {
  const index = state.runways.findIndex(
    (item) => item.runwayId === action.payload.runwayId
  );
  state.runways[index].thirds[action.payload.third].manualRWYCC =
    action.payload.value;
  state.pause = true;
};

export default setRWYCCAction;
