import { DropDown, OutlinedInput } from "@snowtam/ui";
import { FC, FormEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Users from "../../../api/users";
import AddModal from "../../../components/AddModal";
import { UserRoles } from "../../../Router";

const Form = styled.form`
  display: flex;
  width: 100%;
  padding: 24px 0;
  flex-direction: column;
`;

const CreateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: ${(props) => props.theme.colors.active};
  border-radius: 4px;
  padding: 16px;
  width: 276px;
  margin: 16px auto 0 auto;
  color: ${(props) => props.theme.colors.font_active};
`;

const Error = styled.span`
  text-align: center;
  color: ${(props) => props.theme.colors.error};
  padding: 12px 0;
`;

interface StatusProps {
  title: string;
  onClose: () => void;
  onSuccess: () => void;
  data: any;
}

const Status: FC<StatusProps> = ({ title, onClose, onSuccess, data }) => {
  const [status, setStatus] = useState<any>();
  const [error, setError] = useState<string>();
  const { t } = useTranslation("common");

  const statusItems = useMemo(
    () => [
      {
        id: 1,
        value: t`accepted`,
      },
      {
        id: 0,
        value: t`suspended`,
      },
    ],
    [t]
  );

  useEffect(() => {
    if (!status) {
      setStatus(statusItems.find((i) => i.id === data.status));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (typeof status?.id === "undefined") {
      setError(t`errors.fill_all`);
      return;
    }
    Users.updateOne({
      id: data.id,
      status: status.id,
      role: UserRoles.Operator,
    }).then(() => onSuccess());
  };

  return (
    <AddModal title={title} onClose={onClose}>
      <Form onSubmit={handleSubmit}>
        <OutlinedInput
          name={"name"}
          label={t`user`}
          value={data?.name || ""}
          inputProps={{ readOnly: true }}
        />
        <DropDown
          name={"status"}
          label={t`status`}
          items={statusItems}
          value={status}
          notChosenText={t`not_chosen`}
          onChoose={(val: any) => setStatus(val)}
        />
        {error ? <Error>{error}</Error> : null}
        <CreateButton name="save">{t`change`}</CreateButton>
      </Form>
    </AddModal>
  );
};

export default Status;
