import React from "react";
import {GlobalStyle} from "@snowtam/ui";
import {Provider} from "react-redux";
import {store} from "./redux";
import Router from "./Router";
import {ThemeProvider} from "styled-components";
import useThemeSetting from "./hooks/useTheme";
import TitleProvider from "./providers/TitleProvider";
import {HelmetProvider} from "react-helmet-async";

const WithReduxApp = () => {
  const theme = useThemeSetting();
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <TitleProvider/>
        <GlobalStyle/>
        <Router/>
      </ThemeProvider>
    </HelmetProvider>
  );
};
const App = () => {
  return (
    <Provider store={store}>
      <WithReduxApp/>
    </Provider>
  );
};

export default App;
