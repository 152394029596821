import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setLowTemperatureAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<boolean>
) => {
  state.low_temperature = action.payload;
};

export default setLowTemperatureAction;
