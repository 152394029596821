import { FC, ReactElement } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const Label = styled.h4`
  display: flex;
  flex: 1;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};
  padding: 16px 0;
  margin: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};
  @media (max-width: 767px) {
    font-size: 1rem;
    padding: 12px 0;
  }
`;
const SubTitle: FC<{ label: string; endAdornment?: ReactElement }> = ({
  label,
  endAdornment,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      {endAdornment}
    </Container>
  );
};

export default SubTitle;
