const settings = {
  profile: "Профиль",
  personal_data: "Персональные данные ",
  language: "Выбор языка",
  language_caption: "На этом языке будут отображаться данные программы",
  language_input: "Язык программы",
  theme: "Цветовая тема",
  theme_auto: "Автоматически выбирать тему",
  theme_auto_mobile: "Автоматически",
  theme_caption: "Выбор цветовой темы программы",
  font: "Размер текста",
  font_caption: "Выберите удобный размер шрифтов",
  font_input: "Размер шрифтов",
  new_form: "Обновленная форма",
  new_form_caption: "Использовать обновленную форму ввода первичных данных",
  new_form_checkbox: "Использовать",
  about: "О программе",
  about_caption: "Информация о программе",
  about_description:
    "Программа для предоставления сообщений о данных по состоянию взлетно-посадочных полос аэродромов",
  fonts: {
    "100": "Стандартный",
    "110": "Увеличенный",
    "120": "Большой",
    "130": "Очень большой",
  },
  themes: {
    main: "Светлая",
    dark: "Темная",
    night: "Ночь",
  },
};

export default settings;
