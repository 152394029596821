import { FC } from "react";
import { DialogYesNo } from "@snowtam/ui";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useTranslation } from "react-i18next";
import { initializeForm } from "../../../../redux/snowtamform/snowtamformv2Reducer";
import { RootState } from "../../../../redux";
import useServerTime from "../../../../hooks/useServerTime";

const Modal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const store = useStore();
  const time = useServerTime();
  const user_id = useSelector((state: RootState) => state.general.user?.id);
  const { t } = useTranslation(["data_form", "common"]);
  const dispatch = useDispatch();

  return (
    <DialogYesNo
      title={t`common:clear_form`}
      onApply={() => {
        if (time)
          dispatch(
            initializeForm({
              data: store.getState().general,
              time,
              user_id,
            })
          );
      }}
      caption={t`common:clear_form_caption`}
      yesText={t`common:yes`}
      noText={t`common:no`}
      onClose={onClose}
    />
  );
};

export default Modal;
