import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "../types/status";

const initialState: AppStateType = {
  canAdd: true,
};

export type AppStateType = {
  canAdd?: boolean;
  last_id?: number;
  last_status?: RequestStatus;
};
export const generalSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCanAdd: (
      state,
      action: PayloadAction<{
        canAdd: boolean;
        last_id?: number;
        last_status?: RequestStatus;
      }>
    ) => {
      state.canAdd = action.payload.canAdd;
      state.last_status = action.payload.last_status;
      if (action.payload.last_id) {
        state.last_id = action.payload.last_id;
      }
    },
  },
});

export const { setCanAdd } = generalSlice.actions;

export default generalSlice.reducer;
