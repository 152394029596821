import { FC } from "react";
import { TableRow, TableColumn } from "@snowtam/ui";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const EmptyText = styled.span`
  color: ${({ theme }) => theme.colors.font2};
  font-size: 0.875rem;
`;
const Empty: FC = () => {
  const { t } = useTranslation("common");
  return (
    <TableRow>
      <TableColumn colSpan={6}>
        <EmptyText>{t`empty_users`}</EmptyText>
      </TableColumn>
    </TableRow>
  );
};
export default Empty;
