import API from "./API";

type LoginInput = {
  login: string;
  password: string;
  type: string;
  force?: boolean;
};

class Auth {
  private api: typeof API;
  constructor() {
    this.api = API;
  }

  async login(data: LoginInput) {
    return this.api
      .post("/api/auth/login", {
        ...data,
      })
      .then((res: any) => res.data);
  }

  async logout() {
    return this.api.head("/api/auth/logout").then((res: any) => res.data);
  }
}

export default new Auth();
