import { FC, useState } from "react";
import styled from "styled-components";
import { IconClose, OutlinedInput, OutlinedTextarea } from "@snowtam/ui";
import { TemplateModel } from "../../../@types/models";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import Templates from "../../api/templates";

const BackDrop = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.backdrop};
  z-index: 2000;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.popup_background};
  box-shadow: ${({ theme }) => theme.shadows.popup};

  border-radius: 8px;
  margin: auto;
  width: 100%;
  padding: 24px 48px 48px 48px;
  position: relative;
  max-width: 690px;
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 12px 16px 16px 16px;
  }
`;
const Title = styled.h2`
  margin: 0 0 46px;
  padding: 0;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};
  @media (max-width: 767px) {
    font-size: 1.125rem;
    margin-bottom: 22px;
  }
`;

const CloseButton = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  border-radius: 8px;

  svg {
    color: ${({ theme }) => theme.colors.font2};
  }

  @media (max-width: 767px) {
    width: 50px;
    height: 50px;
  }
`;

const SaveButton = styled.button`
  border: none;
  padding: 16px 89px;
  background: ${(props) => props.theme.colors.active};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.font_active};
  outline: none;
  margin: 24px auto 0 auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Error = styled.p`
  margin: 0;
  padding: 8px 0;
  color: ${(props) => props.theme.colors.error};
  text-align: center;
`;

interface TemplateModalProps {
  onClose: () => void;
  data?: TemplateModel;
  onApply: () => void;
}

const TemplateModal: FC<TemplateModalProps> = ({ onClose, onApply, data }) => {
  const [name, setName] = useState<string>(data?.name || "");
  const [text, setText] = useState<string>(data?.text || "");
  const [error, setError] = useState<string>("");
  const [errorFields, setErrorFields] = useState<string[]>([]);

  const { t } = useTranslation(["templates", "common"]);

  const rootNode = document.getElementById("root") as HTMLDivElement;

  const handleAdd = () => {
    if (name.length > 0 && text.length > 0) {
      Templates.createOne({
        name,
        text,
      })
        .then(onApply)
        .catch(catchError);
    } else {
      setError(t`common:errors.fill_all`);
    }
  };

  const handleUpdate = () => {
    if (name.length > 0 && text.length > 0 && data) {
      Templates.updateOne({
        name,
        text,
        id: data.id,
      })
        .then(onApply)
        .catch(catchError);
    } else {
      setError(t`common:errors.fill_all`);
    }
  };

  const catchError = (reason: any) => {
    if (reason.response.data.message) {
      setError(reason.response.data.message);
    }
    if (reason.response.data.error_fields) {
      setErrorFields(reason.response.data.error_fields);
    }
  };
  const handleSubmit = () => {
    if (name.length > 0 && text.length > 0) {
      if (!data) {
        handleAdd();
      } else {
        handleUpdate();
      }
    } else {
      setError(t`common:errors.fill_all`);
    }
  };
  return createPortal(
    <BackDrop>
      <Modal>
        <Title>
          {data ? t`templates:edit_template` : t`templates:new_template`}
        </Title>
        <CloseButton onClick={onClose} type="button">
          <IconClose />
        </CloseButton>
        <OutlinedInput
          name={"template_name"}
          label={t`name`}
          inputProps={{ maxLength: 100 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          focusOnEnter
          error={(!!error && name.length === 0) || errorFields.includes("name")}
        />
        <OutlinedTextarea
          name={"template_text"}
          label={t`text`}
          maxLength={1200}
          style={{ marginTop: "24px" }}
          value={text}
          onChange={(e) => {
            const value = e.target.value.replace(/[~@!#$%^&*{}\]['`"]/g, "");
            setText(value);
          }}
          totalCharactersText={t`common:total_characters`}
          error={(!!error && text.length === 0) || errorFields.includes("text")}
        />
        {error ? <Error>{error}</Error> : null}
        <SaveButton type="button" onClick={handleSubmit} name="save">
          {t`common:save`}
        </SaveButton>
      </Modal>
    </BackDrop>,
    rootNode
  );
};

export default TemplateModal;
