import { FC, Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useStore } from "react-redux";
import { RootState } from "../../redux";
import { useHistory } from "react-router-dom";
import { setInitialState } from "../../redux/snowtamformReducer";
import ExistDialog from "./ExistDialog";
import { DialogYesNo } from "@snowtam/ui";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import useDataValidate from "./hooks/useDataValidate";
import Requests from "../../api/requests";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: ${(props) => props.theme.colors.active};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.font_active};
  margin: 0 auto;
  padding: 16px 88px;
  font-weight: bold;
`;

const SendDialog = styled(DialogYesNo)`
  //max-width: max-content;
  //width: 100%;
  padding: 0 40px 24px 40px;
`;

const SnowTamMessageWrapper = styled.div`
  width: 100%;
`;
const SnowTamMessage = styled.div`
  padding-bottom: 16px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SaveButton: FC<{ isUpdate?: boolean }> = ({ isUpdate }) => {
  const store = useStore();
  const history = useHistory();
  const dispatch = useDispatch();
  const [exist, setExist] = useState<{ show: boolean; data?: any }>({
    show: false,
  });
  const [errorDialog, setErrorDialog] = useState<boolean>(false);
  const [approve, setApprove] = useState(false);
  const [error, setError] = useState<{ error: boolean; message?: string }>({
    error: false,
  });

  const [message, setMessage] = useState<string[] | undefined>();

  const validate = useDataValidate();

  useEffect(() => {
    if (approve) {
      const state: RootState = store.getState();
      const data: any = { ...state.snowtamForm };
      Requests.generateMessage(data).then((data) => {
        setMessage(data.message);
      });
    }
  }, [approve, store]);

  const handleApprove = () => {
    const validation = validate();
    if (validation.error) {
      setError(validation);
      return;
    }
    setApprove(true);
  };
  const handleSave = () => {
    const state: RootState = store.getState();
    const data: any = { ...state.snowtamForm }; // TODO Remove any type
    data.airport_id = state.general.user?.airport.id;
    delete data.timeStamp;
    delete data.pause;
    fetch(`/api/requests`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        auth: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        if (!res.ok) {
          return;
        }
        return res.json();
      })
      .then((json) => {
        if (json?.exist) {
          setExist({ show: true, data: json });
          return;
        }
        if (json.success) {
          history.push("/menu");
          dispatch(setInitialState());
        }
      })
      .catch(() => {
        setErrorDialog(true);
      });
  };
  const { t } = useTranslation(["data_form", "common"]);
  return (
    <>
      <Button
        id={"send_form"}
        name={"send_form"}
        type="button"
        onClick={handleApprove}
      >
        {t`data_form:send`}
      </Button>
      {approve ? (
        <SendDialog
          title={t`data_form:send_request`}
          onApply={() => {
            handleSave();
          }}
          yesText={t`common:yes`}
          noText={t`common:cancel`}
          onClose={() => setApprove(false)}
        >
          <SnowTamMessageWrapper>
            <SimpleBar autoHide={false}>
              <SnowTamMessage>
                {message
                  ? message.map((item: string, index: number) => (
                      <Fragment key={index}>
                        <span style={{ whiteSpace: "nowrap" }}>{item}</span>
                        <br />
                      </Fragment>
                    ))
                  : null}
              </SnowTamMessage>
            </SimpleBar>
          </SnowTamMessageWrapper>
        </SendDialog>
      ) : null}
      {exist.show ? <ExistDialog data={exist.data} /> : null}
      {errorDialog ? (
        <>
          <DialogYesNo
            title={t`common:errors.warning`}
            onApply={() => setErrorDialog(false)}
            yesText={"OK"}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                lineHeight: "24px",
                marginBottom: "0",
              }}
            >
              {t`common:errors.connection_lost`} <br />
              {t`common:errors.repeat_later`}
            </p>
          </DialogYesNo>
        </>
      ) : null}
      {error.error ? (
        <>
          <DialogYesNo
            title={t`common:errors.warning`}
            onApply={() => setError({ error: false })}
            yesText={"OK"}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                lineHeight: "24px",
                marginBottom: "0",
              }}
            >
              {error.message}
            </p>
          </DialogYesNo>
        </>
      ) : null}
    </>
  );
};
export default SaveButton;
