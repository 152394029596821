import { FC } from "react";
import { DialogYesNo } from "@snowtam/ui";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { UTC_DATETIME_FORMAT } from "../../settings/app.constants";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const ExistDialog: FC<{ data: { exist: boolean; exist_date?: string } }> = ({
  data,
}) => {
  const history = useHistory();
  const { t } = useTranslation("common");
  return (
    <DialogYesNo
      title={t`errors.warning`}
      onApply={() => history.push("/menu")}
      onClose={() => history.push("/menu")}
      yesText={"OK"}
    >
      <span style={{ textAlign: "center", fontSize: "18px" }}>
        {t`request_exist1`}
        <br /> {t`request_exist2`}
        <br />
        {data.exist_date
          ? dayjs.utc(data.exist_date, "X").format(UTC_DATETIME_FORMAT)
          : ""}
      </span>
    </DialogYesNo>
  );
};
export default ExistDialog;
