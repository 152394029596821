const common = {
  server_error: "Ошибка сервера",
  connection_error: "Отсутствует соединение с сервером",
  exit: "Выход",
  back: "Назад",
  save: "Сохранить",
  total_characters: "Введено символов",
  yes: "Да",
  no: "Нет",
  continue: "Продолжить",
  cancel: "Отмена",
  create: "Добавить",
  delete: "Удалить",
  status: "Статус",
  time: "Время UTC",
  meter: "м",
  millimeter: "мм",
  not_chosen: "Не выбрано",
  change_password: "Смена пароля",
  change: "Изменить",
  password: "Пароль",
  re_password: "Повторите пароль",
  user: "Фамилия Имя Отчество",
  login: "Псевдоним (логин)",
  accepted: "Допущен",
  suspended: "Отстранен",
  operator: "Оператор",
  status_changed: "Дата и время изменения статуса",
  change_status_action: "Смена статуса",
  change_password_action: "Изменить пароль",
  change_status_title: "Изменить статус",
  change_password_title: "Изменить пароль",
  new_operator_title: "Новый оператор",
  delete_operator_title: "Удалить пользователя",
  empty_templates: "У вас нет шаблонов текстов",
  add_new_template: "Добавить новый шаблон",
  delete_template: "Удалить шаблон?",
  request_exist1: "С вашего аэродрома уже отправлена заявка",
  request_exist2: "на издание SNOWTAM от",
  clear_form: "Очистить форму?",
  clear_form_caption: "Вы уверены что хотите очистить форму ввода данных?",
  empty_template: "Пустой шаблон!",
  template_saved: "Шаблон сохранен!",
  print: "Отправить на печать",
  phone: "Телефон",
  print_title_users: "Список операторов",
  empty_users: "Операторы не найдены",
  error: "Ошибка",
  pages: "Страницы",
  fatal_error_caption:
    "При выполнении операции произошла ошибка, попробуйте обновить страницу позднее",
  reload: "Обновить",
  exit_label: "Вы уверены что хотите выйти?",
  exit_title: "Выход",
  title_operator:'SNOWTAM - Оператор',
  title_manager:'SNOWTAM - Аэропорт',
  title_common:'SNOWTAM',
  errors: {
    rwys_empty: "ВПП отсутствуют",
    fill_all: "Заполните все поля",
    passwords_match: "Пароли не совпадают",
    warning: "Внимание",
    connection_lost: "Отсутствует соединение с сервером!",
    repeat_later: "Повторите попытку позднее.",
    server_error: "Ошибка сервера",
    paused_form: "В форме ввода есть заполненные данные.",
    are_you_sure: "Вы уверенны что хотите продолжить?",
    suspended: "Вы отстранены от работы!",
    cant_decline: "Невозможно отменить заявку",
    request_inprogress: "Заявка находится в работе.",
  },
};

export default common;
