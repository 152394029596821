import { FC } from "react";
import { LabeledCheckbox } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setTreated } from "../../../../redux/snowtamformReducer";

const TreatedCheck: FC<{ title: string; name: string; runwayId: number }> = ({
  title,
  name,
  runwayId,
}) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamForm.treated.findIndex(
      (item) => item.id === runwayId
    );
    return state.snowtamForm.treated[index];
  });
  const dispatch = useDispatch();
  return (
    <LabeledCheckbox
      _id={"chemically_treated"}
      title={title}
      caption={name}
      value={value?.value}
      onChange={(val) => dispatch(setTreated({ runwayId, value: val }))}
    />
  );
};
export default TreatedCheck;
