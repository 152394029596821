import { FC } from "react";
import { Body, BoldSpan, Container, TextBlock, Title } from "./labelStyles";

import { useTranslation } from "react-i18next";
import { IconRoundDecline } from "@snowtam/ui";
import { useTheme } from "styled-components";

type CommentProps = {
  comment: string;
};

const Comment: FC<CommentProps> = ({ comment }) => {
  const { t } = useTranslation("requests");
  const theme = useTheme();
  return (
    <Container>
      <Title>
        <IconRoundDecline color={theme.colors.active} />
        {t`request_declined`}
      </Title>
      <Body>
        <TextBlock>{t`comment`}</TextBlock>
        <TextBlock>
          <BoldSpan>{comment}</BoldSpan>
        </TextBlock>
      </Body>
    </Container>
  );
};

export default Comment;
