import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { OutlinedInput, Chip } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { setTaxiwayStatusRaw } from "../../../../redux/snowtamform/snowtamformv2Reducer";
import { RootState } from "../../../../redux";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.p`
  margin: -8px 0 0;
  padding: 0 0 8px 0;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};
  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px -4px 12px -4px;
`;
const RawInput: FC = () => {
  const value = useSelector(
    (state: RootState) => state.snowtamFormV2.taxiway_status_raw || ""
  );
  const { t } = useTranslation(["data_form", "helper"]);

  const dispatch = useDispatch();
  return (
    <Container>
      <Label>{t`helper:twy_status.caption`}</Label>
      <ChipContainer>
        {value
          .split(",")
          .filter((v) => v)
          .map((item, index) => (
            <Chip text={item} key={index} />
          ))}
      </ChipContainer>
      <OutlinedInput
        name={"twy_poor_raw"}
        label={t`data_form:status_twy`}
        value={value}
        onChange={(e) =>
          dispatch(
            setTaxiwayStatusRaw(e.target.value.replace(/[^A-Za-z0-9 ,]/, ""))
          )
        }
      />
    </Container>
  );
};
export default RawInput;
