import { FC } from "react";
import { useLocation } from "react-router-dom";
import NewForm from "./NewForm";
import ExistingForm from "./ExistingForm";
import FromExistingForm from "./FromExistingForm";

function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

const DataForm: FC = () => {
  const query = useQuery();
  const request = query.get("request");
  const from = query.get("from");
  if (request) {
    return <ExistingForm request_id={request} />;
  }
  if (from) {
    return <FromExistingForm request_id={from} />;
  }
  return <NewForm />;
};

export default DataForm;
