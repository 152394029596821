import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setSandAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{ runwayId: number; value: boolean }>
) => {
  const index = state.sand.findIndex(
    (obj) => obj.id === action.payload.runwayId
  );
  state.sand[index].value = action.payload.value;
  state.pause = true;
};

export default setSandAction;
