const history = {
  history: "History",
  date_time: "Date/Time",
  user: "Operator",
  snowtam_number: "SNOWTAM №",
  publish_date: "Publish Date",
  status: "Status",
  show: "View",
};

export default history;
