const menu = {
  menu: "Menu",
  exit: "Exit",
  information: "Information",
  create: "Create",
  create_short: "Create",
  history: "History",
  history_short: "History",
  templates: "Templates",
  templates_short: "Templates",
  created: "Requested created",
  cancel: "Decline request",
  language: "Language",
  admin_info: "Information",
  admin_history: "Requests",
  admin_users: "Operators",
  settings: "Settings",
};

export default menu;
