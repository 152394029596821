import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  SnowbanksTaxiwayFormType,
  SnowtamFormStateType,
} from "../../@types/form";
import { DropDownItem } from "@snowtam/ui";
import { GeneralStateType } from "./generalReducer";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import settings from "../settings/form";
import { DATE_FORMAT, TIME_FORMAT } from "../settings/app.constants";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const initialState: SnowtamFormStateType = {
  snowbanks_taxiway: [],
  snowbanks_near_runway: [],
  taxiway_status: [],
  apron_status: [],
  comment: "",
  adhesion: "",
  snowbank_runway_position: [],
  treated: [],
  sand: [],
  snowstorm: [],
  reduced: [],
  runways: [],
  version: 1,
};

export const snowtamformSlice = createSlice({
  name: "snowtamform",
  initialState,
  reducers: {
    setForm: (state, action: PayloadAction<SnowtamFormStateType>) => {
      return {
        ...initialState,
        ...action.payload,
        version: 1,
        pause: true,
      };
    },
    removeSnowbanksTaxiway: (state, action: PayloadAction<number>) => {
      state.snowbanks_taxiway.splice(action.payload, 1);
      state.pause = true;
    },
    setSnowbanksTwyRaw: (state, action: PayloadAction<string>) => {
      state.snowbanks_twy_raw = action.payload;
      state.pause = true;
    },
    setApronStatusRaw: (state, action: PayloadAction<string>) => {
      state.apron_status_raw = action.payload;
      state.pause = true;
    },
    setTaxiwayStatusRaw: (state, action: PayloadAction<string>) => {
      state.taxiway_status_raw = action.payload;
      state.pause = true;
    },
    addSnowbanksTaxiway: (
      state,
      action: PayloadAction<SnowbanksTaxiwayFormType>
    ) => {
      if (action.payload.value === "ALL") {
        state.snowbanks_taxiway = [];
      }
      state.snowbanks_taxiway.push(action.payload);
      state.pause = true;
    },
    updateSnowbanksTaxiway: (
      state,
      action: PayloadAction<{ data: SnowbanksTaxiwayFormType; index: number }>
    ) => {
      if (action.payload.data.value === "ALL") {
        state.snowbanks_taxiway = [action.payload.data];
        return;
      }
      state.snowbanks_taxiway.splice(
        state.snowbanks_taxiway.findIndex((item) => item.value === "ALL"),
        1
      );
      state.snowbanks_taxiway[action.payload.index] = action.payload.data;
      state.pause = true;
    },
    setSnowbanksNearRunway: (
      state,
      action: PayloadAction<{ runwayId: number; value: boolean }>
    ) => {
      const index = state.snowbanks_near_runway.findIndex(
        (obj) => obj.id === action.payload.runwayId
      );
      state.snowbanks_near_runway[index].value = action.payload.value;
      state.pause = true;
    },
    removeTaxiwayStatus: (state, action: PayloadAction<number>) => {
      state.taxiway_status.splice(action.payload, 1);
      state.pause = true;
    },
    addTaxiwayStatus: (
      state,
      action: PayloadAction<SnowbanksTaxiwayFormType>
    ) => {
      if (action.payload.value === "ALL") {
        state.taxiway_status = [];
      }
      state.taxiway_status.push(action.payload);
      state.pause = true;
    },
    updateTaxiwayStatus: (
      state,
      action: PayloadAction<{ data: SnowbanksTaxiwayFormType; index: number }>
    ) => {
      if (action.payload.data.value === "ALL") {
        state.taxiway_status = [action.payload.data];
        return;
      }
      state.taxiway_status.splice(
        state.taxiway_status.findIndex((item) => item.value === "ALL"),
        1
      );
      state.taxiway_status[action.payload.index] = action.payload.data;
    },
    removeApronStatus: (state, action: PayloadAction<number>) => {
      state.apron_status.splice(action.payload, 1);
      state.pause = true;
    },
    addApronStatus: (
      state,
      action: PayloadAction<SnowbanksTaxiwayFormType>
    ) => {
      if (action.payload.value === "ALL") {
        state.apron_status = [action.payload];
        return;
      }
      state.apron_status.push(action.payload);
      state.pause = true;
    },
    updateApronStatus: (
      state,
      action: PayloadAction<{ data: SnowbanksTaxiwayFormType; index: number }>
    ) => {
      if (action.payload.data.value === "ALL") {
        state.apron_status = [action.payload.data];
        return;
      }
      state.apron_status.splice(
        state.apron_status.findIndex((item) => item.value === "ALL"),
        1
      );
      state.apron_status[action.payload.index] = action.payload.data;
      state.pause = true;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
      state.pause = true;
    },
    setAdhesion: (state, action: PayloadAction<string>) => {
      state.adhesion = action.payload;
      state.pause = true;
    },
    setSnowbankRunwayPosition: (
      state,
      action: PayloadAction<{
        runwayId: number;
        value: DropDownItem;
      }>
    ) => {
      const index = state.snowbank_runway_position.findIndex(
        (item) => item.runwayId === action.payload.runwayId
      );
      state.snowbank_runway_position[index].position = action.payload.value;
      state.pause = true;
    },
    setSnowbankRunwayLength: (
      state,
      action: PayloadAction<{ runwayId: number; value: number }>
    ) => {
      const index = state.snowbank_runway_position.findIndex(
        (item) => item.runwayId === action.payload.runwayId
      );
      state.snowbank_runway_position[index].value = action.payload.value;
      state.pause = true;
    },
    setTreated: (
      state,
      action: PayloadAction<{ runwayId: number; value: boolean }>
    ) => {
      const index = state.treated.findIndex(
        (obj) => obj.id === action.payload.runwayId
      );
      state.treated[index].value = action.payload.value;
      state.pause = true;
    },
    setSand: (
      state,
      action: PayloadAction<{ runwayId: number; value: boolean }>
    ) => {
      const index = state.sand.findIndex(
        (obj) => obj.id === action.payload.runwayId
      );
      state.sand[index].value = action.payload.value;
      state.pause = true;
    },
    setSnowstorm: (
      state,
      action: PayloadAction<{ runwayId: number; value: boolean }>
    ) => {
      const index = state.snowstorm.findIndex(
        (obj) => obj.id === action.payload.runwayId
      );
      state.snowstorm[index].value = action.payload.value;
      state.pause = true;
    },
    setReduced: (
      state,
      action: PayloadAction<{ runwayId: number; value: number }>
    ) => {
      const index = state.reduced.findIndex(
        (obj) => obj.id === action.payload.runwayId
      );
      state.reduced[index].value = action.payload.value;
      state.pause = true;
    },
    setThirdWidth: (
      state,
      action: PayloadAction<{
        runwayId: number;
        value: number;
      }>
    ) => {
      const index = state.runways.findIndex(
        (item) => item.runwayId === action.payload.runwayId
      );
      state.runways[index].width = action.payload.value;
      state.pause = true;
    },
    setPollutionDepth: (
      state,
      action: PayloadAction<{
        runwayId: number;
        third: 1 | 2 | 3;
        value: number;
      }>
    ) => {
      const index = state.runways.findIndex(
        (item) => item.runwayId === action.payload.runwayId
      );
      if (state.runways[index]) {
        state.runways[index].thirds[action.payload.third].pollutionDepth =
          action.payload.value;
        state.pause = true;
      }
    },
    setStatusCode: (
      state,
      action: PayloadAction<{
        runwayId: number;
        third: 1 | 2 | 3;
        value: DropDownItem;
      }>
    ) => {
      const index = state.runways.findIndex(
        (item) => item.runwayId === action.payload.runwayId
      );
      state.runways[index].thirds[action.payload.third].status_code =
        action.payload.value;
      state.pause = true;
    },
    setPollution: (
      state,
      action: PayloadAction<{
        runwayId: number;
        third: 1 | 2 | 3;
        value: DropDownItem;
      }>
    ) => {
      const index = state.runways.findIndex(
        (item) => item.runwayId === action.payload.runwayId
      );
      state.runways[index].thirds[action.payload.third].pollution =
        action.payload.value;
      state.pause = true;
    },
    setFrictionFormValue: (
      state,
      action: PayloadAction<{
        runwayId: number;
        third: 1 | 2 | 3;
        value: string;
      }>
    ) => {
      if (typeof state.friction_form === "undefined") {
        state.friction_form = [];
      }
      const item = state.friction_form?.find(
        (item) => item.runwayId === action.payload.runwayId
      );
      if (!item) {
        state.friction_form.push({
          runwayId: action.payload.runwayId,
          first: action.payload.third === 1 ? action.payload.value : "",
          second: action.payload.third === 2 ? action.payload.value : "",
          third: action.payload.third === 3 ? action.payload.value : "",
          device: "",
        });
      } else {
        switch (action.payload.third) {
          case 1:
            item.first = action.payload.value;
            break;
          case 2:
            item.second = action.payload.value;
            break;
          case 3:
            item.third = action.payload.value;
            break;
        }
      }
    },
    setFrictionFormDevice: (
      state,
      action: PayloadAction<{
        runwayId: number;
        value: string;
      }>
    ) => {
      if (typeof state.friction_form === "undefined") {
        state.friction_form = [];
      }
      const item = state.friction_form?.find(
        (item) => item.runwayId === action.payload.runwayId
      );
      if (!item) {
        state.friction_form.push({
          runwayId: action.payload.runwayId,
          first: "",
          second: "",
          third: "",
          device: action.payload.value,
        });
      } else {
        item.device = action.payload.value;
      }
    },
    setRunwayState: (
      state,
      action: PayloadAction<{
        runwayId: number;
        third: 1 | 2 | 3;
        value: DropDownItem;
      }>
    ) => {
      const index = state.runways.findIndex(
        (item) => item.runwayId === action.payload.runwayId
      );
      state.runways[index].thirds[action.payload.third].status =
        action.payload.value;
      state.pause = true;
    },
    setTime: (
      state,
      action: PayloadAction<{ runwayId: number; value: string }>
    ) => {
      const index = state.runways.findIndex(
        (item) => item.runwayId === action.payload.runwayId
      );
      state.runways[index].time = action.payload.value;
      state.runways[index].timestamp = dayjs
        .utc(
          `${state.runways[index].date} ${action.payload.value}`,
          `${DATE_FORMAT} ${TIME_FORMAT}`
        )
        .unix()
        .toString();

      state.pause = true;
    },
    setServerTime: (state, action: PayloadAction<number>) => {
      const serverTime = dayjs.utc(action.payload, "x");
      state.runways.forEach((rwy) => {
        rwy.date = serverTime.format(DATE_FORMAT);
        rwy.time = serverTime.format(TIME_FORMAT);
        rwy.timestamp = serverTime.unix().toString();
      });
    },
    setPause: (state) => {
      state.pause = true;
    },
    setContinue: (state) => {
      state.pause = false;
    },
    setInitialState: () => initialState,
    initializeForm: (
      state,
      action: PayloadAction<{
        data: GeneralStateType;
        time: number;
        user_id: number | undefined;
      }>
    ) => {
      const serverTime = dayjs.utc(action.payload.time, "x");
      let initialData: SnowtamFormStateType = {
        ...initialState,
        runways: [],
        reduced: [],
        snowstorm: [],
        sand: [],
        treated: [],
        snowbanks_near_runway: [],
        snowbank_runway_position: [],
        friction_form: [],
        user_id: action.payload.user_id,
      };

      action.payload.data.user?.airport.runways?.forEach((runway) => {
        initialData.reduced.push({
          id: runway.id,
          runway: runway.name,
          value: runway.length,
        });
        initialData.snowstorm.push({
          id: runway.id,
          runway: runway.name,
          value: false,
        });
        initialData.sand.push({
          id: runway.id,
          runway: runway.name,
          value: false,
        });
        initialData.treated.push({
          id: runway.id,
          runway: runway.name,
          value: false,
        });
        initialData.snowbanks_near_runway.push({
          id: runway.id,
          runway: runway.name,
          value: false,
        });
        initialData.snowbank_runway_position.push({
          position: {
            id: 0,
            value: "",
            data: "",
          },
          value: Math.round(runway.width / 2),
          name: runway.name,
          runwayId: runway.id,
        });
        initialData.runways.push({
          runwayId: runway.id,
          width: runway.width,
          name: runway.name,
          date: serverTime.format(DATE_FORMAT),
          time: serverTime.format(TIME_FORMAT),
          timestamp: serverTime.unix().toString(),
          thirds: {
            1: {
              pollution: settings.pollutionDepthItems.find(
                (item) => item.id === 0
              ),
              pollutionDepth: 0,
              status: {
                id: 1,
                value: "NR",
              },
              status_code: {
                id: 6,
                value: "6",
              },
            },
            2: {
              pollution: settings.pollutionDepthItems.find(
                (item) => item.id === 0
              ),
              pollutionDepth: 0,
              status: {
                id: 1,
                value: "NR",
              },
              status_code: {
                id: 6,
                value: "6",
              },
            },
            3: {
              pollution: settings.pollutionDepthItems.find(
                (item) => item.id === 0
              ),
              pollutionDepth: 0,
              status: {
                id: 1,
                value: "NR",
              },
              status_code: {
                id: 6,
                value: "6",
              },
            },
          },
        });
      });
      return initialData;
    },
  },
});

export const {
  addSnowbanksTaxiway,
  removeSnowbanksTaxiway,
  updateSnowbanksTaxiway,
  setSnowbanksNearRunway,
  addTaxiwayStatus,
  updateTaxiwayStatus,
  removeTaxiwayStatus,
  addApronStatus,
  updateApronStatus,
  removeApronStatus,
  setComment,
  setAdhesion,
  setSnowbankRunwayPosition,
  setSnowbankRunwayLength,
  setTreated,
  setSand,
  setSnowstorm,
  setReduced,
  setThirdWidth,
  setPollutionDepth,
  setStatusCode,
  setPollution,
  setRunwayState,
  setTime,
  setInitialState,
  setPause,
  setContinue,
  initializeForm,
  setSnowbanksTwyRaw,
  setApronStatusRaw,
  setTaxiwayStatusRaw,
  setForm,
  setFrictionFormValue,
  setFrictionFormDevice,
  setServerTime,
} = snowtamformSlice.actions;

export default snowtamformSlice.reducer;
