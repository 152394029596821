import { Draft, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { DATE_FORMAT, TIME_FORMAT } from "../../../settings/app.constants";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";
import parseTimestamp from "../utils/parseTimestamp";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const setTimeAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{ runwayId: number; value: string }>
) => {
  const index = state.runways.findIndex(
    (item) => item.runwayId === action.payload.runwayId
  );
  state.runways[index].time = action.payload.value;

  const timestamp = dayjs.utc(
    `${state.runways[index].date} ${action.payload.value}`,
    `${DATE_FORMAT} ${TIME_FORMAT}`
  );
  state.runways[index].timestamp = parseTimestamp(timestamp).unix().toString();

  state.pause = true;
};

export default setTimeAction;
