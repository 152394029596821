import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useTranslation } from "react-i18next";

dayjs.extend(duration);

const useCountdown = (expire: string) => {
  const { t } = useTranslation("requests");

  const [value, setValue] = useState("");
  const expirationTime = useMemo(() => {
    return dayjs(expire, "X");
  }, [expire]);

  const countAndFormat = useCallback(() => {
    const diff = dayjs.duration(expirationTime.diff(dayjs()));
    setValue(
      diff.format(
        `H ${t`hours_short`}. mm ${t`minutes_short`}. ss ${t`seconds_short`}.`
      )
    );
  }, [t, expirationTime]);

  useEffect(() => {
    countAndFormat();
    const interval = setInterval(countAndFormat, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [countAndFormat]);

  return value;
};

export default useCountdown;
