import { FC, memo } from "react";
import SubTitle from "../../SubTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import styled from "styled-components";
import TaxiwayItem from "./TaxiwayItem";
import { Group } from "../styles";
import { useTranslation } from "react-i18next";
import RawInput from "./RawInput";
import { InfoPopup } from "@snowtam/ui";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const TaxiwayStatus: FC = () => {
  const items = useSelector(
    (state: RootState) => state.snowtamFormV2.taxiway_status
  );
  const taxiways = useSelector(
    (state: RootState) => state.general.user?.airport.taxiways
  );
  const { t } = useTranslation("data_form");

  return (
    <Group>
      <SubTitle
        label={t`status_twy`}
        endAdornment={
          <InfoPopup
            title={t`helper:twy_status.title`}
            text={
              <>
                {t`helper:twy_status.line1`}
                <br />
                {t`helper:twy_status.line2`}
              </>
            }
          />
        }
      />
      <Row>
        {taxiways && taxiways.length > 0 ? (
          <>
            {items && items.length > 0
              ? items.map((item, index) => {
                  return (
                    <TaxiwayItem
                      key={`sbi${item.id}`}
                      item={item}
                      withAll={index === 0 && items.length === 1}
                    />
                  );
                })
              : null}
            <TaxiwayItem withAll={items.length === 0} />
          </>
        ) : (
          <RawInput />
        )}
      </Row>
    </Group>
  );
};

export default memo(TaxiwayStatus);
