import { useEffect, useState } from "react";
import Requests from "../api/requests";

const useCheckRequestExists = () => {
  const [data, setData] = useState<{
    exist: boolean;
    exist_date?: string;
  }>();

  const fetchTime = () => {
    Requests.checkExist().then((res) => setData(res));
  };

  useEffect(() => {
    fetchTime();
  }, []);

  return data;
};

export default useCheckRequestExists;
