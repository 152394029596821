import { FC } from "react";
import { CounterInput } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setReduced } from "../../../../redux/snowtamform/snowtamformv2Reducer";
import { css } from "styled-components";
import { useTranslation } from "react-i18next";

const ReducedRunway: FC<IReducedRunway> = ({ name, runwayId }) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.reduced.findIndex(
      (item) => item.id === runwayId
    );
    return state.snowtamFormV2.reduced[index];
  });
  const defaultValue = useSelector((state: RootState) => {
    if (!state.general.user?.airport.runways) {
      return 0;
    }
    const index = state.general.user?.airport.runways?.findIndex(
      (item) => item.id === runwayId
    );
    return state.general.user?.airport.runways[index]?.length;
  });
  const dispatch = useDispatch();

  const { t } = useTranslation("common");
  return (
    <CounterInput
      name={"reduced"}
      label={name}
      minValue={0}
      maxValue={defaultValue}
      step={50}
      value={value?.value}
      textAdornment={t`meter`}
      onChange={(val) => dispatch(setReduced({ runwayId, value: val }))}
      containerStyle={css`
        max-width: unset;
      `}
    />
  );
};

interface IReducedRunway {
  name: string;
  defaultValue?: number;
  runwayId: number;
}

export default ReducedRunway;
