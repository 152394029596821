import { FC } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { CheckButton, DropDownItem } from "@snowtam/ui";
import { RootState } from "../../../../redux";
import {
  setLowTemperature,
  setPollution,
  setPollutionDepth,
  setRunwayState,
} from "../../../../redux/snowtamform/snowtamformv2Reducer";
import {
  RWYCondition,
  RWYConditionType,
  ThirdNumbers,
} from "../../../../settings/runwayStates.constants";
import pollutionDepth from "../../../../settings/pollutionDepth";
import settings from "../../../../settings/form";

const TreatedCheck: FC<{ title: string }> = ({ title }) => {
  const value = useSelector(
    (state: RootState) => state.snowtamFormV2.low_temperature
  );
  const dispatch = useDispatch();

  const store = useStore();
  const handleChange = (val: boolean) => {
    if (val) {
      updateRwyConditions();
    }
    dispatch(setLowTemperature(val));
  };

  const updateRwyConditions = () => {
    const wetRWYConditions = pollutionDepth
      .filter((items) => items.type === RWYConditionType.COMMON)
      .map((item) => item.data);
    const state: RootState = store.getState();
    state.snowtamFormV2.runways.forEach((rwy) => {
      for (let thirdId: ThirdNumbers = 1; thirdId <= 3; thirdId++) {
        if (
          !wetRWYConditions.includes(
            rwy.thirds[thirdId].status?.value as string
          )
        ) {
          setRwyThirdConditionNR(rwy.runwayId, thirdId as ThirdNumbers);
        }
      }
    });
  };

  const setRwyThirdConditionNR = (runwayId: number, third: ThirdNumbers) => {
    const pollutionDepthSettings = pollutionDepth.find(
      (item) => item.data === RWYCondition.NR
    );
    if (!pollutionDepthSettings) return;
    dispatch(
      setRunwayState({
        runwayId,
        third,
        value: {
          id: 0,
          value: RWYCondition.NR,
          data: RWYCondition.NR,
        },
      })
    );
    dispatch(
      setPollutionDepth({
        runwayId,
        third,
        value: pollutionDepthSettings.depth.min,
      })
    );
    dispatch(
      setPollution({
        runwayId,
        third,
        value: settings.pollutionDepthItems.find(
          (item) => item.data === pollutionDepthSettings.default_coverage
        ) as DropDownItem,
      })
    );
  };
  return (
    <CheckButton
      _id={"low_temperature"}
      label={title}
      value={value}
      onChange={handleChange}
    />
  );
};

export default TreatedCheck;
