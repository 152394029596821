import { FC } from "react";
import SnowStormCheck from "./SnowStormCheck";
import SandCheck from "./SandCheck";
import SnowBankCheck from "./SnowBankCheck";
import TreatedCheck from "./TreatedCheck";
import styled from "styled-components";
import MeteoHelper from "../../InfoDialogs/MeteoHelper";
import {GroupTitle} from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const Meteo: FC<{ runwayId: number }> = ({ runwayId }) => {
  const { t } = useTranslation("data_form");

  return (
    <>
      <GroupTitle
        label={t`meteo_conditions`}
        endAdornmentButton={<MeteoHelper />}
      />
      <Row>
        <SnowStormCheck title={t`snowstorm`} name="" runwayId={runwayId} />
        <SandCheck title={t`sand`} name="" runwayId={runwayId} />
        <SnowBankCheck runwayId={runwayId} />
        <TreatedCheck title={t`treated`} name="" runwayId={runwayId} />
      </Row>
    </>
  );
};
export default Meteo;
