import { FC } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

//@todo refactor button styles
const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: ${(props) => props.theme.colors.runways.disabled_button};
  border-radius: 8px 8px 0 0;
  padding: 0;
  height: auto;
  justify-content: space-between;
  z-index: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.runways.stroke};
  border-left: 1px solid ${({ theme }) => theme.colors.runways.stroke};
  border-right: 1px solid ${({ theme }) => theme.colors.runways.stroke};
`;

const Button = styled.button<{ active: boolean; noBorder?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.blocks : theme.colors.runways.disabled_button};
  border-top: 1px solid ${({ theme }) => theme.colors.runways.stroke};
  border-left: none;
  border-right: none;
  border-bottom: none;

  &:last-child {
    border-radius: 0 8px 0 0;
    border-right: 1px solid ${({ theme }) => theme.colors.runways.stroke};
  }

  &:first-child {
    border-radius: 8px 0 0 0;
    border-left: 1px solid ${({ theme }) => theme.colors.runways.stroke};
  }

  padding: 16px 0;
  margin: -1px 0 0;
  position: relative;
  font-weight: ${({ active }) => (active ? "600" : "400")};
  font-size: 1rem;
  line-height: 1.5rem;
  z-index: 10;
  cursor: pointer;
  color: ${({ active, theme }) =>
    active ? theme.colors.active : theme.colors.separator};
  box-sizing: border-box;
  box-shadow: ${({ active, theme }) =>
    active ? theme.shadows.runway : "none"};

  &:last-child {
    margin-right: -1px;
  }

  &:first-child {
    margin-left: -1px;
  }

  ${({ active }) =>
    active
      ? css`
          border-radius: 8px 8px 0 0 !important;
          border-left: 2px solid ${({ theme }) => theme.colors.runways.stroke} !important;
          border-top: 2px solid ${({ theme }) => theme.colors.runways.stroke} !important;
          border-right: 2px solid ${({ theme }) => theme.colors.runways.stroke} !important;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 2px;
            background-color: ${(props) =>
              props.theme.colors.runways.active_highlight};
          }
        `
      : css`
          &:last-child {
            border-left: 1px solid ${({ theme }) => theme.colors.runways.stroke};
          }

          &:first-child {
            border-right: 1px solid
              ${({ theme }) => theme.colors.runways.stroke};
          }
        `}
  ${({ noBorder }) =>
    noBorder
      ? css`
          &:last-child {
            border-left: none;
          }

          &:first-child {
            border-right: none;
          }
        `
      : null}
`;

const DesktopText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;
const MobileText = styled.span`
  @media (min-width: 768px) {
    display: none;
  }
`;
const ThirdsMenu: FC<IThirdsMenu> = ({ active, onChange }) => {
  const { t } = useTranslation("data_form");
  return (
    <Container>
      <Button
        active={active === 1}
        noBorder={active === 2}
        name={"third_1"}
        type="button"
        onClick={() => onChange(1)}
      >
        <MobileText>1/3</MobileText>
        <DesktopText>{t`thirds.first`}</DesktopText>
      </Button>
      <Button
        active={active === 2}
        type="button"
        name={"third_2"}
        onClick={() => onChange(2)}
      >
        <MobileText>2/3</MobileText>
        <DesktopText>{t`thirds.second`}</DesktopText>
      </Button>
      <Button
        active={active === 3}
        noBorder={active === 2}
        type="button"
        name={"third_3"}
        onClick={() => onChange(3)}
      >
        <MobileText>3/3</MobileText>
        <DesktopText>{t`thirds.third`}</DesktopText>
      </Button>
    </Container>
  );
};

export interface IThirdsMenu {
  active: 1 | 2 | 3;
  onChange: (val: 1 | 2 | 3) => void;
}

export default ThirdsMenu;
