import { FC } from "react";
import { BorderedCard } from "./cardStyles";
import CardLeft from "./CardLeft";
import { IconTheme, Radio, Switch } from "@snowtam/ui";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../redux/generalReducer";
import { RootState } from "../../redux";
import API from "../../api/API";

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-top: -12px;
  & > div {
    margin-top: 12px;
  }
  @media (max-width: 1199px) {
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    margin-bottom: -0.5rem;
    margin-top: 0.5rem;
  }
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioButton = styled(Radio)`
  &:not(:last-child) {
    margin-right: 3rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 1rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const StyledSwitch = styled(Switch)`
  margin-top: 16px;
`;
const Theme: FC = () => {
  const theme = useSelector(
    (state: RootState) => state.general.settings?.theme
  );
  const { t } = useTranslation("settings");
  const dispatch = useDispatch();
  const mainTheme = useTheme();

  const handleChoose = (value: string) => {
    dispatch(setTheme(value));
    API.putSetting({ theme: value }).then();
  };

  return (
    <BorderedCard>
      <CardLeft
        icon={() => (
          <IconTheme color={mainTheme.colors.active} width={24} height={24} />
        )}
        title={t`theme`}
        caption={t`theme_caption`}
      >
        <StyledSwitch
          name={"theme_auto"}
          label={window.innerWidth < 768 ? t`theme_auto_mobile` : t`theme_auto`}
          checked={theme === "auto"}
          onCheck={() => {
            if (theme === "auto") {
              switch (mainTheme.name) {
                case "Main":
                  handleChoose("main");
                  break;
                case "Night":
                  handleChoose("night");
                  break;
                case "Dark":
                  handleChoose("dark");
                  break;
                default:
                  handleChoose("main");
              }
            } else {
              handleChoose("auto");
            }
          }}
        />
      </CardLeft>
      <SettingsContainer>
        <Container>
          <RadioButton
            name={"theme_main"}
            color="#FFFFFF"
            borderColor="#D9D9D9"
            onClick={() => handleChoose("main")}
            label={t`themes.main`}
            selected={mainTheme.name === "Main"}
            disabled={theme === "auto"}
          />
          <RadioButton
            name={"theme_dark"}
            color="#363740"
            onClick={() => handleChoose("dark")}
            label={t`themes.dark`}
            selected={mainTheme.name === "Dark"}
            disabled={theme === "auto"}
          />
          <RadioButton
            name={"theme_night"}
            color="#181F2E"
            onClick={() => handleChoose("night")}
            label={t`themes.night`}
            selected={mainTheme.name === "Night"}
            disabled={theme === "auto"}
          />
        </Container>
      </SettingsContainer>
    </BorderedCard>
  );
};

export default Theme;
