import { FC } from "react";
import styled from "styled-components";
import Position from "./Position";
import Distance from "./Distance";
import {GroupTitle} from "@snowtam/ui";
import Helper from "./Helper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    position: relative;
  }
`;
const Index: FC<{
  runwayId: number;
}> = ({ runwayId }) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamForm.snowbank_runway_position.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamForm.snowbank_runway_position[index]?.position;
  });
  const { t } = useTranslation("data_form");

  return (
    <>
      <GroupTitle label={t`snowbanks`} endAdornmentButton={<Helper />} />
      <Row>
        <Position runwayId={runwayId} />
        {value?.id !== 0 ? <Distance runwayId={runwayId} /> : null}
      </Row>
    </>
  );
};
export default Index;
