import { FC, useMemo } from "react";
import { DropDown, DropDownItem } from "@snowtam/ui";
import { useDispatch } from "react-redux";
import { setComment } from "../../../../redux/snowtamformReducer";
import { useTranslation } from "react-i18next";

const Templates: FC<{ data: any }> = ({ data }) => {
  const items: DropDownItem[] | undefined = useMemo(() => {
    if (data) {
      return data.map((item: any) => ({
        id: item.id,
        value: item.name,
        data: item.text,
      }));
    }
  }, [data]);
  const dispatch = useDispatch();

  const { t } = useTranslation(["data_form", "common"]);

  return (
    <>
      {items && items.length > 0 ? (
        <DropDown
          name={"choose_template"}
          label={t`data_form:choose_template`}
          items={items || []}
          notChosenText={t`common:not_chosen`}
          onChoose={(item) => {
            dispatch(setComment(item.data));
          }}
        />
      ) : null}
    </>
  );
};
export default Templates;
