import { useEffect, useState } from "react";
import Requests from "../api/requests";
import { useInterval } from "@snowtam/ui";

const useGetLastRequest = (): [number | undefined, () => void] => {
  const [request, setRequest] = useState<number>();
  const fetchLast = () => {
    Requests.getLast().then((res) => setRequest(res));
  };

  useInterval(() => {
    fetchLast();
  }, 15);

  useEffect(fetchLast, []);

  return [request, fetchLast];
};

export default useGetLastRequest;
