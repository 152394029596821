import { FC } from "react";
import { BorderedCard } from "./cardStyles";
import CardLeft from "./CardLeft";
import { DropDown, IconGlobe } from "@snowtam/ui";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { DropDownItem } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { setLanguage } from "../../redux/generalReducer";
import API from "../../api/API";

const languages: DropDownItem[] = [
  {
    id: 0,
    value: "RUS",
    data: "ru",
  },
  { id: 1, value: "EN", data: "en" },
];

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 1rem;
  }
`;
const Language: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation("settings");
  const language = useSelector(
    (state: RootState) => state.general.settings?.language
  );

  const dispatch = useDispatch();

  const handleChoose = (val: DropDownItem) => {
    dispatch(setLanguage(val.data));
    API.putSetting({ language: val.data }).then();
  };

  return (
    <BorderedCard>
      <CardLeft
        icon={() => (
          <IconGlobe color={theme.colors.active} width={24} height={24} />
        )}
        title={t`language`}
        caption={t`language_caption`}
      />
      <Container>
        <DropDown
          name={"language_select"}
          label={t`language_input`}
          items={languages}
          value={languages.filter((i) => i.data === language).pop()}
          onChoose={handleChoose}
        />
      </Container>
    </BorderedCard>
  );
};

export default Language;
