import { FC } from "react";
import { InfoPopup } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const Helper: FC = () => {
  const { t } = useTranslation("helper");
  return (
    <>
      <InfoPopup
        title={t`twy_status.title`}
        text={
          <>
            {t`twy_status.line1`}
            <br />
            {t`twy_status.line2`}
          </>
        }
      />
    </>
  );
};
export default Helper;
