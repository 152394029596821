import { FC, useEffect, useMemo, useState } from "react";
import { BorderedCard } from "./cardStyles";
import CardLeft from "./CardLeft";
import { DropDown, DropDownItem, IconFont } from "@snowtam/ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { setFontSize } from "../../redux/generalReducer";
import API from "../../api/API";

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 1rem;
  }
`;

const FontSize: FC = () => {
  const fontSize = useSelector(
    (state: RootState) => state.general.settings?.fontSize
  );
  const [value, setValue] = useState<DropDownItem | undefined>();

  const { t } = useTranslation("settings");
  const dispatch = useDispatch();
  const fontItems: DropDownItem[] = useMemo(
    () => [
      {
        id: 0,
        value: t`fonts.100`,
        data: "100%",
      },
      {
        id: 1,
        value: t`fonts.110`,
        data: "110%",
      },
      {
        id: 2,
        value: t`fonts.120`,
        data: "120%",
      },
      {
        id: 3,
        value: t`fonts.130`,
        data: "130%",
      },
    ],
    [t]
  );

  useEffect(() => {
    if (fontSize) {
      setValue(fontItems.filter((item) => item.data === fontSize).pop());
    } else {
      setValue(fontItems[0]);
    }
  }, [fontItems, fontSize]);

  const handleChoose = (value: DropDownItem) => {
    setValue(value);
    dispatch(setFontSize(value.data));
    API.putSetting({ fontSize: value.data }).then();
  };

  return (
    <BorderedCard>
      <CardLeft
        icon={() => <IconFont />}
        title={t`font`}
        caption={t`font_caption`}
      />
      <Container>
        <DropDown
          value={value}
          name={"font_size"}
          label={t`font_input`}
          items={fontItems}
          onChoose={handleChoose}
        />
      </Container>
    </BorderedCard>
  );
};

export default FontSize;
