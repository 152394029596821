import { FC, useRef } from "react";
import styled, { css, keyframes, useTheme } from "styled-components";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  IconChevron,
  IconClose,
  IconExit,
  IconHistory,
  IconSettings,
  IconTemplate,
  useOutsideClicker,
} from "@snowtam/ui";
import { Link, useLocation } from "react-router-dom";
import Info from "./Info";

const slideRight = keyframes`
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
`;
const BackDrop = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.backdrop};
  z-index: 1120;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.blocks};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 500px;
  animation: ${slideRight} 0.2s ease-out;

  a {
    li {
      border-bottom: 1px solid ${(props) => props.theme.colors.stroke_block};
    }
  }

  @media (max-width: 767px) {
    width: 300px;
    max-width: 90vw;
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
  font-size: 1.5rem;
  line-height: 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.stroke_block};
  @media (max-width: 767px) {
    padding: 1rem 0;
    font-size: 1.125rem;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  bottom: 16px;
`;

const MenuItem = styled.li<{ active?: boolean }>`
  margin: 0;
  padding: 16px 24px;
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.font1};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
  }

  ${({ active }) =>
    active
      ? css`
          background: ${(props) => props.theme.colors.menu_highlight};
          color: ${(props) => props.theme.colors.active};

          svg {
            color: ${(props) => props.theme.colors.active};
          }
        `
      : null}
`;

interface MenuProps {
  onClose: () => void;
}

const Menu: FC<MenuProps> = ({ onClose }) => {
  const rootNode = document.getElementById("root") as HTMLDivElement;
  const menuRef = useRef(null);
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation("menu");

  useOutsideClicker(menuRef, () => onClose());
  const handleExit = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/");
  };

  return createPortal(
    <>
      <BackDrop>
        <MenuContainer ref={menuRef}>
          <Header>
            {t`menu`}
            <CloseButton
              icon={() => <IconClose />}
              color="transparent"
              onClick={() => onClose()}
              name={"close"}
            />
          </Header>
          <Link
            id={"burger_link_history"}
            to={"/history"}
            data-value={"/history"}
          >
            <MenuItem active={location.pathname === "/history"}>
              <IconHistory
                style={{ marginRight: "0.5rem", color: theme.colors.active }}
              />
              {t`menu:history`}
              <IconChevron style={{ marginLeft: "auto" }} />
            </MenuItem>
          </Link>
          <Link
            id={"burger_link_templates"}
            to={"/templates"}
            data-value={"/templates"}
          >
            <MenuItem active={location.pathname === "/templates"}>
              <IconTemplate
                style={{ marginRight: "0.5rem", color: theme.colors.active }}
              />
              {t`menu:templates`}
              <IconChevron style={{ marginLeft: "auto" }} />
            </MenuItem>
          </Link>
          <Link
            id={"burger_link_settings"}
            to={"/settings"}
            data-value={"/settings"}
          >
            <MenuItem active={location.pathname === "/settings"}>
              <IconSettings
                style={{ marginRight: "0.5rem", color: theme.colors.active }}
              />
              {t`menu:settings`}
              <IconChevron style={{ marginLeft: "auto" }} />
            </MenuItem>
          </Link>
          <a
            id={"burger_link_exit"}
            href="/"
            onClick={handleExit}
            data-action={"exit"}
          >
            <MenuItem>
              <IconExit
                style={{ marginRight: "0.5rem", color: theme.colors.active }}
              />
              {t`exit`}
              <IconChevron style={{ marginLeft: "auto" }} />
            </MenuItem>
          </a>
          <Info />
        </MenuContainer>
      </BackDrop>
    </>,
    rootNode
  );
};
export default Menu;
