import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setSnowbankRunwayLengthAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{ runwayId: number; value: number }>
) => {
  const index = state.snowbank_runway_position.findIndex(
    (item) => item.runwayId === action.payload.runwayId
  );
  state.snowbank_runway_position[index].value = action.payload.value;
  state.pause = true;
};

export default setSnowbankRunwayLengthAction;
