const information = {
  information: "Информация",
  operator: "Оператор",
  airport: "Аэропорт",
  runways: "Количество ВПП",
  taxiways: "Количество РД",
  aprons: "Количество Перронов",
  length: "Длина",
  width: "Ширина",
  meter: "м",
};

export default information;
