import { FC, useCallback, useEffect, useState } from "react";
import Title from "./Title/";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import { RootState } from "../../redux";
import { setForm } from "../../redux/snowtamformReducer";
import { DialogYesNo } from "@snowtam/ui";
import RunwayFields from "./RunwayFields";
import GeneralFields from "./GeneralFields";
import { useTranslation } from "react-i18next";
import SaveButton from "./SaveButton";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import ExistDialog from "./ExistDialog";
import { DATE_FORMAT, TIME_FORMAT } from "../../settings/app.constants";
import useServerTime from "../../hooks/useServerTime";
import useCheckRequestExist from "../../hooks/useCheckRequestExist";
import Requests from "../../api/requests";

dayjs.extend(utc);

const Body = styled.div`
  padding: 16px;
  @media (max-width: 767px) {
    padding: 0;
  }
`;

const ErrorSpan = styled.span`
  margin-top: 1rem;
  text-align: center;
`;
const FromExistingForm: FC<{ request_id: number | string }> = ({
  request_id,
}) => {
  const runways = useSelector(
    (state: RootState) => state.general.user?.airport.runways
  );
  const user_id = useSelector((state: RootState) => state.general.user?.id);

  const [data, setData] = useState<any>();

  const existData = useCheckRequestExist();

  const store = useStore();
  const [paused, setPaused] = useState(false);
  const [runwayId, setRunwayId] = useState<number>();
  const time = useServerTime();
  const [runwaysError, setRunwaysError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation("common");

  useEffect(() => {
    Requests.findOneByID({ id: parseInt(request_id.toString(), 10) }).then(
      (res) => setData(res)
    );
  }, [request_id]);

  const handleSetForm = () => {
    const dayjsTime = dayjs.utc(time, "x");
    data.data.runways.forEach((request: any) => {
      request.timestamp = dayjsTime.unix();
      request.date = dayjsTime.format(DATE_FORMAT);
      request.time = dayjsTime.format(TIME_FORMAT);
    });
    dispatch(setForm({ ...data.data, user_id: user_id }));
  };

  useEffect(() => {
    if (data && time && existData) {
      const rootState: RootState = store.getState();
      if (existData.exist) return;
      if (rootState.snowtamForm.pause) {
        setPaused(true);
      } else {
        handleSetForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data, time, existData]);

  const handleSetFirstRunway = useCallback(
    (id: number) => {
      if (typeof runwayId === "undefined") {
        setRunwayId(id);
      }
    },
    [runwayId]
  );

  useEffect(() => {
    if (typeof runways !== "undefined" && runways.length > 0) {
      handleSetFirstRunway(runways[0].id);
    }
  }, [handleSetFirstRunway, runways]);

  useEffect(() => {
    if (typeof runways === "undefined" || runways?.length === 0) {
      setRunwaysError(true);
    }
  }, [runways]);

  return (
    <>
      {data ? (
        <>
          <Title
            onSetRunway={(id) => {
              window.scroll({ top: 0, behavior: "smooth" });
              setRunwayId(id);
            }}
          />
          {runwayId ? (
            <Body>
              <RunwayFields runwayId={runwayId} key={runwayId} />
              <GeneralFields>
                <SaveButton />
              </GeneralFields>
            </Body>
          ) : null}
        </>
      ) : null}
      {paused ? (
        <>
          <DialogYesNo
            title={t`errors.warning`}
            caption={t`errors.paused_form`}
            onApply={() => {
              handleSetForm();
              setPaused(false);
            }}
            yesText={t`continue`}
            onClose={() => history.goBack()}
            noText={t`cancel`}
          >
            <ErrorSpan>{t`errors.are_you_sure`}</ErrorSpan>
          </DialogYesNo>
        </>
      ) : null}
      {existData?.exist ? <ExistDialog data={existData} /> : null}
      {runwaysError ? (
        <DialogYesNo
          onClose={() => history.goBack()}
          title={t`error`}
          caption={t`errors.rwys_empty`}
          onApply={() => history.goBack()}
          yesText={"OK"}
        />
      ) : null}
    </>
  );
};
export default FromExistingForm;
