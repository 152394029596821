import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import loginEn from "./translations/en/login";
import loginRu from "./translations/ru/login";
import commonEn from "./translations/en/common";
import commonRu from "./translations/ru/common";
import menuEn from "./translations/en/menu";
import menuRu from "./translations/ru/menu";
import informationEn from "./translations/en/information";
import informationRu from "./translations/ru/information";
import templatesRu from "./translations/ru/templates";
import templatesEn from "./translations/en/templates";
import historyEn from "./translations/en/history";
import historyRu from "./translations/ru/history";
import dataFormEn from "./translations/en/data_form";
import dataFormRu from "./translations/ru/data_form";
import requestsEn from "./translations/en/requests";
import requestsRu from "./translations/ru/requests";
import helperEn from "./translations/en/helper";
import helperRu from "./translations/ru/helper";
import settingsEn from "./translations/en/settings";
import settingsRu from "./translations/ru/settings";
import frictionEn from "./translations/en/friction";
import frictionRu from "./translations/ru/friction";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ["localStorage"] },
    interpolation: { escapeValue: false },
    fallbackLng: "ru",
    resources: {
      en: {
        login: loginEn,
        common: commonEn,
        menu: menuEn,
        information: informationEn,
        templates: templatesEn,
        history: historyEn,
        data_form: dataFormEn,
        requests: requestsEn,
        helper: helperEn,
        settings: settingsEn,
        friction: frictionEn,
      },
      ru: {
        login: loginRu,
        common: commonRu,
        menu: menuRu,
        information: informationRu,
        templates: templatesRu,
        history: historyRu,
        data_form: dataFormRu,
        requests: requestsRu,
        helper: helperRu,
        settings: settingsRu,
        friction: frictionRu,
      },
    },
  });

export default i18next;
