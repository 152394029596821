import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserType } from "../../@types/models";
import API from "../api/API";

const initialState: GeneralStateType = {
  userLoading: true,
  settings: {},
};

export type GeneralStateType = {
  user?: UserType;
  userLoading: boolean;
  settings: {
    fontSize?: string;
    theme?: string;
    language?: string;
    friction_form?: boolean;
    new_form?: boolean;
    sidebar_collapsed?: boolean;
  };
};
export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setState: (state, action) => {
      state = { ...action.payload, settings: state.settings };
      return state;
    },
    updateState: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
    setSettings: (state, action: PayloadAction<any>) => {
      state.settings = { ...action.payload };
    },
    setFontSize: (state, action: PayloadAction<string>) => {
      state.settings.fontSize = action.payload;
    },
    setTheme: (state, action: PayloadAction<string>) => {
      state.settings.theme = action.payload;
    },
    setNewForm: (state, action: PayloadAction<boolean>) => {
      API.putSetting({ new_form: action.payload });
      state.settings.new_form = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.settings.language = action.payload;
    },
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      state.userLoading = action.payload;
    },
    reloadUser: (state) => {
      state.user = undefined;
      state.userLoading = true;
    },
    collapseSidebar: (state, action: PayloadAction<boolean>) => {
      state.settings.sidebar_collapsed = action.payload;
    },
  },
});

export const {
  setState,
  updateState,
  setUserLoading,
  setFontSize,
  setTheme,
  setLanguage,
  setNewForm,
  setSettings,
  reloadUser,
  collapseSidebar,
} = generalSlice.actions;

export default generalSlice.reducer;
