const common = {
  server_error: "Server error",
  connection_error: "Connection lost",
  exit: "Logout",
  back: "Back",
  save: "Save",
  total_characters: "Total characters",
  yes: "Yes",
  no: "No",
  continue: "Continue",
  cancel: "Cancel",
  delete: "Delete",
  create: "Create",
  status: "Status",
  time: "UTC Time",
  meter: "m",
  millimeter: "mm",
  not_chosen: "Not chosen",
  change_password: "Change password",
  change: "Apply",
  password: "Password",
  accepted: "Active",
  suspended: "Suspended",
  re_password: "Repeat password",
  user: "Operator",
  login: "Login",
  operator: "Operator",
  status_changed: "Status changed date",
  change_status_action: "Change status",
  change_password_action: "Change password",
  change_status_title: "Change status",
  change_password_title: "Change password",
  new_operator_title: "New operator",
  delete_operator_title: "Delete user",
  empty_templates: "You have no text templates",
  add_new_template: "New template",
  delete_template: "Delete template?",
  request_exist1: "SNOWTAM Publication request",
  request_exist2: " has already been sent at ",
  clear_form: "Clear form?",
  clear_form_caption: "Are you sure you want to clear form?",
  empty_template: "Empty template!",
  template_saved: "Template saved!",
  print: "Print",
  phone: "Phone",
  print_title_users: "Operators",
  empty_users: "Operators not found",
  error: "Error",
  pages: "Pages",
  fatal_error_caption:
    "An error occurred while performing the operation, try refreshing the page.",
  reload: "Refresh",
  exit_title: "Exit",
  exit_label: "Are you sure you want to exit?",
  title_operator:'SNOWTAM - Operator',
  title_manager:'SNOWTAM - Airport',
  title_common:'SNOWTAM',
  errors: {
    rwys_empty: "Empty runways!",
    fill_all: "Fill all fields",
    passwords_match: "Passwords didn't match",
    warning: "Warning",
    connection_lost: "Connection lost",
    repeat_later: "Please repeat later.",
    server_error: "Server error",
    paused_form: "The input form has filled data.",
    are_you_sure: "Are you sure you want to continue?",
    suspended: "You are suspended from work!",
    cant_decline: "Can't cancel request",
    request_inprogress: "Request is in progress.",
  },
};

export default common;
