import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {GroupTitle} from "@snowtam/ui";
import Width from "./Width";
import ReducedRunway from "./ReducedRunway";
import DimensionsHelper from "../../InfoDialogs/DimensionsHelper";

const Row = styled.div`
  display: flex;
  margin: 0 -12px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Column = styled.div`
  max-width: 50%;
  padding: 0 12px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 767px) {
    width: 100%;
    min-width: 290px;
    max-width: 100%;
  }
`;
const Dimensions: FC<{ runwayId: number }> = ({ runwayId }) => {
  const { t } = useTranslation("data_form");
  return (
    <>
      <GroupTitle
        label={t`runway_dimensions`}
        endAdornmentButton={<DimensionsHelper />}
      />
      <Row style={{ marginBottom: "16px" }}>
        <Column>
          <Width runwayId={runwayId} />
        </Column>
        <Column>
          <ReducedRunway
            name={t`reduced_rwy`}
            defaultValue={300}
            runwayId={runwayId}
          />
        </Column>
      </Row>
    </>
  );
};

export default Dimensions;
