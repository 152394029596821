import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";
import { DropDownItem } from "@snowtam/ui";
import pollutionDepth, {
  PollutionDepthSettingsType,
} from "../../../settings/pollutionDepth";

const setRunwayStateAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{
    runwayId: number;
    third: 1 | 2 | 3;
    value: DropDownItem;
  }>
) => {
  const index = state.runways.findIndex(
    (item) => item.runwayId === action.payload.runwayId
  );
  state.runways[index].thirds[action.payload.third].status =
    action.payload.value;
  const availablePollutionValues = (
    pollutionDepth.find(
      (item) => item.data === action.payload.value.value
    ) as PollutionDepthSettingsType
  ).depth;

  const currentPollutionValue =
    state.runways[index].thirds[action.payload.third].pollutionDepth ?? 0;

  if (currentPollutionValue < availablePollutionValues.min) {
    state.runways[index].thirds[action.payload.third].pollutionDepth =
      availablePollutionValues.min;
  }

  if (currentPollutionValue > availablePollutionValues.max) {
    state.runways[index].thirds[action.payload.third].pollutionDepth =
      availablePollutionValues.max;
  }
  if (availablePollutionValues.exclude.includes(currentPollutionValue)) {
    state.runways[index].thirds[action.payload.third].pollutionDepth =
      availablePollutionValues.min;
  }

  state.pause = true;
};

export default setRunwayStateAction;
