const data_form = {
  rwy: "ВПП",
  rwy_active: "Отправлять данные",
  choose_rwy: "Выберите ВПП",
  runway_data: "Данные по ВПП",
  clean: "Очистить",
  clean_desktop: "Очистить форму",
  no_rwy_selected_title: "Выберите ВПП",
  no_rwy_selected_caption: "Чтобы продолжить заполнение формы выберите ВПП",
  thirds: {
    first: "Первая треть",
    first_title: "Первая треть ВПП",
    second: "Вторая треть",
    second_title: "Вторая треть ВПП",
    third: "Третья треть",
    third_title: "Третья треть ВПП",
    condition_code: "Код состояния (RWYCC)",
    condition_description: "Тип загрязнения",
    contaminant_depth: "Глубина загрязнения",
    coverage: "Площадь покрытия",
  },
  conditions: {
    NR: "ИНФОРМАЦИЯ ОТСУТСТВУЕТ",
    DRY: "СУХО",
    WET_SNOW_ON_TOP_OF_ICE: "МОКРЫЙ СНЕГ ПОВЕРХ ЛЬДА",
    WET_SNOW_ON_TOP_OF_COMPACTED_SNOW: "МОКРЫЙ СНЕГ ПОВЕРХ УПЛОТНЕННОГО СНЕГА",
    WET_SNOW: "МОКРЫЙ СНЕГ",
    WET_ICE: "МОКРЫЙ ЛЕД",
    WET: "МОКРО",
    WATER_ON_TOP_OF_COMPACTED_SNOW: "ВОДА ПОВЕРХ УПЛОТНЕННОГО СНЕГА",
    STANDING_WATER: "СТОЯЧАЯ ВОДА",
    SLUSH: "СЛЯКОТЬ",
    ICE: "ЛЕД",
    FROST: "ИНЕЙ",
    DRY_SNOW_ON_TOP_OF_ICE: "СУХОЙ СНЕГ ПОВЕРХ ЛЬДА",
    DRY_SNOW_ON_TOP_OF_COMPACTED_SNOW: "СУХОЙ СНЕГ ПОВЕРХ УПЛОТНЕННОГО СНЕГА",
    DRY_SNOW: "СУХОЙ СНЕГ",
    COMPACTED_SNOW: "УПЛОТНЕННЫЙ СНЕГ",
  },
  runway_dimensions: "Размеры ВПП",
  runway_width: "Ширина ВПП",
  reduced_rwy: "Уменьшенная длина ВПП",
  meteo_conditions: "Метеоусловия",
  snowstorm: "Метель",
  sand: "Песок",
  treated: "Реагенты",
  snowbank: "Сугробы у ВПП",
  snowbanks: "Сугробы на ВПП",
  snowbanks_distance: "Расстояние от осевой",
  snowbanks_position: "Расположение",
  general_data: "Общие данные  по Аэропорту",
  snowbanks_twy: "Сугробы на РД",
  status_twy: "Состояние РД",
  status_apron: "Состояние перронов",
  adhesion: "Измеренный коэффициент сцепления",
  comment: "Замечания",
  comment_text: "Введите данные",
  choose_template: "Заполнить по шаблону",
  choose_template_mobile: "По шаблону",
  send: "Отправить",
  send_request: "Отправить заявку?",
  creating_request: "Формирование сообщения",
  measuring_device: "Устройство замера",
  hide_text: "Скрыть текст",
  show_text: "Показать текст заявки",
  not_chosen: "Не выбрано",
  low_temperature: "Темп. ≤ -15°C",
  twy_poor: "РД в плохом состоянии",
  apron_poor: "Перрон в плохом состоянии",
  twy_snowbanks: "Заснеженные РД",
  pollution_depth: {
    nr: "менее 10%",
    25: "от 10% до 25%",
    50: "от 25% до 50%",
    75: "от 50% до 75%",
    100: "от 75% до 100%",
  },
  validation: {
    choose_device: "Выберите устройство измерения коэфициента сопротивления",
    empty_friction: "Введите значения коэффициента сопротивления",
    pollution_depth:
      "Не верное значение глубины загрязнения ВПП {{runway}} Треть №{{third}}",
    utc_date:
      "Подача заявки в будущем времени запрещена. Введите время заполнения в формате UTC.",
  },
  manualRWYCC: `Ручной ввод кода`,
  snowtam_text: "Текст сообщения",
  include_runways: "Включать данные по ВПП",
};

export default data_form;
