import { FC } from "react";
import styled, { keyframes } from "styled-components";
import { useSelector } from "react-redux";
import { GroupTitle, IconAirplane } from "@snowtam/ui";
import Time from "./Thirds/Time";
import Thirds from "./Thirds";
import Snowbanks from "./Snowbanks";
import { RootState } from "../../../redux";
import Meteo from "./Meteo";
import Dimensions from "./Dimensions";
import { useTranslation } from "react-i18next";
import FrictionForm from "./FrictionForm";

const FadeIn = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.blocks};
  padding: 24px;
  border-radius: 4px;
  margin-bottom: 24px;
  animation: ${FadeIn} 0.3s ease-out;
  @media (max-width: 767px) {
    padding: 16px;
  }
  @media (min-width: 1200px) {
    border: 1px solid ${({ theme }) => theme.colors.stroke_block};
    border-radius: 4px;
  }
`;

const RunwayFields: FC<{ runwayId: number }> = ({ runwayId }) => {
  const runway_name = useSelector((state: RootState) => {
    if (!state.general.user?.airport.runways) {
      return "";
    }
    const index = state.general.user?.airport.runways?.findIndex(
      (item) => item.id === runwayId
    );
    return state.general.user?.airport.runways[index]?.name;
  });
  const friction_form = useSelector(
    (state: RootState) => state.general.settings.friction_form
  );
  const { t } = useTranslation("data_form");
  return (
    <Body>
      <GroupTitle
        label={`${t`runway_data`} ${runway_name}`}
        icon={<IconAirplane />}
        endAdornmentButton={<Time runwayId={runwayId} />}
        noBorder
        wrapMobile
      />
      <Thirds runwayId={runwayId} />
      <Dimensions runwayId={runwayId} />
      <Meteo runwayId={runwayId} />
      <Snowbanks runwayId={runwayId} />
      {friction_form ? <FrictionForm runwayId={runwayId} /> : null}
    </Body>
  );
};

export default RunwayFields;
