import { FC } from "react";
import { IconButton, IconFont } from "@snowtam/ui";
import { useDispatch } from "react-redux";
import { setFontSize } from "../../redux/generalReducer";
import API from "../../api/API";

const FontSelector: FC = () => {
  const dispatch = useDispatch();

  const handleChange = () => {
    const currentScale = document.documentElement.style.fontSize;
    let fontSize;
    switch (currentScale) {
      case "100%":
        fontSize = "110%";
        break;
      case "110%":
        fontSize = "120%";
        break;
      case "120%":
        fontSize = "130%";
        break;
      default:
        fontSize = "100%";
    }
    dispatch(setFontSize(fontSize));
    API.putSetting({ fontSize }).then();
  };

  return (
    <IconButton
      icon={() => <IconFont />}
      color="transparent"
      onClick={handleChange}
      name={"font_switch"}
    />
  );
};

export default FontSelector;
