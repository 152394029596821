import { FC, useCallback, useEffect, useMemo } from "react";
import { DropDown, DropDownItem } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { setPollution } from "../../../../../redux/snowtamform/snowtamformv2Reducer";
import { useTranslation } from "react-i18next";
import settings from "../../../../../settings/form";
import pollutionDepth from "../../../../../settings/pollutionDepth";

type PollutionProps = {
  third: 1 | 2 | 3;
  runwayId: number;
};

const Pollution: FC<PollutionProps> = ({ third, runwayId }) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamFormV2.runways[index]?.thirds[third]?.pollution;
  });

  const pollution_status = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamFormV2.runways[index]?.thirds[third]?.status;
  });

  const dispatch = useDispatch();

  const { t } = useTranslation(["data_form", "common"]);

  const val: DropDownItem =
    settings.pollutionDepthItems.find((item) => item.id === value?.id) ??
    (settings.pollutionDepthItems.find(
      (item) => item.id === 0
    ) as DropDownItem);

  const availableItems = useMemo(() => {
    if (!pollution_status) return [];

    const statusSettings = pollutionDepth.find(
      (item) => item.data === pollution_status.value
    );
    if (statusSettings) {
      return statusSettings.coverage;
    }
    return [];
  }, [pollution_status]);

  const items = useMemo(() => {
    const initialItems = settings.pollutionDepthItems;
    if (pollution_status) {
      return initialItems.filter((item) => availableItems.includes(item.data));
    }
    return initialItems;
  }, [pollution_status, availableItems]);

  const isValueAvailable = useCallback((): boolean => {
    return availableItems.includes(val.data);
  }, [availableItems, val]);

  useEffect(() => {
    if (!isValueAvailable() && typeof pollution_status !== "undefined") {
      dispatch(setPollution({ runwayId, third, value: items[0] }));
    }
  }, [dispatch, isValueAvailable, items, runwayId, third, pollution_status]);

  return (
    <DropDown
      name={"polution"}
      label={t`data_form:thirds.coverage`}
      items={items}
      disabled={items.length <= 1}
      value={val}
      notChosenText={t`common:not_chosen`}
      onChoose={(val) =>
        dispatch(setPollution({ runwayId, third, value: val }))
      }
    />
  );
};
export default Pollution;
