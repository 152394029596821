import { FC } from "react";
import styled from "styled-components";
import { UserRoles } from "../../Router";
import CanAccess from "../../components/CanAccess";

const Container = styled.div`
  padding: 1rem;
  position: relative;
  @media (max-width: 1199px) {
    padding: 0;
  }
`;

const Paper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.blocks};
  border: 1px solid ${({ theme }) => theme.colors.stroke_block};
  box-sizing: border-box;
  @media (max-width: 1199px) {
    background: none;
    padding: 1.5rem;
    border: none;
  }
  @media (max-width: 767px) {
    padding: 0.5rem;
  }
`;

const WarningPaper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  background: ${({ theme }) => theme.colors.error_background};
  border: 1px solid ${({ theme }) => theme.colors.error};
  box-sizing: border-box;
  margin-bottom: 16px;
  @media (max-width: 1199px) {
    background: none;
    padding: 1.5rem;
    border: none;
  }
  @media (max-width: 767px) {
    padding: 0.5rem;
  }
`;

const ExperimentalFlags: FC = () => {
  return (
    <Container>
      <WarningPaper>
        <b>Внимание!</b> Экспериментальные функции приложения. При включении
        экспериментальных функций стабильность работы приложения не
        гарантируется.
      </WarningPaper>
      <Paper>
        <CanAccess roles={[UserRoles.Operator]} />
      </Paper>
    </Container>
  );
};

export default ExperimentalFlags;
