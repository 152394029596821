import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setFrictionFormValueAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{
    runwayId: number;
    third: 1 | 2 | 3;
    value: string;
  }>
) => {
  if (typeof state.friction_form === "undefined") {
    state.friction_form = [];
  }
  const item = state.friction_form?.find(
    (item) => item.runwayId === action.payload.runwayId
  );
  if (!item) {
    state.friction_form.push({
      runwayId: action.payload.runwayId,
      first: action.payload.third === 1 ? action.payload.value : "",
      second: action.payload.third === 2 ? action.payload.value : "",
      third: action.payload.third === 3 ? action.payload.value : "",
      device: "",
    });
  } else {
    switch (action.payload.third) {
      case 1:
        item.first = action.payload.value;
        break;
      case 2:
        item.second = action.payload.value;
        break;
      case 3:
        item.third = action.payload.value;
        break;
    }
  }
  state.pause = true;
};

export default setFrictionFormValueAction;
