import React, { FC, lazy } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Menu from "./pages/Menu";
import LoginPage from "./pages/Login";
import AdminInfo from "./pages/AdminInfo";
import FormPage from "./pages/FormPage";
import Templates from "./pages/Templates";
import GuestRoute from "./routes/GuestRoute";
import AuthRoute from "./routes/UserRoute";
import AdminUsers from "./pages/AdminUsers";
import TargetRequest from "./pages/History/Target";
import Settings from "./pages/Settings";
import { ScrollTop } from "@snowtam/ui";
import useQueryUser from "./hooks/useQueryUser";
import ExperimentalFlags from "./pages/ExperimentalFlags";
import FrictionEquipment from "./pages/friction-equipment";

const History = lazy(() => import("./pages/History"));

export enum UserRoles {
  Operator = "user",
  Manager = "responsible",
}

const Router: FC = () => {
  useQueryUser();

  return (
    <BrowserRouter>
      <ScrollTop>
        <Switch>
          <AuthRoute
            exact
            path="/history"
            component={History}
            role={UserRoles.Operator}
          />
          <AuthRoute
            exact
            path="/history/:request"
            component={TargetRequest}
            role={UserRoles.Operator}
          />
          <AuthRoute
            exact
            path="/templates"
            component={Templates}
            role={UserRoles.Operator}
          />
          <AuthRoute
            exact
            path="/form"
            component={FormPage}
            role={UserRoles.Operator}
          />
          <AuthRoute
            exact
            path="/settings"
            component={Settings}
            role={UserRoles.Operator}
          />
          <AuthRoute
            exact
            path="/menu"
            component={Menu}
            role={UserRoles.Operator}
          />
          <AuthRoute
            exact
            path="/admin"
            component={AdminInfo}
            role={UserRoles.Manager}
          />
          <AuthRoute
            exact
            path="/admin/history/:request"
            component={TargetRequest}
            role={UserRoles.Manager}
          />
          <AuthRoute
            exact
            path="/admin/history"
            component={History}
            role={UserRoles.Manager}
          />
          <AuthRoute
            exact
            path="/admin/users"
            component={AdminUsers}
            role={UserRoles.Manager}
          />
          <AuthRoute
            exact
            path="/admin/settings"
            component={Settings}
            role={UserRoles.Manager}
          />
          <AuthRoute
            exact
            path="/friction_equipment"
            component={FrictionEquipment}
            role={UserRoles.Manager}
          />
          <AuthRoute
            exact
            path="/experimental"
            component={ExperimentalFlags}
            role={[UserRoles.Operator, UserRoles.Manager]}
          />
          <GuestRoute exact path="/" component={LoginPage} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </ScrollTop>
    </BrowserRouter>
  );
};
export default Router;
