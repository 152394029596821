import { useDispatch, useSelector, useStore } from "react-redux";
import { RootState } from "../../../redux";
import { useCallback, useState } from "react";
import {
  appendRunway,
  clearApronStatus,
  clearSnowbanksTaxiway,
  clearTaxiwayStatus,
  initializeForm,
  removeApronStatusById,
  removeRunway,
  removeSnowbanksTaxiwayById,
  removeTaxiwayStatusById,
  setApronStatusRaw,
  setForm,
  setPause,
  setServerTime,
  setSnowbanksTwyRaw,
  setTaxiwayStatusRaw,
} from "../../../redux/snowtamform/snowtamformv2Reducer";
import useQueryParam from "./useQueryParam";
import dayjs from "dayjs";
import cloneDeep from "lodash.clonedeep";
import {
  RunwayDataType,
  SnowtamFormStateTypeV2,
} from "../../../../@types/form";
import { DATE_FORMAT, TIME_FORMAT } from "../../../settings/app.constants";
import API from "../../../api/API";
import Requests from "../../../api/requests";

// @TODO Refactor
const useFormInitializer = (): [() => void, boolean] => {
  const query = useQueryParam();

  const request = query.get("request");
  const from = query.get("from");

  const [initialized, setInitialized] = useState(false);

  const user_id = useSelector((state: RootState) => state.general.user?.id);
  const formUser = useSelector(
    (state: RootState) => state.snowtamFormV2.user_id
  );

  const store = useStore();
  const dispatch = useDispatch();

  const handleInitializeNewForm = useCallback(async () => {
    const serverTime = await API.getServerTime();

    if (!formUser || user_id !== formUser) {
      dispatch(
        initializeForm({
          data: store.getState().general,
          time: serverTime.time,
          user_id: user_id,
        })
      );
    } else {
      const state: RootState = store.getState();
      let outdated = false;
      state.snowtamFormV2.runways.forEach((rwy) => {
        if (
          rwy.timestamp &&
          parseInt(rwy.timestamp, 10) <= serverTime.time / 1000 - 8 * 60 * 60
        ) {
          outdated = true;
        }
      });
      if (outdated) {
        dispatch(setServerTime(serverTime.time));
      }
      dispatch(setPause());
    }
  }, [dispatch, formUser, store, user_id]);

  const compareLoadedAndUpdateState = useCallback(
    (data: SnowtamFormStateTypeV2, time: number) => {
      const currentState: RootState = store.getState();
      const airport = currentState.general.user?.airport;

      if (!airport) return;

      const airportRunwayIDs = airport.runways.map((rwy) => rwy.id);
      const loadedRunwayIDs: number[] = data.runways.map(
        (rwy: RunwayDataType) => rwy.runwayId
      );

      const idIntersections = Array.from(
        new Set([...airportRunwayIDs, ...loadedRunwayIDs])
      );

      if (
        idIntersections.length !== data.runways.length ||
        idIntersections.length !== airport.runways.length
      ) {
        const additionalRunways = airport.runways.filter(
          (rwy) => !loadedRunwayIDs.includes(rwy.id)
        );
        const removedRunways = loadedRunwayIDs.filter(
          (rwy_id) => !airportRunwayIDs.includes(rwy_id)
        );
        if (additionalRunways) {
          additionalRunways.forEach((runway) => {
            dispatch(appendRunway({ runway, time }));
          });
        }
        if (removedRunways.length > 0) {
          removedRunways.forEach((rwy_id) => {
            dispatch(removeRunway(rwy_id));
          });
        }
      }
      const twyIDs = airport.taxiways.map((twy) => twy.id);
      const apronIDs = airport.aprons.map((twy) => twy.id);
      if (
        twyIDs.length === 0 &&
        data.snowbanks_taxiway.find((item) => item.id === -1)
      ) {
        dispatch(setSnowbanksTwyRaw("ALL"));
        dispatch(clearSnowbanksTaxiway());
      } else if (twyIDs.length === 0 && data.snowbanks_taxiway.length > 0) {
        dispatch(
          setSnowbanksTwyRaw(
            data.snowbanks_taxiway.map((item) => item.value).join(",")
          )
        );
        dispatch(clearSnowbanksTaxiway());
      } else if (
        twyIDs.length >= 0 &&
        (!data.snowbanks_twy_raw || data.snowbanks_twy_raw.length === 0)
      ) {
        dispatch(setSnowbanksTwyRaw(""));
      }

      if (
        twyIDs.length === 0 &&
        data.taxiway_status.find((item) => item.id === -1)
      ) {
        dispatch(setTaxiwayStatusRaw("ALL"));
        dispatch(clearTaxiwayStatus());
      } else if (twyIDs.length === 0 && data.taxiway_status.length > 0) {
        dispatch(
          setTaxiwayStatusRaw(
            data.taxiway_status.map((item) => item.value).join(",")
          )
        );
        dispatch(clearTaxiwayStatus());
      } else if (
        twyIDs.length >= 0 &&
        (!data.taxiway_status_raw || data.taxiway_status_raw.length === 0)
      ) {
        dispatch(setTaxiwayStatusRaw(""));
      }

      if (
        apronIDs.length === 0 &&
        data.apron_status.find((item) => item.id === -1)
      ) {
        dispatch(setApronStatusRaw("ALL"));
        dispatch(clearApronStatus());
      } else if (apronIDs.length === 0 && data.apron_status.length > 0) {
        dispatch(
          setApronStatusRaw(
            data.apron_status.map((item) => item.value).join(",")
          )
        );
        dispatch(clearApronStatus());
      } else if (
        apronIDs.length >= 0 &&
        (!data.apron_status_raw || data.apron_status_raw.length === 0)
      ) {
        dispatch(setApronStatusRaw(""));
      }

      twyIDs.unshift(-1);
      apronIDs.unshift(-1);

      data.snowbanks_taxiway.forEach((item) => {
        if (!twyIDs.includes(item.id)) {
          dispatch(removeSnowbanksTaxiwayById(item.id));
        }
      });

      data.apron_status.forEach((item) => {
        if (!apronIDs.includes(item.id)) {
          dispatch(removeApronStatusById(item.id));
        }
      });

      data.taxiway_status.forEach((item) => {
        if (!twyIDs.includes(item.id)) {
          dispatch(removeTaxiwayStatusById(item.id));
        }
      });
    },
    [dispatch, store]
  );

  const handleInitializeUpdateForm = useCallback(async () => {
    const requestData = await Requests.findOneByID({ id: request });
    const serverTime = await API.getServerTime();
    const data = cloneDeep(requestData.data);
    dispatch(setForm({ ...data, request_id: request }));
    compareLoadedAndUpdateState(data, serverTime.time);
  }, [compareLoadedAndUpdateState, dispatch, request]);

  const handleInitializeLoadedForm = useCallback(async () => {
    const requestData = await Requests.findOneByID({ id: from });

    const data = cloneDeep(requestData.data);
    const serverTime = await API.getServerTime();
    const dayjsTime = dayjs.utc(serverTime.time, "x");
    data.runways.forEach((runway: RunwayDataType) => {
      runway.timestamp = dayjsTime.unix().toString();
      runway.date = dayjsTime.format(DATE_FORMAT);
      runway.time = dayjsTime.format(TIME_FORMAT);
      if (typeof runway.active === "undefined") {
        runway.active = true;
      }
    });
    dispatch(setForm({ ...data, user_id: user_id }));

    compareLoadedAndUpdateState(data, serverTime.time);
  }, [compareLoadedAndUpdateState, dispatch, from, user_id]);

  const initFrom = useCallback(() => {
    if (!user_id) return;
    if (request) {
      handleInitializeUpdateForm().then(() => setInitialized(true));
      return;
    }
    if (from) {
      handleInitializeLoadedForm().then(() => setInitialized(true));
      return;
    }
    handleInitializeNewForm().then(() => setInitialized(true));
  }, [
    from,
    request,
    user_id,
    handleInitializeNewForm,
    handleInitializeUpdateForm,
    handleInitializeLoadedForm,
  ]);

  return [initFrom, initialized];
};
export default useFormInitializer;
