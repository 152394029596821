import { useCallback, useEffect, useState } from "react";
import { useInterval } from "@snowtam/ui";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux";

dayjs.extend(utc);

const useErrorMonitor = (runway_id?: number) => {
  const [error, setError] = useState(false);

  const timestamp = useSelector(
    (state: RootState) =>
      state.snowtamFormV2.runways.find((rwy) => rwy.runwayId === runway_id)
        ?.timestamp
  );

  const checkTimeIsAfter = useCallback((): boolean => {
    if (!timestamp) return true;
    return dayjs.utc(timestamp, "X").isAfter(dayjs.utc());
  }, [timestamp]);

  const checkError = useCallback(() => {
    if (checkTimeIsAfter()) {
      setError(true);
    } else {
      setError(false);
    }
  }, [checkTimeIsAfter]);

  useInterval(() => {
    checkError();
  }, 1);

  useEffect(() => {
    checkError();
  }, [checkError]);

  return error;
};

export default useErrorMonitor;
