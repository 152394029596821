import { FC } from "react";
import { BorderedCard } from "./cardStyles";
import CardLeft from "./CardLeft";
import { IconLogin } from "@snowtam/ui";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 767px) {
    margin-top: 1rem;
  }
`;

const Text = styled.p`
  padding: 0;
  margin: 0 0 0.25rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.font1};
  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;
const Profile: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation("settings");
  const user = useSelector((state: RootState) => state.general.user);
  return (
    <BorderedCard>
      <CardLeft
        icon={() => (
          <IconLogin color={theme.colors.active} width={24} height={24} />
        )}
        title={t`profile`}
        caption={t`personal_data`}
      />
      {user ? (
        <Container>
          <Text>{user?.name}</Text>
          <Text>{user?.phone ? user.phone : null}</Text>
        </Container>
      ) : null}
    </BorderedCard>
  );
};

export default Profile;
