const requests = {
  publication_control: "Publication control",
  sending_data: "Sending data",
  operator: "Operator",
  send_at: "Sent at",
  processing_data: "Processing data",
  navigator: "Navigator",
  phone: "Phone",
  snowtam: "SNOWTAM",
  snowtam_number: "SNOWTAM number",
  snowtam_text: "SNOWTAM message",
  published_date: "Published date",
  comment: "Comment",
  no_request: "No acting SNOWTAM",
  decline: "Decline",
  show: "Show",
  request: "Request",
  status_caption: "Status",
  date_time: "Date / Time",
  published_date_short: "Published at",
  request_declined: "Request declined",
  correct_resubmit: "Correct and resubmit",
  upload_to_form: "Upload to form",
  empty: "Requests not found",
  filter: "Sorting",
  filter_mobile: "Sorting",
  show_history: "Show history",
  filter_status: {
    all: "All requests",
    sent: "Sent",
    published: "Published",
    in_progress: "In progress",
    declined: "Declined",
    expired: "Expired",
    canceled: "Canceled",
  },
  filter_date: {
    all: "All time",
    today: "Today",
    week: "Week",
    month: "Month",
    year: "Year",
  },
  status_text: {
    new: "Request sent",
    declined: "Request declined",
    rejected: "Request rejected",
    in_progress: "Request in progress",
    published: "Request published",
    expired: "Request expired",
    canceled: "Request canceled",
  },
  status: {
    new: "Sent",
    declined: "Declined",
    rejected: "Rejected",
    in_progress: "In progress",
    published: "Published",
    expired: "Expired",
    canceled: "Canceled",
  },
  hours_short: "h",
  minutes_short: "min",
  seconds_short: "sec",
  until_expire: "Until expire",
};

export default requests;
