import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const removeRunwayAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<number>
) => {
  state.runways = state.runways.filter(
    (rwy) => rwy.runwayId !== action.payload
  );
  state.friction_form = state.friction_form?.filter(
    (item) => item.runwayId !== action.payload
  );
  state.reduced = state.reduced.filter((item) => item.id !== action.payload);
  state.snowstorm = state.snowstorm.filter(
    (item) => item.id !== action.payload
  );
  state.sand = state.sand.filter((item) => item.id !== action.payload);
  state.treated = state.treated.filter((item) => item.id !== action.payload);
  state.snowbanks_near_runway = state.snowbanks_near_runway.filter(
    (item) => item.id !== action.payload
  );
  state.snowbank_runway_position = state.snowbank_runway_position.filter(
    (item) => item.runwayId !== action.payload
  );
};

export default removeRunwayAction;
