import { FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { DropDown, DropDownItem } from "@snowtam/ui";
import {
  removeSnowbanksTaxiway,
  updateSnowbanksTaxiway,
} from "../../../../redux/snowtamformReducer";
import { DropDownContainer } from "../../styles";
import { useTranslation } from "react-i18next";

const SnowBankItem: FC<{
  item: DropDownItem;
  selected: number[];
  index: number;
}> = ({ item, selected, index }) => {
  const taxiways = useSelector(
    (state: RootState) => state.general.user?.airport.taxiways
  );
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  let items: DropDownItem[] = useMemo(
    () => [
      ...(taxiways?.map((item) => ({ id: item.id, value: item.code })) || []),
    ],
    [taxiways]
  );

  if (!selected || selected.length === 0) {
    items = [
      {
        id: -1,
        value: "ALL",
      },
      ...items,
    ];
  }
  items = [{ id: 0, value: "" }, ...items];
  items = items.filter((item) => !selected.includes(item.id));

  return (
    <DropDownContainer>
      <DropDown
        name={"twy_snowbanks"}
        label={"SNOW BANK"}
        items={items}
        value={item}
        notChosenText={t`not_chosen`}
        onChoose={(value) => {
          if (value.id !== item.id) {
            value.value !== ""
              ? dispatch(updateSnowbanksTaxiway({ data: value, index }))
              : dispatch(removeSnowbanksTaxiway(index));
          }
        }}
      />
    </DropDownContainer>
  );
};
export default SnowBankItem;
