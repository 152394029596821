import { FC, MutableRefObject } from "react";
import styled from "styled-components";
import { InfoPopupWindow, IconForwardArrow } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const MenuList = styled.ul`
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
`;

const MenuItem = styled.li`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  line-height: 1.25rem;
  color: ${(props) => props.theme.colors.font1};
  padding: 14px 16px 14px 34px;
  border-radius: 4px;
  transition: background-color 0.125s ease;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.helper_button};
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;
const MenuPopup: FC<{
  onClose: () => void;
  toggler?: MutableRefObject<any>;
  onSelect: (value: number) => void;
}> = ({ onClose, toggler, onSelect }) => {
  const { t } = useTranslation("helper");

  return (
    <InfoPopupWindow
      title={t`common.information_window`}
      text={
        <>
          <MenuList>
            <MenuItem onClick={() => onSelect(1)}>
              <span>{t`statuscode.title`}</span>
              <IconForwardArrow />
            </MenuItem>
            <MenuItem onClick={() => onSelect(3)}>
              <span>{t`pollution_status.title`}</span>
              <IconForwardArrow />
            </MenuItem>
            <MenuItem onClick={() => onSelect(4)}>
              <span>{t`pollution_depth.title`}</span>
              <IconForwardArrow />
            </MenuItem>
            <MenuItem onClick={() => onSelect(5)}>
              <span>{t`condition.title`}</span>
              <IconForwardArrow />
            </MenuItem>
          </MenuList>
        </>
      }
      onClose={onClose}
      toggler={toggler}
    />
  );
};

export default MenuPopup;
