const helper = {
  adhesion: {
    title: "Коэффициент сцепления",
    line1:
      "Замеренный коэффициент сцепления. Если эта информация сообщается,\n" +
      "            указывается замеренный коэффициент сцепления и устройство,\n" +
      "            посредством которого производилось измерение сцепления.",
    line2: "Только латинские символы и цифры",
  },
  aprons: {
    caption:
      "Значение перронов вводится через запятую, для выбора всех перронов введите значение ALL",
    title: "Состояние перронов",
    line1: "Заполняется при наличии информации о плохих условиях на перроне.",
    line2:
      "Выберите из списка конкретный перрон с плохими условиями или все перроны (выбрав графу ALL)",
  },
  snowbanks_twy: {
    caption:
      "Значение РД вводится через запятую, для выбора всех перронов введите значение ALL",
    title: "Сугробы на РД",
    line1:
      "Выберите из списка конкретную РД на которой есть сугробы или все РД (выбрав графу ALL)",
  },
  twy_status: {
    caption:
      " Значение РД вводится через запятую, для выбора всех РД введите значение ALL",
    title: "Состояние РД",
    line1:
      "Заполняется при наличии информации о плохих условиях на рулежной дорожке.",
    line2:
      "Выберите из списка конкретную РД с плохими условиями или все РД (выбрав графу ALL)",
  },
  dimensions: {
    information_window: "Окно Информации",
    rwy_width: "Ширина ВПП",
    rwy_reduced: "Укороченная ВПП",
    reduced_text:
      "Указывается доступная длина ВПП в метрах. Введите значение с помощью управляющих элементов",
    width_text:
      "Указывается ширина в метрах (не указывая единицу измерения). Вы можете отредактировать значение с помощью управляющих элементов.",
  },
  common: {
    close: "Закрыть",
    annotation: "Примечания",
    information_window: "Окно Информации",
  },
  meteo: {
    information_window: "Окно Информации",
    drifting_snow: "Метель",
    drifting_snow_title: "Метель на ВПП",
    loose_sand: "Песок",
    adj_snowbanks: "Сугробы у ВПП",
    treated: "Реагенты",
    loose_sand_text: "Отметьте если на ВПП есть песок.",
    adj_snowbanks_text: "Отметьте если возле ВПП есть сугробы.",
    treated_text: "Отметьте если ВПП обработана химическими реагентами.",
    drifting_snow_text: "Отметьте если на ВПП метель.",
    drifting_snow_annotation:
      "Примечание 1.— Метель – это перенос снежных масс над поверхностью земли порывистым и сильным ветром (определение Всемирной метеорологической организации).",
  },
  snowbanks_rwy: {
    title: "Сугробы на ВПП",
    line1:
      "Указывается положение (L - слева, R - справа, LR - по обеим сторонам) и расстояние от осевой в метрах",
  },
  statuscode: {
    title: "Код состояния ВПП",
    line1:
      "Выберите значение от 0 до 6. Код состояния ВПП определяется при оценке состояния поверхности ВПП, в соответствии с положениями Правил аэронавигационного обслуживания аэродромов и согласно Таблице оценки состояния ВПП (RCAM).",
    snow_rcam: "Смотреть таблицу (RCAM)",
    rcam: "Таблица (RCAM)",
  },
  condition: {
    title: "Состояние ВПП",
    line1: "Выберите значение из выпадающего списка о текущем состоянии ВПП.",
    note: "Примечание 1. — Если информация о состоянии ВПП не сообщается, применительно к каждой соответствующей трети ВПП ставится сокращение «NR» (ИНФОРМАЦИЯ ОТСУТСТВУЕТ).",
  },
  pollution_depth: {
    title: "Глубина загрязнения ВПП",
    line1:
      "Глубина слоя рыхлого загрязнителя по каждой трети ВПП. При наличии такой информации ее следует указать в миллиметрах по каждой трети ВПП",
    note1:
      "Примечание 1.— Данная информация приводится только для нижеперечисленных типов загрязнителей:",
    note2: "— стоячая вода",
    note3: "— слякоть",
    note4: "— мокрый снег",
    note5: "— сухой снег",
    note6:
      "Примечание 2.— Если информация о метеоусловиях на соответствующей трети ВПП отсутствует или не сообщается, ставятся две буквы «NR».",
    note7:
      "Примечание 3.— Буквы NR ставятся также в тех случаях, когда глубина слоя загрязнителя меньше тех минимальных значений, по достижении которых следует сообщать информацию (как указано выше), или когда на данном участке ВПП сухая и т.п.",
    note8:
      "Примечание 4. – Если речь не идет о СТОЯЧЕЙ ВОДЕ, СЛЯКОТИ, МОКРОМ СНЕГЕ или СУХОМ СНЕГЕ (STANDING WATER, SLUSH, WET SNOW, DRY SNOW), информация о глубине не сообщается. В этих случаях ставятся буквы /NR/.",
  },
  pollution_status: {
    title: "Уровень загрязнения ВПП",
    line1: "Выберите строку из выпадающего списка",
    line2: "NR - информация отсутствует",
    line3: "DRY – сухая ВПП, если площадь загрязненной поверхности меньше 10%",
    line4: "25 – площадь загрязнения от 10 до 25%",
    line5: "50 – площадь загрязнения от 26 до 50%",
    line6: "75 – площадь загрязнения от 51 до 75%",
    line7: "100 – площадь загрязнения от 76 до 100%",
    note1:
      "Примечание 1.— Данная информация приводится только в тех случаях, когда указано, что состояние каждой трети ВПП (позиция D) не равно 6 и по каждой трети ВПП (позиция G) указывается, что она не сухая (DRY).",
    note2:
      "Примечание 2.— Если информация о состоянии ВПП не сообщается, применительно к каждой соответствующей трети ВПП ставится сокращение  «NR» (ИНФОРМАЦИЯ ОТСУТСТВУЕТ).",
    note3:
      "Примечание 3. — Если ВПП сухая (DRY) или покрытие загрязнителем менее 10 %, применительно к позиции E ставится сокращение «NR».",
  },
  rcam: {
    maintitle: "Таблица оценки состояния ВПП (RCAM)",
    subtitle1: "Критерии оценки",
    subtitle2: "Критерии снижения оценки",
    rwy_status_code: "Код состояния ВПП",
    rwy_descr: "Описание поверхности ВПП",
    rwy_comment:
      "Комментарии в отношении снижения скорости самолета или регулировки направления",
    pilot_report: "Отчет пилота о сцеплении на ВПП",
    dry_rwy: "СУХАЯ ВПП",
    frost: "ИНЕЙ",
    wet: "МОКРАЯ ВПП (Поверхность ВПП покрыта заметной на глаз влагой или водой, толщина слоя которой составляет до 3 мм включительно)",
    under3mm: "До 3 мм включительно",
    slush: "СЛЯКОТЬ",
    drysnow: "СУХОЙ СНЕГ",
    wetsnow: "МОКРЫЙ СНЕГ",
    good: "ХОРОШЕЕ",
    comment1:
      "Нормальное снижение скорости при торможении для приложенного усилия торможения колес И нормальная регулировка направления",
    temp15: "Температура воздуха −15ºC и ниже",
    comp_snow: "УПЛОТНЕННЫЙ СНЕГ",
    comment2:
      "Уровень снижения скорости при торможении ИЛИ уровень регулировки управления в диапазоне от хорошего до среднего",
    fromgoodtomiddle: "В ДИАПАЗОНЕ ОТ ХОРОШЕГО ДО СРЕДНЕГО",
    wet_rwy: "МОКРАЯ («скользкая и мокрая» ВПП)",
    dry_wet_snow:
      "СУХОЙ СНЕГ или МОКРЫЙ СНЕГ (любой глубины) ПОВЕРХ УПЛОТНЕННОГО СНЕГА",
    more3mm: "Глубина более 3 мм",
    tempmore15: "Температура воздуха выше −15ºC",
    comment3:
      "Уровень снижения скорости при торможении для приложенного усилия торможения колес заметно снижен ИЛИ уровень регулировки управления заметно снижен.",
    medium: "СРЕДНЕЕ",
    more3mmwater: "Слой воды или слякоти глубиной более 3 мм",
    std_water: "СТОЯЧАЯ ВОДА",
    comment4:
      "Уровень снижения скорости при торможении ИЛИ уровень регулировки управления в диапазоне от среднего до плохого.",
    frommedtobad: "В ДИАПАЗОНЕ ОТ СРЕДНЕГО ДО ПЛОХОГО",
    ice: "ЛЕД",
    comment5:
      "Уровень снижения скорости при торможении для приложенного усилия торможения колес ИЛИ уровень регулировки управления существенно снижен.",
    bad: "ПЛОХОЕ",
    wetice: "МОКРЫЙ ЛЕД",
    water_comp_snow: "ВОДА ПОВЕРХ УПЛОТНЕННОГО СНЕГА",
    dry_wet_comp_snow: "СУХОЙ СНЕГ или МОКРЫЙ СНЕГ ПОВЕРХ ЛЬДА",
    comment6:
      "Уровень снижения скорости при торможении для приложенного усилия торможения колес в диапазоне от минимального до нулевого ИЛИ уровень регулировки управления ненадежный.",
    verybad: "ОЧЕНЬ ПЛОХОЕ",
    very: "ОЧЕНЬ",
    mobile: {
      mediumtopoor1: "В ДИАПАЗОНЕ",
      mediumtopoor2: "ОТ СРЕДНЕГО",
      mediumtopoor3: "ДО ПЛОХОГО",
      mediumtogood1: "В ДИАПАЗОНЕ",
      mediumtogood2: "ОТ ХОРОШЕГО",
      mediumtogood3: "ДО СРЕДНЕГО",
    },
    annotation: {
      header: "Примечания",
      header1: "Примечание 1.",
      header2: "Примечание 2.",
      annotation1:
        "RCAM — это документ, представляющий собой инструмент, применяемый для\n" +
        "          оценки состояния поверхности ВПП. Указанный документ нельзя\n" +
        "          рассматривать в отрыве от других материалов, и его следует применять в\n" +
        "          соответствии с надлежащими процедурами оценки, включая критерии\n" +
        "          снижения или повышения оценки, подробно изложенные в документе ИКАО\n" +
        "          DOC 9881 Правила аэронавигационного обслуживания — Аэродром\n" +
        "          (PANS-Aerodrome), часть II, глава 1.",
      annotation2:
        " — Государства, которые придерживаются правил Европейского агентства по\n" +
        "          безопасности полетов (EASA), помимо всего прочего, пользуются понятием\n" +
        "          «ВПП, СПЕЦИАЛЬНО ПОДГОТОВЛЕННАЯ ДЛЯ ЗИМЫ» применительно к коду",
    },
  },
};

export default helper;
