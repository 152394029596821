import { FC, useMemo } from "react";
import { DropDown, DropDownItem } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { addSnowbanksTaxiway } from "../../../../redux/snowtamformReducer";
import { DropDownContainer } from "../../styles";
import { useTranslation } from "react-i18next";

const AddSnowBank: FC = () => {
  const taxiways = useSelector(
    (state: RootState) => state.general.user?.airport.taxiways
  );
  const selected = useSelector(
    (state: RootState) => state.snowtamForm.snowbanks_taxiway
  );
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const selected_ids = Object.values(selected).map((item) => item.id);
  let items: DropDownItem[] = useMemo(
    () => [
      ...(taxiways?.map((item) => ({ id: item.id, value: item.code })) || []),
    ],
    [taxiways]
  );

  if (!selected || selected_ids.length === 0) {
    items = [
      {
        id: -1,
        value: "ALL",
      },
      ...items,
    ];
  }
  items = [{ id: 0, value: "" }, ...items];

  items = items.filter((item) => !selected_ids.includes(item.id));

  if (
    Object.values(selected).filter((item) => item.value === "ALL").length > 0 ||
    items.length === 1
  ) {
    return null;
  }
  return (
    <DropDownContainer>
      <DropDown
        name={"twy_snowbanks"}
        label={"SNOW BANK"}
        items={items}
        notChosenText={t`not_chosen`}
        onChoose={(val) =>
          val.id !== 0 ? dispatch(addSnowbanksTaxiway(val)) : null
        }
      />
    </DropDownContainer>
  );
};

export default AddSnowBank;
