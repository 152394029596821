import dayjs, { Dayjs } from "dayjs";

const parseTimestamp = (timestamp: Dayjs) => {
  const now = dayjs().utc();
  if (timestamp.isBefore(now.subtract(16, "hours"))) {
    return timestamp.add(1, "day");
  } else if (timestamp.isAfter(now.add(16, "hours"))) {
    return timestamp.subtract(1, "day");
  } else {
    return timestamp;
  }
};

export default parseTimestamp;
