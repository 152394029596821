import { FC, useState } from "react";
import { RequestModel } from "../../../@types/models";
import Status from "./Status";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { Collapse, IconDiagram, IconExpand } from "@snowtam/ui";
import Data from "./Data";
import History from "./History";

export const DataRow = styled.tr`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: ${(props) => props.theme.colors.separator};
  }

  @media (max-width: 767px) {
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    &:last-child:after {
      content: none;
    }
  }
`;

const DataWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px 24px 24px 24px;
  position: relative;
  @media (max-width: 1199px) {
    padding: 12px 24px 24px 24px;
  }
  @media (max-width: 767px) {
    padding: 4px 16px 16px 16px;
  }
`;
const Container = styled.div<{ noLastBorder?: boolean }>`
  width: 100%;
  ${({ noLastBorder }) =>
    noLastBorder
      ? css`
          ${DataRow}:last-child:after {
            content: none;
          }
        `
      : null}
`;

const Title = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};
  align-items: center;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
  }

  @media (max-width: 767px) {
    font-size: 1rem;
    padding: 16px;
  }
`;

const ShowMore = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: height 0.1s linear, padding 0.1s linear;
  overflow: hidden;
  height: ${({ visible }) => (visible ? "calc(1.5rem + 12px)" : "0")};
  padding: ${({ visible }) => (visible ? "12px 0" : "0")};
`;
const ShowMoreHr = styled.hr`
  padding: 0;
  margin: auto 0;
  border: none;
  background: ${({ theme }) => theme.colors.separator};
  height: 1px;
  width: 100%;
`;

const ShowMoreSpan = styled.span`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.active};
  cursor: pointer;
  white-space: nowrap;

  svg {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.active};
  }
`;
const StatusContainer = styled(Status)`
  margin: 0 0 0 auto;
`;

interface RequestCardProps {
  data: RequestModel;
  withDecline?: boolean;
  onDecline?: () => void;
  noLastBorder?: boolean;
  noTitle?: boolean;
}

const RequestCard: FC<RequestCardProps> = ({ data, noLastBorder }) => {
  const { t } = useTranslation("requests");
  const [history, setHistory] = useState(false);
  return (
    <Container noLastBorder={noLastBorder}>
      <Title>
        <IconDiagram style={{ marginRight: "16px" }} />
        {t`publication_control`}
        <StatusContainer status={data.status} />
      </Title>
      <DataWrapper>
        {data.versions && data.versions.length > 0 ? (
          <ShowMore onClick={() => setHistory(true)} visible={!history}>
            <ShowMoreHr />
            <ShowMoreSpan>
              {t`show_history`}
              <IconExpand />
            </ShowMoreSpan>
            <ShowMoreHr />
          </ShowMore>
        ) : null}
        <Collapse open={history} duration={300}>
          <History versions={data.versions} />
        </Collapse>
        <Data data={data} />
      </DataWrapper>
    </Container>
  );
};

export default RequestCard;
