import { Draft } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const deleteAllRunwaysActiveAction = (state: Draft<SnowtamFormStateTypeV2>) => {
  state.runways.forEach((rwy) => {
    delete rwy.active;
  });
};

export default deleteAllRunwaysActiveAction;
