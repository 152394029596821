import { FC } from "react";
import styled, { keyframes } from "styled-components";
import Status from "./Status";
import Pollution from "./Pollution";
import PollutionDepth from "./PollutionDepth";
import State from "./State";
import {GroupTitle} from "@snowtam/ui";
import RunwaysHelper from "../../../InfoDialogs/RunwaysHelper";
import { useTranslation } from "react-i18next";

const FadeIn = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;
const Container = styled.div<{ active: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  animation: ${FadeIn} 0.3s ease-out;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: -8px;

  & > div:first-child {
    margin-right: 24px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    & > div:first-child {
      margin-right: 0;
    }
  }
`;

const PollutionContainer = styled.div`
  padding: 8px 0;
`;

const StateContainer = styled.div`
  margin: 0 0 -8px 0;
`;
const ThirdForm: FC<IThirdForm> = ({ active, third, runwayId }) => {
  const { t } = useTranslation("data_form");
  const name = () => {
    switch (third) {
      case 1:
        return t`thirds.first_title`;
      case 2:
        return t`thirds.second_title`;
      case 3:
        return t`thirds.third_title`;
    }
  };
  return (
    <Container active={active}>
      <GroupTitle label={name()} endAdornmentButton={<RunwaysHelper />} />
      <Row>
        <Status third={third} runwayId={runwayId} />
        <PollutionDepth third={third} runwayId={runwayId} />
      </Row>
      <PollutionContainer>
        <Pollution third={third} runwayId={runwayId} />
      </PollutionContainer>

      <StateContainer>
        <State third={third} runwayId={runwayId} />
      </StateContainer>
    </Container>
  );
};

interface IThirdForm {
  active: boolean;
  third: 1 | 2 | 3;
  runwayId: number;
}

export default ThirdForm;
