import { FC, memo } from "react";
import styled from "styled-components";
import { IconAirplane } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;

  svg {
    width: 64px;
    height: 64px;
  }
  @media (max-width: 1199px) {
    svg {
      width: 48px;
      height: 48px;
    }
  }
  @media (max-width: 767px) {
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const Title = styled.h1`
  padding: 0;
  margin: 8px 0;
  text-align: center;
  font-size: 2.625rem;
  line-height: 2.625rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font1};
  @media (max-width: 1199px) {
    font-size: 2rem;
    line-height: 2rem;
  }
  @media (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;
const Caption = styled.p`
  padding: 0;
  margin: 8px 0;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.font3};
  @media (max-width: 1199px) {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
`;

const NoRunwaysSelected: FC = () => {
  const { t } = useTranslation("data_form");

  return (
    <Container>
      <IconWrapper>
        <IconAirplane />
      </IconWrapper>
      <Title>{t`no_rwy_selected_title`}</Title>
      <Caption>{t`no_rwy_selected_caption`}</Caption>
    </Container>
  );
};

export default memo(NoRunwaysSelected);
