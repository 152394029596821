import { FC } from "react";
import { DateTimeInput } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setTime } from "../../../../redux/snowtamform/snowtamformv2Reducer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useErrorMonitor from "./hooks/useErrorMonitor";

const TimeContainer = styled.div`
  display: flex;
  min-width: 170px;
  width: 100%;
  box-sizing: border-box;
  @media (min-width: 768px) {
    min-width: 354px;
  }
`;
const Time: FC<{ runwayId: number }> = ({ runwayId }) => {
  const time = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamFormV2.runways[index]?.time;
  });
  const date = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamFormV2.runways[index]?.date;
  });
  const dispatch = useDispatch();

  const error = useErrorMonitor(runwayId);

  const { t } = useTranslation("common");

  return (
    <TimeContainer>
      <input type="date" style={{ display: "none" }} defaultValue={date} />

      <DateTimeInput
        name="time"
        type="time"
        value={time ?? ""}
        onChange={(e) => dispatch(setTime({ runwayId, value: e.target.value }))}
        label={t`time`}
        style={{ width: "100%" }}
        error={error}
      />
    </TimeContainer>
  );
};

export default Time;
