import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import initializeFormAction from "./actions/initializeForm.action";
import appendRunwayAction from "./actions/appendRunway.action";
import setFrictionFormValueAction from "./actions/setFrictionFormValue.action";
import setServerTimeAction from "./actions/setServerTime.action";
import setRWYCCAction from "./actions/setRWYCC.action";
import setTimeAction from "./actions/setTime.action";
import setRunwayStateAction from "./actions/setRunwayState.action";
import setFrictionFormDeviceAction from "./actions/setFrictionFormDevice.action";
import setPollutionAction from "./actions/setPollution.action";
import setPollutionDepthAction from "./actions/setPollutionDepth.action";
import setThirdWidthAction from "./actions/setThirdWidth.action";
import setReducedAction from "./actions/setReduced.action";
import setSnowstormAction from "./actions/setSnowstorm.action";
import setSandAction from "./actions/setSand.action";
import setTreatedAction from "./actions/setTreated.action";
import setSnowbankRunwayLengthAction from "./actions/setSnowbankRunwayLength.action";
import setSnowbankRunwayPositionAction from "./actions/setSnowbankRunwayPosition.action";
import setCommentAction from "./actions/setComment.action";
import updateApronStatusAction from "./actions/updateApronStatus.action";
import addApronStatusAction from "./actions/addApronStatus.action";
import setFormAction from "./actions/setForm.action";
import setSnowbanksTwyRawAction from "./actions/setSnowbanksTwyRaw.action";
import setActiveAction from "./actions/setActive.action";
import switchActiveAction from "./actions/switchActive.action";
import setAllRunwaysActiveAction from "./actions/setAllRunwaysActive.action";
import updateTaxiwayStatusAction from "./actions/updateTaxiwayStatus.action";
import addTaxiwayStatusAction from "./actions/addTaxiwayStatus.action";
import setSnowbanksNearRunwayAction from "./actions/setSnowbanksNearRunway.action";
import updateSnowbanksTaxiwayAction from "./actions/updateSnowbanksTaxiway.action";
import addSnowbanksTaxiwayAction from "./actions/addSnowbanksTaxiway.action";
import setTaxiwayStatusRawAction from "./actions/setTaxiwayStatusRaw.action";
import setApronStatusRawAction from "./actions/setApronStatusRaw.action";
import deleteAllRunwaysActiveAction from "./actions/deleteAllRunwaysActive.action";
import setLowTemperatureAction from "./actions/setLowTemperature.action";
import setPauseAction from "./actions/setPause.action";
import setContinueAction from "./actions/setContinue.action";
import removeRunwayAction from "./actions/removeRunway.action";
import removeTaxiwayStatusByIdAction from "./actions/removeTaxiwayStatusById.action";
import removeApronStatusByIdAction from "./actions/removeApronStatusById.action";
import removeSnowbanksTaxiwayByIdAction from "./actions/removeSnowbanksTaxiwayById.action";
import clearApronStatusAction from "./actions/clearApronStatus.action";
import clearTaxiwayStatusAction from "./actions/clearTaxiwayStatus.action";
import clearSnowbanksTaxiwayAction from "./actions/clearSnowbanksTaxiway.action";

export const snowtamformv2Slice = createSlice({
  name: "snowtamformv2",
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setForm: setFormAction,
    removeSnowbanksTaxiwayById: removeSnowbanksTaxiwayByIdAction,
    setSnowbanksTwyRaw: setSnowbanksTwyRawAction,
    setActive: setActiveAction,
    switchActive: switchActiveAction,
    setAllRunwaysActive: setAllRunwaysActiveAction,
    setApronStatusRaw: setApronStatusRawAction,
    setTaxiwayStatusRaw: setTaxiwayStatusRawAction,
    addSnowbanksTaxiway: addSnowbanksTaxiwayAction,
    updateSnowbanksTaxiway: updateSnowbanksTaxiwayAction,
    setSnowbanksNearRunway: setSnowbanksNearRunwayAction,
    removeTaxiwayStatusById: removeTaxiwayStatusByIdAction,
    addTaxiwayStatus: addTaxiwayStatusAction,
    updateTaxiwayStatus: updateTaxiwayStatusAction,
    removeApronStatusById: removeApronStatusByIdAction,
    clearApronStatus: clearApronStatusAction,
    clearTaxiwayStatus: clearTaxiwayStatusAction,
    clearSnowbanksTaxiway: clearSnowbanksTaxiwayAction,
    addApronStatus: addApronStatusAction,
    updateApronStatus: updateApronStatusAction,
    setComment: setCommentAction,
    setSnowbankRunwayPosition: setSnowbankRunwayPositionAction,
    setSnowbankRunwayLength: setSnowbankRunwayLengthAction,
    setTreated: setTreatedAction,
    setSand: setSandAction,
    setSnowstorm: setSnowstormAction,
    setReduced: setReducedAction,
    setLowTemperature: setLowTemperatureAction,
    setThirdWidth: setThirdWidthAction,
    setPollutionDepth: setPollutionDepthAction,
    setPollution: setPollutionAction,
    setFrictionFormValue: setFrictionFormValueAction,
    setFrictionFormDevice: setFrictionFormDeviceAction,
    setRunwayState: setRunwayStateAction,
    setTime: setTimeAction,
    setRWYCC: setRWYCCAction,
    setServerTime: setServerTimeAction,
    setPause: setPauseAction,
    setContinue: setContinueAction,
    appendRunway: appendRunwayAction,
    removeRunway: removeRunwayAction,
    initializeForm: initializeFormAction,
    // For experimental flag only!
    deleteAllRunwaysActive: deleteAllRunwaysActiveAction,
  },
});

export const {
  addSnowbanksTaxiway,
  updateSnowbanksTaxiway,
  setSnowbanksNearRunway,
  addTaxiwayStatus,
  updateTaxiwayStatus,
  removeTaxiwayStatusById,
  addApronStatus,
  updateApronStatus,
  removeApronStatusById,
  setComment,
  setSnowbankRunwayPosition,
  setSnowbankRunwayLength,
  setTreated,
  setSand,
  setSnowstorm,
  setReduced,
  setThirdWidth,
  setPollutionDepth,
  setPollution,
  setRunwayState,
  setTime,
  setInitialState,
  setPause,
  setContinue,
  initializeForm,
  setSnowbanksTwyRaw,
  setApronStatusRaw,
  setTaxiwayStatusRaw,
  setLowTemperature,
  setForm,
  setFrictionFormValue,
  setFrictionFormDevice,
  setServerTime,
  setRWYCC,
  setActive,
  switchActive,
  setAllRunwaysActive,
  deleteAllRunwaysActive,
  appendRunway,
  removeRunway,
  removeSnowbanksTaxiwayById,
  clearApronStatus,
  clearTaxiwayStatus,
  clearSnowbanksTaxiway,
} = snowtamformv2Slice.actions;

export default snowtamformv2Slice.reducer;
