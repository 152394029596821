import { FC, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { DialogYesNo, IconCancelRequest, IconAddRequest } from "@snowtam/ui";
import { RequestStatus } from "../../types/status";
import Requests from "../../api/requests";

const Button = styled.button<{ active?: boolean }>`
  display: flex;
  padding: 0.75rem;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  outline: none;
  background: ${({ active, theme }) =>
    active ? theme.colors.active : "transparent"};
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.active : theme.colors.error};
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ active, theme }) =>
    active ? theme.colors.font_active : theme.colors.error};
  border-radius: 4px;
  width: 100%;
  max-width: 327px;

  svg {
    color: ${({ active, theme }) =>
      active ? theme.colors.font_active : theme.colors.error};
    margin-right: 16px;
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const ErrorSpan = styled.span`
  text-align: center;
`;
const inProgressStatuses = [
  RequestStatus.InProgress,
  RequestStatus.Sent,
  RequestStatus.NotSent,
];
const CreateButton: FC<{ refetch: () => void }> = ({ refetch }) => {
  const active = useSelector((state: RootState) => state.app.canAdd);
  const requestId = useSelector((state: RootState) => state.app.last_id);
  const last_status = useSelector((state: RootState) => state.app.last_status);

  const [approve, setApprove] = useState(false);
  const [in_progress, setInProgress] = useState(false);
  const history = useHistory();
  const { t } = useTranslation(["menu", "common", "requests"]);

  const handleDecline = () => {
    if (!requestId) return;
    Requests.declineRequest(requestId).then(() => {
      setApprove(false);
      refetch();
    });
  };

  const handleButtonClick = () => {
    if (active) {
      history.push("/form");
      return;
    }
    if (last_status && inProgressStatuses.includes(last_status)) {
      setInProgress(true);
      return;
    }
    setApprove(true);
  };

  return (
    <>
      <Button
        type="button"
        active={active}
        onClick={handleButtonClick}
        name="create_request"
      >
        {active ? <IconAddRequest /> : <IconCancelRequest />}
        {active ? t`create` : t`cancel`}
      </Button>
      {approve ? (
        <DialogYesNo
          title={`${t`requests:decline`}?`}
          onApply={() => handleDecline()}
          onClose={() => setApprove(false)}
          yesText={t`common:yes`}
          noText={t`common:no`}
        />
      ) : null}
      {in_progress ? (
        <DialogYesNo
          title={`${t`common:errors.warning`}`}
          onApply={() => setInProgress(false)}
          onClose={() => setInProgress(false)}
          yesText={"OK"}
        >
          <ErrorSpan>{`${t`common:errors.cant_decline`}`}</ErrorSpan>
          <ErrorSpan>{`${t`common:errors.request_inprogress`}`}</ErrorSpan>
        </DialogYesNo>
      ) : null}
    </>
  );
};

export default CreateButton;
