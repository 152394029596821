import { FC } from "react";
import { Body, BoldSpan, Container, TextBlock } from "./labelStyles";
import { useTranslation } from "react-i18next";

type NavigatorProps = {
  navigator: string;
  phone: string;
};

const Navigator: FC<NavigatorProps> = ({ navigator, phone }) => {
  const { t } = useTranslation("requests");
  return (
    <Container>
      <Body>
        <TextBlock>
          <span style={{ marginRight: "16px" }}>{t`navigator`}</span>
          <BoldSpan>{navigator}</BoldSpan>
        </TextBlock>
        {phone ? (
          <TextBlock>
            <span style={{ marginRight: "16px" }}>{t`phone`}</span>
            <BoldSpan>{phone}</BoldSpan>
          </TextBlock>
        ) : null}
      </Body>
    </Container>
  );
};

export default Navigator;
