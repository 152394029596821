import styled from "styled-components";

export const Row = styled.tr`
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};
`;

export const Column = styled.td`
  align-items: center;
  padding: 12px 20px;

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 16px 20px;
    &:first-child {
      padding-left: 16px;
    }
    &:last-child {
      padding-right: 16px;
    }
  }
`;
