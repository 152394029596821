import { FC, MutableRefObject, useState } from "react";
import AddModal from "../../../AddModal";
import { CommonButton, MoreParagraph, InfoPopupWindow } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const SnowPopup: FC<{
  onClose: () => void;
  toggler?: MutableRefObject<any>;
}> = ({ onClose, toggler }) => {
  const [more, setMore] = useState(false);
  const { t } = useTranslation("helper");
  return (
    <>
      <InfoPopupWindow
        title={t`meteo.drifting_snow_title`}
        text={<>{t`meteo.drifting_snow_text`}</>}
        more
        onMoreClick={() => setMore(true)}
        moreText={t`common.annotation`}
        onClose={onClose}
        toggler={toggler}
      />
      {more ? (
        <AddModal title={t`common.annotation`} onClose={() => setMore(false)}>
          <MoreParagraph>{t`meteo.drifting_snow_annotation`}</MoreParagraph>
          <CommonButton
            label={t`common.close`}
            onClick={() => setMore(false)}
          />
        </AddModal>
      ) : null}
    </>
  );
};

export default SnowPopup;
