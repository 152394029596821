import { Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  SnowbanksTaxiwayFormType,
  SnowtamFormStateTypeV2,
} from "../../../../@types/form";

const updateSnowbanksTaxiwayAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{ data: SnowbanksTaxiwayFormType; prevId: number }>
) => {
  if (action.payload.data.value === "ALL") {
    state.snowbanks_taxiway = [action.payload.data];
    return;
  }

  const itemIndex = state.snowbanks_taxiway.findIndex(
    (item) => item.id === action.payload.prevId
  );

  state.snowbanks_taxiway[itemIndex] = action.payload.data;

  state.pause = true;
};

export default updateSnowbanksTaxiwayAction;
