import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DropDownItem } from "@snowtam/ui";
import { RootState } from "./index";
import Requests, { ListSearchOptions } from "../api/requests";
import { StateStatusType } from "./types";

const initialState: HistoryFilterType = {
  state: StateStatusType.Idle,
  totalPages: 0,
  filter: {
    page: 1,
  },
};

export type HistoryFilterType = {
  state: StateStatusType;
  totalPages: number;
  filter: {
    status?: DropDownItem;
    date?: DropDownItem;
    page: number;
  };
  data?: any;
};

export const fetchHistory = createAsyncThunk(
  "historyFilter/fetchHistory",
  async (arg: void, { getState }) => {
    const state = getState() as RootState;
    const data: ListSearchOptions = {
      page: state.historyFilter.filter.page || 1,
      status: state.historyFilter.filter.status?.data,
      date: state.historyFilter.filter.date?.data,
    };
    return Requests.getList(data);
  }
);

export const historyFilterSlice = createSlice({
  name: "historyFilter",
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setStatus: (state, action: PayloadAction<DropDownItem>) => {
      state.filter.page = 1;
      state.filter.status = action.payload;
    },
    setDate: (state, action: PayloadAction<DropDownItem>) => {
      state.filter.page = 1;
      state.filter.date = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filter.page = action.payload;
    },
    setState: (
      state,
      action: PayloadAction<Omit<HistoryFilterType, "page">>
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistory.pending, (state) => {
        state.state = StateStatusType.Loading;
      })
      .addCase(fetchHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = action.payload.data.requests;
        state.totalPages = action.payload.data.meta.totalPages;
        state.state = StateStatusType.Succeeded;
      });
  },
});

export const { setStatus, setDate, setState, setPage, setInitialState } =
  historyFilterSlice.actions;

export default historyFilterSlice.reducer;
