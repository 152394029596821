import { FC } from "react";
import  { DropDown, DropDownItem } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { setStatusCode } from "../../../../../redux/snowtamformReducer";
import { useTranslation } from "react-i18next";

const values: DropDownItem[] = [
  {
    id: 0,
    value: "0",
  },
  {
    id: 1,
    value: "1",
  },
  {
    id: 2,
    value: "2",
  },
  {
    id: 3,
    value: "3",
  },
  {
    id: 4,
    value: "4",
  },
  {
    id: 5,
    value: "5",
  },
  {
    id: 6,
    value: "6",
  },
];

const Status: FC<{ third: 1 | 2 | 3; runwayId: number }> = ({
  third,
  runwayId,
}) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamForm.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamForm.runways[index]?.thirds[third]?.status_code;
  });
  const dispatch = useDispatch();
  const { t } = useTranslation(["data_form","common"]);

  return (
    <DropDown
      name="third_status"
      label={t`data_form:thirds.condition_code`}
      items={values}
      value={value}
      notChosenText={t`common:not_chosen`}
      onChoose={(val) =>
        dispatch(setStatusCode({ runwayId, third, value: val }))
      }
    />
  );
};

export default Status;
