import { FC } from "react";
import styled, { useTheme } from "styled-components";
import ColorButton from "../ClearButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import RunwaysSelector from "./RunwaysSelector";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconAirport, IconBackArrow } from "@snowtam/ui";

const DesktopTitleHeader = styled.h3`
  margin: 0;
  padding: 12px 0 12px 24px;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
  }

  @media (max-width: 767px) {
    display: none;
    font-size: 1rem;
    border-right: none;
    padding: 12px 16px;
    svg {
      margin-right: 0;
    }
  }
`;

const TitleContainer = styled.div`
  padding: 12px 0;
  box-shadow: ${({ theme }) => theme.shadows.header_main};
  background: ${(props) => props.theme.colors.blocks};
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1110;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: 767px) {
    padding: 4px 0;
  }
`;

const AirportSpan = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;

const BackButton = styled.button`
  border: none;
  outline: none;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.active};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  border-right: 1px solid ${(props) => props.theme.colors.separator};
  background: rgba(0, 0, 0, 0);

  svg {
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    border: none;
    padding: 12px 16px;
    svg {
      margin-right: 0;
    }
  }
  @media (min-width: 1200px) {
    display: none;
  }
`;

const ButtonText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;
const Title: FC<{ onSetRunway: (id: number) => void }> = ({ onSetRunway }) => {
  const pause = useSelector((state: RootState) => state.snowtamForm.pause);
  const airport = useSelector(
    (state: RootState) => state.general.user?.airport
  );
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation("common");
  return (
    <TitleContainer>
      <BackButton onClick={() => history.push("/menu")}>
        <IconBackArrow color={theme.colors.active} />
        <ButtonText>{t`back`}</ButtonText>
      </BackButton>
      <DesktopTitleHeader>
        <IconAirport />
        <AirportSpan>{airport?.code}</AirportSpan>
      </DesktopTitleHeader>
      <RunwaysSelector onSelect={(v) => onSetRunway(v)} />
      {pause ? <ColorButton /> : null}
    </TitleContainer>
  );
};
export default Title;
