import { FC, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { DialogYesNo, IconCancelRequest } from "@snowtam/ui";
import Requests from "../../api/requests";

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-top: 16px;
  @media (max-width: 1199px) {
    display: none;
    margin: 16px auto 0 auto;
  }
`;

const Button = styled.button`
  display: flex;
  padding: 0.5rem 40px;
  margin: 0;
  justify-content: center;
  align-items: center;
  outline: none;
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.error};
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.error};
  border-radius: 4px;

  svg {
    color: ${(props) => props.theme.colors.error};
    margin-right: 16px;
    width: 1.5rem;
    height: 1.5rem;
  }
`;

interface DeclineButtonProps {
  onDecline?: () => void;
}

const DeclineButton: FC<DeclineButtonProps> = ({ onDecline }) => {
  const requestId = useSelector((state: RootState) => state.app.last_id);

  const [approve, setApprove] = useState(false);

  const { t } = useTranslation("requests");

  const handleDecline = () => {
    if (!requestId) return;
    Requests.declineRequest(requestId).then(() =>
      onDecline ? onDecline() : null
    );
  };
  return (
    <Container>
      <Button type="button" onClick={() => setApprove(true)} name="decline">
        <IconCancelRequest />
        {t`decline`}
      </Button>
      {approve ? (
        <DialogYesNo
          title={`${t`requests:decline`}?`}
          onApply={() => handleDecline()}
          onClose={() => setApprove(false)}
          yesText={t`common:yes`}
          noText={t`common:no`}
        />
      ) : null}
    </Container>
  );
};
export default DeclineButton;
