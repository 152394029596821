import styled from "styled-components";

export const DropDownContainer = styled.div`
  flex: unset;
  max-width: 48%;
  width: 48%;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const Group = styled.div`
  padding-bottom: 16px;
`;
