import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { [key: string]: boolean } = {
  timeline: false,
};

export const experimentalSlice = createSlice({
  name: "experimentalFeatures",
  initialState,
  reducers: {
    setKeyValue: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      state[action.payload.key] = action.payload.value;
      return state;
    },
    setExperimentalState: (state, action: PayloadAction<any>) => ({
      ...action.payload,
    }),
  },
});

export const { setKeyValue, setExperimentalState } = experimentalSlice.actions;

export default experimentalSlice.reducer;
