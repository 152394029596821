import {
  RWYCondition,
  RWYConditionType,
  RWYPollutionCoverage,
} from "./runwayStates.constants";

export type PollutionDepthSettingsType = {
  data: string;
  type: RWYConditionType;
  depth: {
    min: number;
    max: number;
    exclude: number[];
  };
  coverage: RWYPollutionCoverage[];
  default_coverage: RWYPollutionCoverage;
};

const pollutionDepth: PollutionDepthSettingsType[] = [
  {
    data: RWYCondition.NR,
    type: RWYConditionType.COMMON,
    depth: {
      min: 0,
      max: 0,
      exclude: [],
    },
    coverage: [RWYPollutionCoverage.NR],
    default_coverage: RWYPollutionCoverage.NR,
  },
  {
    data: RWYCondition.DRY,
    type: RWYConditionType.COMMON,
    depth: {
      min: 0,
      max: 0,
      exclude: [],
    },
    coverage: [RWYPollutionCoverage.NR],
    default_coverage: RWYPollutionCoverage.NR,
  },
  {
    data: RWYCondition.WET_SNOW_ON_TOP_OF_ICE,
    type: RWYConditionType.WET,
    depth: {
      min: 0,
      max: 999,
      exclude: [1, 2],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.WET_SNOW_ON_TOP_OF_COMPACTED_SNOW,
    type: RWYConditionType.WET,
    depth: {
      min: 0,
      max: 999,
      exclude: [1, 2],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.WET_SNOW,
    type: RWYConditionType.WET,
    depth: {
      min: 0,
      max: 999,
      exclude: [1, 2],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.WET_ICE,
    type: RWYConditionType.WET,
    depth: {
      min: 0,
      max: 999,
      exclude: [],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.WET,
    type: RWYConditionType.WET,
    depth: {
      min: 0,
      max: 3,
      exclude: [1, 2],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.WATER_ON_TOP_OF_COMPACTED_SNOW,
    type: RWYConditionType.WET,
    depth: {
      min: 0,
      max: 999,
      exclude: [],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.STANDING_WATER,
    type: RWYConditionType.WET,
    depth: {
      min: 4,
      max: 999,
      exclude: [],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.SLUSH,
    type: RWYConditionType.WET,
    depth: {
      min: 0,
      max: 999,
      exclude: [1, 2],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.ICE,
    type: RWYConditionType.COMMON,
    depth: {
      min: 0,
      max: 999,
      exclude: [],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.FROST,
    type: RWYConditionType.COMMON,
    depth: {
      min: 0,
      max: 999,
      exclude: [],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.DRY_SNOW_ON_TOP_OF_ICE,
    type: RWYConditionType.COMMON,
    depth: {
      min: 0,
      max: 999,
      exclude: [1, 2],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.DRY_SNOW_ON_TOP_OF_COMPACTED_SNOW,
    type: RWYConditionType.COMMON,
    depth: {
      min: 0,
      max: 999,
      exclude: [1, 2],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.DRY_SNOW,
    type: RWYConditionType.COMMON,
    depth: {
      min: 0,
      max: 999,
      exclude: [1, 2],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
  {
    data: RWYCondition.COMPACTED_SNOW,
    type: RWYConditionType.COMMON,
    depth: {
      min: 0,
      max: 999,
      exclude: [],
    },
    coverage: [
      RWYPollutionCoverage.TwentyFive,
      RWYPollutionCoverage.Fifty,
      RWYPollutionCoverage.SeventyFive,
      RWYPollutionCoverage.OneHundred,
    ],
    default_coverage: RWYPollutionCoverage.TwentyFive,
  },
];

export default pollutionDepth;
