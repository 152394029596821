import { FC } from "react";
import styled, { keyframes } from "styled-components";
import Pollution from "./Pollution";
import PollutionDepth from "./PollutionDepth";
import State from "./State";
import { GroupTitle } from "@snowtam/ui";
import RunwaysHelper from "../../../InfoDialogs/RunwaysHelper";
import { useTranslation } from "react-i18next";
import Status from "./Status";
import ManualRWYCCSwitch from "./ManualRWYCCSwitch";

const FadeIn = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  animation: ${FadeIn} 0.3s ease-out;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px 0;
  @media (max-width: 767px) {
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const PollutionContainer = styled.div`
  margin-left: 24px;
  @media (max-width: 767px) {
    margin-top: 8px;
    margin-left: 0;
  }
`;

const SwitchRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
`;

const EndAdornment = styled.div`
  display: flex;
`;

const Title = styled(GroupTitle)`
  margin-bottom: 16px;
`;

const ThirdForm: FC<ThirdFormProps> = ({ active, third, runwayId }) => {
  const { t } = useTranslation("data_form");
  const name = () => {
    switch (third) {
      case 1:
        return t`thirds.first_title`;
      case 2:
        return t`thirds.second_title`;
      case 3:
        return t`thirds.third_title`;
    }
  };
  return active ? (
    <Container>
      <Title
        label={name()}
        endAdornmentButton={
          <EndAdornment>
            {window.innerWidth > 767 ? (
              <ManualRWYCCSwitch third={third} runwayId={runwayId} />
            ) : null}
            <RunwaysHelper />
          </EndAdornment>
        }
      />
      <Status third={third} runwayId={runwayId} />
      <Row>
        <State third={third} runwayId={runwayId} />
      </Row>
      <Row>
        <Pollution third={third} runwayId={runwayId} />
        <PollutionContainer>
          <PollutionDepth third={third} runwayId={runwayId} />
        </PollutionContainer>
      </Row>
      {window.innerWidth < 768 ? (
        <SwitchRow>
          <ManualRWYCCSwitch third={third} runwayId={runwayId} />
        </SwitchRow>
      ) : null}
    </Container>
  ) : null;
};

type ThirdFormProps = {
  active: boolean;
  third: 1 | 2 | 3;
  runwayId: number;
};

export default ThirdForm;
