import { FC, memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { RootState } from "../../../redux";
import { useHistory } from "react-router-dom";
import { setInitialState } from "../../../redux/snowtamform/snowtamformv2Reducer";
import ExistDialog from "../ErrorDialogs/ExistDialog";
import useDataValidate from "../hooks/useDataValidate";
import ConnectionLost from "../ErrorDialogs/ConnectionLost";
import ErrorDialog from "../ErrorDialogs/ErrorDialog";
import SendDialogWithRunways from "./SendDialog/";
import Requests from "../../../api/requests";

const SendButton: FC<{ isUpdate?: boolean; onClose: () => void }> = ({
  isUpdate,
  onClose,
}) => {
  const store = useStore();
  const history = useHistory();
  const dispatch = useDispatch();
  const [exist, setExist] = useState<{ show: boolean; data?: any }>({
    show: false,
  });
  const [errorDialog, setErrorDialog] = useState<boolean>(false);
  const [approve, setApprove] = useState(false);
  const [error, setError] = useState<{ error: boolean; message?: string }>({
    error: false,
  });
  const [message, setMessage] = useState<string[] | undefined>();
  const validate = useDataValidate();

  const handleGetMessage = useCallback(() => {
    const state: RootState = store.getState();
    const data: any = { ...state.snowtamFormV2 };

    const activeRunways = state.snowtamFormV2.runways.filter(
      (item) => typeof item.active === "undefined" || item.active
    ).length;
    if (activeRunways === 0) return;

    Requests.generateMessage(data).then((data) => {
      setMessage(data.message);
    });
  }, [store]);

  useEffect(() => {
    if (approve) {
      handleGetMessage();
    }
  }, [approve, store, handleGetMessage]);

  const handleValidate = useCallback(() => {
    const validation = validate();
    if (validation.error) {
      setError(validation);
      return;
    }
    setApprove(true);
  }, [validate]);

  useEffect(() => {
    handleValidate();
  }, [handleValidate]);

  const handleSave = () => {
    const validation = validate();
    if (validation.error) {
      setError(validation);
      return;
    }
    const state: RootState = store.getState();
    const data: any = { ...state.snowtamFormV2 }; // TODO Remove any type
    data.airport_id = state.general.user?.airport.id;
    delete data.timeStamp;
    delete data.pause;
    fetch(`/api/requests`, {
      method: isUpdate ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        auth: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        if (!res.ok) {
          return;
        }
        return res.json();
      })
      .then((json) => {
        if (json?.exist) {
          setExist({ show: true, data: json });
          return;
        }
        if (json.success) {
          history.push("/menu");
          dispatch(setInitialState());
        }
      })
      .catch(() => {
        setErrorDialog(true);
      });
  };

  return (
    <>
      {approve ? (
        <SendDialogWithRunways
          onSend={() => {
            handleSave();
          }}
          onClose={() => {
            setApprove(false);
            onClose();
          }}
          message={message}
          onChange={() => handleGetMessage()}
        />
      ) : null}
      {exist.show ? <ExistDialog data={exist.data} /> : null}
      {errorDialog ? (
        <ConnectionLost
          onClose={() => {
            setErrorDialog(false);
            onClose();
          }}
        />
      ) : null}
      {error.error ? (
        <ErrorDialog
          onClose={() => {
            setError({ error: false });
            if (!approve) {
              onClose();
            }
          }}
          message={error.message}
        />
      ) : null}
    </>
  );
};
export default memo(SendButton);
