import { useEffect, useState } from "react";

const useGetInitialError = () => {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initialError = sessionStorage.getItem("auth_error");
    if (initialError) {
      setError(initialError);
      sessionStorage.removeItem("auth_error");
    }
  }, []);

  return error;
};

export default useGetInitialError;
