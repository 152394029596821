const login = {
  user: "Login",
  password: "Password",
  sign_in: "Sign in",
  unauthorized: "Incorrect login or password.",
  error: {
    logged: "User was logged in from another device!",
  },
};

export default login;
