import { FC } from "react";
import ControlPublication from "../../components/ControlPublication";
import useGetLastRequest from "../../hooks/useGetLastRequest";

const LastRequest: FC = () => {
  const [data, refetch] = useGetLastRequest();

  return <ControlPublication data={data} refetch={refetch} />;
};

export default LastRequest;
