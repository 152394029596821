import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const removeTaxiwayStatusByIdAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<number>
) => {
  const index = state.taxiway_status.findIndex(
    (item) => item.id === action.payload
  );
  if (index === -1) return;

  state.taxiway_status.splice(index, 1);
  state.pause = true;
};

export default removeTaxiwayStatusByIdAction;
