export enum RWYCondition {
  NR = "NR",
  DRY = "DRY",
  COMPACTED_SNOW = "COMPACTED SNOW",
  DRY_SNOW = "DRY SNOW",
  DRY_SNOW_ON_TOP_OF_COMPACTED_SNOW = "DRY SNOW ON TOP OF COMPACTED SNOW",
  DRY_SNOW_ON_TOP_OF_ICE = "DRY SNOW ON TOP OF ICE",
  FROST = "FROST",
  ICE = "ICE",
  SLUSH = "SLUSH",
  STANDING_WATER = "STANDING WATER",
  WATER_ON_TOP_OF_COMPACTED_SNOW = "WATER ON TOP OF COMPACTED SNOW",
  WET = "WET",
  WET_ICE = "WET ICE",
  WET_SNOW = "WET SNOW",
  WET_SNOW_ON_TOP_OF_COMPACTED_SNOW = "WET SNOW ON TOP OF COMPACTED SNOW",
  WET_SNOW_ON_TOP_OF_ICE = "WET SNOW ON TOP OF ICE",
}

export type ThirdNumbers = 1 | 2 | 3;

export enum RWYConditionType {
  WET,
  COMMON,
}

export enum RWYPollutionCoverage {
  NR = "NR",
  TwentyFive = "25",
  Fifty = "50",
  SeventyFive = "75",
  OneHundred = "100",
}
