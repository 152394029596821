import { ChangeEvent, FC } from "react";
import SubTitle from "../../SubTitle";
import { OutlinedInput } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setAdhesion } from "../../../../redux/snowtamformReducer";
import Helper from "./Helper";
import styled from "styled-components";
import { Group } from "../../styles";
import { useTranslation } from "react-i18next";

const symbols = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  ".",
  " ",
];

const InputContainer = styled.div`
  display: flex;
`;

const Adhesion: FC = () => {
  const value = useSelector((state: RootState) => state.snowtamForm.adhesion);
  const dispatch = useDispatch();

  const { t } = useTranslation("data_form");
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const getValue = () => {
      const val = e.target.value;
      if (val.length > value.length) {
        const lastChar = val[val.length - 1];
        if (symbols.includes(lastChar.toLowerCase())) {
          return val;
        }
        return value;
      }
      return val;
    };
    dispatch(setAdhesion(getValue()));
  };
  return (
    <Group>
      <SubTitle label={t`adhesion`} endAdornment={<Helper />} />
      <InputContainer>
        <OutlinedInput
          name={"friction_raw"}
          label={t`adhesion`}
          value={value}
          onChange={(e) => handleChange(e)}
          inputProps={{
            maxLength: 20,
          }}
        />
      </InputContainer>
    </Group>
  );
};
export default Adhesion;
