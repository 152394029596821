import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RequestsPage from "../../components/RequestPage";
import Requests from "../../api/requests";

const TargetRequest: FC = () => {
  const { request } = useParams<{ request: string }>();

  const [data, setData] = useState();

  useEffect(() => {
    Requests.findOneByID({ id: parseInt(request, 10) }).then((res) =>
      setData(res)
    );
  }, [request]);

  return <RequestsPage data={data} />;
};
export default TargetRequest;
