import styled from "styled-components";

export const BorderedCard = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.blocks};
  border: 1px solid ${({ theme }) => theme.colors.stroke_block};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 1rem;
  }
`;
