import { Middleware } from "@reduxjs/toolkit";

type SessionSaverProps = {
  states: string[];
};
export const sessionSaver: (props: SessionSaverProps) => Middleware =
  (props: { states: string[] }) => (store) => (next) => (action) => {
    const ActionType = action.type.split("/")[0];
    const result = next(action);
    if (ActionType && props.states.includes(ActionType)) {
      let stateToSave = store.getState();
      sessionStorage.setItem(
        ActionType,
        JSON.stringify({ ...stateToSave[ActionType] })
      );
    }
    return result;
  };

export const sessionLoader = (props: SessionSaverProps) => {
  let result: any = {};
  props.states.forEach((state) => {
    const data = sessionStorage.getItem(state);
    if (data) {
      result[state] = JSON.parse(data);
    }
  });
  return result;
};
