import {
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from "@snowtam/ui";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DataContext } from "./Providers/DataProvider";
import Row from "./Row";

export const Container = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.blocks};
  border: 1px solid ${(props) => props.theme.colors.stroke_block};
  box-sizing: border-box;
  padding: 0 24px;
  width: 100%;
  flex-direction: column;
`;

const TBody = styled(TableBody)`
  tr:last-child {
    border-bottom: none;
  }
`;

const List: FC = () => {
  const { t } = useTranslation("friction");
  const { equipment } = useContext(DataContext);
  return (
    <Container>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderColumn>{t`designation`}</TableHeaderColumn>
            <TableHeaderColumn
              style={{ width: "1%" }}
            >{t`action`}</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TBody>
          {equipment && equipment.length > 0 ? (
            equipment.map((item) => <Row key={item.id} data={item} />)
          ) : (
            <TableRow style={{ borderBottom: "none" }}>
              <TableColumn colSpan={2}>{t`no_friction_equipment`}</TableColumn>
            </TableRow>
          )}
        </TBody>
      </Table>
    </Container>
  );
};

export default List;
