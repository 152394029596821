import { FC, useMemo } from "react";
import { DropDown, DropDownItem } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { setRunwayState } from "../../../../../redux/snowtamformReducer";
import { useTranslation } from "react-i18next";

const dataItems: (t: any) => DropDownItem[] = (t) => [
  {
    id: 1,
    value: t`data_form:conditions.NR`,
    data: "NR",
  },
  {
    id: 2,
    value: t`data_form:conditions.DRY`,
    data: "DRY",
  },
  {
    id: 3,
    value: t`data_form:conditions.WET_SNOW_ON_TOP_OF_ICE`,
    data: "WET SNOW ON TOP OF ICE",
  },
  {
    id: 4,
    value: t`data_form:conditions.WET_SNOW_ON_TOP_OF_COMPACTED_SNOW`,
    data: "WET SNOW ON TOP OF COMPACTED SNOW",
  },
  {
    id: 5,
    value: t`data_form:conditions.WET_SNOW`,
    data: "WET SNOW",
  },
  {
    id: 6,
    value: t`data_form:conditions.WET_ICE`,
    data: "WET ICE",
  },
  {
    id: 7,
    value: t`data_form:conditions.WET`,
    data: "WET",
  },
  {
    id: 8,
    value: t`data_form:conditions.WATER_ON_TOP_OF_COMPACTED_SNOW`,
    data: "WATER ON TOP OF COMPACTED SNOW",
  },
  {
    id: 9,
    value: t`data_form:conditions.STANDING_WATER`,
    data: "STANDING WATER",
  },
  {
    id: 10,
    value: t`data_form:conditions.SLUSH`,
    data: "SLUSH",
  },
  {
    id: 11,
    value: t`data_form:conditions.ICE`,
    data: "ICE",
  },
  {
    id: 12,
    value: t`data_form:conditions.FROST`,
    data: "FROST",
  },
  {
    id: 13,
    value: t`data_form:conditions.DRY_SNOW_ON_TOP_OF_ICE`,
    data: "DRY SNOW ON TOP OF ICE",
  },
  {
    id: 14,
    value: t`data_form:conditions.DRY_SNOW_ON_TOP_OF_COMPACTED_SNOW`,
    data: "DRY SNOW ON TOP OF COMPACTED SNOW",
  },
  {
    id: 15,
    value: t`data_form:conditions.DRY_SNOW`,
    data: "DRY SNOW",
  },
  {
    id: 16,
    value: t`data_form:conditions.COMPACTED_SNOW`,
    data: "COMPACTED SNOW",
  },
];

const State: FC<{ third: 1 | 2 | 3; runwayId: number }> = ({
  third,
  runwayId,
}) => {
  const formValue = useSelector((state: RootState) => {
    const index = state.snowtamForm.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamForm.runways[index]?.thirds[third]?.status;
  });
  const dispatch = useDispatch();
  const { t } = useTranslation(["data_form", "common"]);

  const items = useMemo(() => dataItems(t), [t]);

  const value = items.find((item) => item.id === formValue?.id);
  return (
    <DropDown
      name={"state"}
      label={t`data_form:thirds.condition_description`}
      items={items}
      value={value || items[0]}
      notChosenText={t`common:not_chosen`}
      onChoose={(val) =>
        dispatch(
          setRunwayState({
            runwayId,
            third,
            value: {
              id: val.id,
              value: val.data,
            },
          })
        )
      }
    />
  );
};
export default State;
