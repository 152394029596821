import { Redirect, Route, RouteProps } from "react-router-dom";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { UserRoles } from "../Router";

interface PrivateRouteProps extends RouteProps {
  component: any;
}

export const GuestRoute: FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const loading = useSelector((state: RootState) => state.general.userLoading);
  const user = useSelector((state: RootState) => state.general.user);

  const redirectUrl = () => {
    if (user) {
      switch (user.role) {
        case UserRoles.Operator:
          return "/menu";
        case UserRoles.Manager:
          return "/admin";
        default:
          return "/";
      }
    }
    return "/";
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        loading || !user || !user.role ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectUrl()} />
        )
      }
    />
  );
};

export default GuestRoute;
