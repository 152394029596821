import { FC, PropsWithChildren } from "react";
import ExistDialog from "./ErrorDialogs/ExistDialog";
import useCheckRequestExist from "../../hooks/useCheckRequestExist";

const Preload: FC<PropsWithChildren<any>> = (props) => {
  const existData = useCheckRequestExist();

  if (!existData) return null;

  return existData?.exist ? <ExistDialog data={existData} /> : props.children;
};

export default Preload;
