const menu = {
  menu: "Меню",
  exit: "Выход",
  create_short: "Создать",
  information: "Информация",
  create: "Создать заявку",
  history: "История заявок",
  history_short: "История",
  templates: "Шаблоны текстов",
  templates_short: "Шаблоны",
  created: "Заявка создана",
  cancel: "Отменить заявку",
  language: "Выбор языка",
  admin_info: "Информация по аэродрому",
  admin_history: "История подачи заявок",
  admin_users: "Список операторов",
  settings: "Настройки",
};

export default menu;
