const templates = {
  templates: "Templates",
  create: "New",
  name: "Template name",
  actions: "Actions",
  new_template: "New template",
  edit_template: "Edit template",
  text: "Enter text",
  delete_template: "Delete template?",
};

export default templates;
