import { FC } from "react";
import { IconUploadDocument } from "@snowtam/ui";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Button = styled.button`
  display: flex;
  padding: 0.5rem 2rem;
  background: rgba(0, 0, 0, 0);
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.active};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.active};
  transition: all 0.125s ease;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  svg {
    transition: all 0.125s ease;
    color: ${({ theme }) => theme.colors.active};
    margin-right: 0.5rem;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.active};
    border-radius: 0.25rem;
    color: ${({ theme }) => theme.colors.font_active};
    background: ${({ theme }) => theme.colors.active};

    svg {
      color: ${({ theme }) => theme.colors.font_active};
    }
  }
  @media (max-width: 1200px) {
    padding: 0.75rem 2rem;
    width: 327px;
    font-size: 1rem;
    margin: 0 1rem;
  }
`;

type UploadToFormProps = {
  request_id: number | string;
};

const UploadToForm: FC<UploadToFormProps> = ({ request_id }) => {
  const history = useHistory();
  const { t } = useTranslation("requests");

  return (
    <Button
      onClick={() =>
        history.push(`/form?from=${encodeURIComponent(request_id)}`)
      }
      name={"fill_form"}
    >
      <IconUploadDocument />
      {t`upload_to_form`}
    </Button>
  );
};

export default UploadToForm;
