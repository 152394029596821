import { FC, MutableRefObject, useRef, useState } from "react";
import AddModal from "../../../AddModal";
import RCAM from "./RCAM";
import { CommonButton, InfoPopupWindow, useOutsideClicker } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const CodePopup: FC<{
  onClose: () => void;
  toggler?: MutableRefObject<any>;
}> = ({ onClose, toggler }) => {
  const popupRef = useRef(null);
  const [more, setMore] = useState(false);
  const { t } = useTranslation("helper");

  useOutsideClicker(popupRef, onClose, toggler);
  return (
    <div ref={popupRef}>
      <InfoPopupWindow
        title={t`statuscode.title`}
        text={<>{t`statuscode.line1`}</>}
        onClose={onClose}
        more
        onMoreClick={() => setMore(true)}
        moreText={t`statuscode.snow_rcam`}
        noOutSideClick
        hide={more}
      />
      {more ? (
        <AddModal title={t`statuscode.rcam`} onClose={() => onClose()}>
          <RCAM />
          <CommonButton label={t`common.close`} onClick={() => onClose()} />
        </AddModal>
      ) : null}
    </div>
  );
};

export default CodePopup;
