import { DropDown, DropDownItem, OutlinedInput } from "@snowtam/ui";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../../redux";
import {
  setFrictionFormDevice,
  setFrictionFormValue,
} from "../../../../redux/snowtamform/snowtamformv2Reducer";
import useGetFrictionDevices from "../../hooks/useGetFrictionDevices";

const Container = styled.div`
  width: 48%;
  @media (max-width: 1024px) {
    margin-top: 12px;
    width: 100%;
  }
`;

const DeviceSelector: FC<{ runwayId: number }> = ({ runwayId }) => {
  const items = useGetFrictionDevices();
  const value = useSelector((state: RootState) => {
    return state.snowtamFormV2.friction_form?.find(
      (item) => item.runwayId === runwayId
    );
  });

  const { t } = useTranslation("data_form");

  const val = useMemo(
    () => items.find((item) => item.data === value?.device),
    [items, value?.device]
  );

  const dispatch = useDispatch();

  const handleChange = useCallback(
    (val: DropDownItem) => {
      dispatch(
        setFrictionFormDevice({
          runwayId,
          value: val.data,
        })
      );
    },
    [dispatch, runwayId]
  );

  useEffect(() => {
    if (!val && !!value?.device && items.length > 0) {
      handleChange(items[0]);
      [1, 2, 3].forEach((third) =>
        dispatch(
          setFrictionFormValue({
            runwayId,
            third: third as 1 | 2 | 3,
            value: "",
          })
        )
      );
    }
  }, [dispatch, handleChange, items, runwayId, val, value?.device]);

  const isError = useMemo(
    (): boolean =>
      !!(
        value &&
        ((value.first !== "0" && value.first !== "") ||
          (value.second !== "0" && value.second !== "") ||
          (value.third !== "0" && value.third !== "")) &&
        (!value.device || value.device.length === 0)
      ),
    [value]
  );

  return (
    <Container>
      {items && items.length > 0 ? (
        <DropDown
          name={"friction_device"}
          label={t`measuring_device`}
          items={items}
          value={val}
          onChoose={handleChange}
          error={isError}
          notChosenText={t`not_chosen`}
        />
      ) : (
        <OutlinedInput
          name={"friction_device"}
          label={t`measuring_device`}
          error={isError}
          value={value?.device ?? ""}
          onChange={(e) =>
            handleChange({
              id: 0,
              value: "",
              data: e.target.value.toUpperCase(),
            })
          }
        />
      )}
    </Container>
  );
};

export default DeviceSelector;
