import { FC } from "react";
import UsersList from "../../components/UsersList";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 1rem;
`;
const AdminUsers: FC = () => {
  return (
    <Wrapper>
      <UsersList />
    </Wrapper>
  );
};
export default AdminUsers;
