import { FC } from "react";
import { DialogYesNo } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

interface ErrorDialogProps {
  onClose: () => void;
  message?: string;
}

const ErrorDialog: FC<ErrorDialogProps> = (props) => {
  const { t } = useTranslation(["data_form", "common"]);

  return (
    <DialogYesNo
      title={t`common:errors.warning`}
      onApply={props.onClose}
      yesText={"OK"}
    >
      <p
        style={{
          textAlign: "center",
          fontSize: "20px",
          lineHeight: "24px",
          marginBottom: "0",
        }}
      >
        {props.message}
      </p>
    </DialogYesNo>
  );
};
export default ErrorDialog;
