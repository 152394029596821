import { configureStore } from "@reduxjs/toolkit";
import generalReducer from "./generalReducer";
import snowtamformReducer from "./snowtamformReducer";
import snowtamformv2Reducer from "./snowtamform/snowtamformv2Reducer";
import historyFilterReducer from "./historyFilterReducer";
import experimentalReducer from "./experimentalReducer";
import appReducer from "./appReducer";
import { save, load } from "redux-localstorage-simple";
import { sessionLoader, sessionSaver } from "./utils/sessionSaver";

const preloadFromLocalStorage = (): any => {
  const loaded: any = load({
    states: ["snowtamForm", "snowtamFormV2"],
    disableWarnings: true,
  });
  const response: any = {};
  if (typeof loaded?.snowtamForm?.version !== "undefined") {
    response.snowtamForm = loaded?.snowtamForm;
  }
  if (typeof loaded?.snowtamFormV2?.version !== "undefined") {
    response.snowtamFormV2 = loaded?.snowtamFormV2;
  }
  return response;
};

export const store = configureStore({
  reducer: {
    general: generalReducer,
    snowtamForm: snowtamformReducer,
    snowtamFormV2: snowtamformv2Reducer,
    historyFilter: historyFilterReducer,
    experimental: experimentalReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      save({
        states: ["snowtamForm", "snowtamFormV2"],
        disableWarnings: true,
      }),
      sessionSaver({ states: ["general"] })
    ),
  preloadedState: {
    ...preloadFromLocalStorage(),
    ...sessionLoader({ states: ["general"] }),
  },
});

export default store;

export type RootDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
