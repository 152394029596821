import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setPollutionDepthAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{
    runwayId: number;
    third: 1 | 2 | 3;
    value: number | null;
  }>
) => {
  const index = state.runways.findIndex(
    (item) => item.runwayId === action.payload.runwayId
  );
  if (state.runways[index]) {
    state.runways[index].thirds[action.payload.third].pollutionDepth =
      action.payload.value;
    state.pause = true;
  }
};

export default setPollutionDepthAction;
