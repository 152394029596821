import { FC, memo } from "react";
import styled from "styled-components";
import Information from "../../components/Information";
import ControlPublication from "../../components/ControlPublication";
import FontSelector from "../../components/FontSelector";
import CreateButton from "./CreateButton";
import BurgerMenu from "../../components/BurgerMenu";
import useGetLastRequest from "../../hooks/useGetLastRequest";

const TitleContainer = styled.div`
  padding: 8px 16px;
  box-shadow: ${({ theme }) => theme.shadows.header_main};
  background: ${(props) => props.theme.colors.blocks};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  position: relative;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 8px 16px;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background: ${(props) => props.theme.colors.blocks};
  @media (min-width: 1200px) {
    margin: 16px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  padding: 0.5rem 0;
  box-shadow: ${({ theme }) => theme.shadows.tablet_bottom};
  @media (max-width: 1199px) {
    z-index: 1050;
    background: ${(props) => props.theme.colors.blocks};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 1rem;
  }
`;

const MenuRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${(props) => props.theme.colors.background};
  @media (max-width: 1199px) {
    padding: 16px;
  }
`;
const MobileMenu = styled.div`
  margin-top: auto;
  @media (min-width: 1200px) {
    display: none;
  }
`;

const Menu: FC = () => {
  const [data, refetch] = useGetLastRequest();

  return (
    <>
      <TitleContainer>
        <FontSelector />
        SNOWTAM
        <BurgerMenu />
      </TitleContainer>
      <Container>
        <Content>
          <Information />
          <ControlPublication data={data} refetch={refetch} />
        </Content>
        <MobileMenu>
          <MenuContainer>
            <MenuRow>
              <CreateButton refetch={refetch} />
            </MenuRow>
          </MenuContainer>
        </MobileMenu>
      </Container>
    </>
  );
};
export default memo(Menu);
