import { Draft, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { DATE_FORMAT, TIME_FORMAT } from "../../../settings/app.constants";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const setServerTimeAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<number>
) => {
  const serverTime = dayjs.utc(action.payload, "x");
  state.runways.forEach((rwy) => {
    rwy.date = serverTime.format(DATE_FORMAT);
    rwy.time = serverTime.format(TIME_FORMAT);
    rwy.timestamp = serverTime.unix().toString();
  });
};

export default setServerTimeAction;
