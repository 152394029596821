import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setSnowbanksTwyRawAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<string>
) => {
  state.snowbanks_twy_raw = action.payload;
  state.pause = true;
};

export default setSnowbanksTwyRawAction;
