import { IconButton, IconDelete, TableColumn, TableRow } from "@snowtam/ui";
import { FC, useContext, useState } from "react";
import { FrictionEquipmentEntity } from "../../api/friction-equipment.types";
import DeleteDialog from "./DeleteDialog";
import { DataContext } from "./Providers/DataProvider";

type RowProps = {
  data: FrictionEquipmentEntity;
};

const Row: FC<RowProps> = ({ data }) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const { refetch } = useContext(DataContext);

  return (
    <TableRow>
      <TableColumn>{data.name}</TableColumn>
      <TableColumn>
        <IconButton
          icon={() => <IconDelete />}
          color={"red"}
          size={"small"}
          style={{ margin: "auto" }}
          onClick={() => setDeleteDialog(true)}
        />
        {deleteDialog ? (
          <>
            <DeleteDialog
              data={data}
              onCancel={() => setDeleteDialog(false)}
              onSuccess={() => {
                setDeleteDialog(false);
                refetch();
              }}
            />
          </>
        ) : null}
      </TableColumn>
    </TableRow>
  );
};

export default Row;
