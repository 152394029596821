import { FC, MutableRefObject } from "react";
import { InfoPopupWindow } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const SandPopup: FC<{
  onClose: () => void;
  toggler?: MutableRefObject<any>;
}> = ({ onClose, toggler }) => {
  const { t } = useTranslation("helper");

  return (
    <>
      <InfoPopupWindow
        title={t`meteo.loose_sand`}
        text={<>{t`meteo.loose_sand_text`}</>}
        onClose={onClose}
        toggler={toggler}
      />
    </>
  );
};

export default SandPopup;
