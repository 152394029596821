const friction = {
  name: "Наименование",
  create: "Создать",
  no_friction_equipment: "Список измерительного оборудования пуст",
  friction_equipment: "Оборудование Ксц",
  friction_equipment_title: "Измерительное оборудование",
  friction_minimal_value: "Максимальное значие кСц",
  friction_downgrade_rwycc: "Понижение RWYCC",
  designation: "Обозначение",
  action: "Действия",
  delete_equipment: "Удалить оборудование",
  new_equipment: "Новое оборудование",
  delete: "Удалить",
  cancel: "Отмена",
  errors: {
    fill_all: "Заполните все поля!",
    code_incorrect: "Не верный код аэропорта!",
    rwy_name_length: "Обозначение ВПП должно содержать не менее 2 символов!",
  },
};

export default friction;
