import { FC, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropDown, DropDownItem } from "@snowtam/ui";
import {
  addSnowbanksTaxiway,
  removeSnowbanksTaxiwayById,
  updateSnowbanksTaxiway,
} from "../../../../redux/snowtamform/snowtamformv2Reducer";
import { DropDownContainer } from "../styles";
import { useTranslation } from "react-i18next";
import useGetSelectionItems, {
  ALL_ITEM,
  EMPTY_ITEM,
} from "./hooks/useGetSelectionItems";
import { RootState } from "../../../../redux";

const SnowBankItem: FC<{
  item?: DropDownItem;
  withAll?: boolean;
}> = ({ item, withAll }) => {
  const items = useGetSelectionItems({
    withAll,
  });
  const isAllSelected = useSelector(
    (state: RootState) =>
      state.snowtamFormV2.snowbanks_taxiway.findIndex(
        (item) => item.id === ALL_ITEM.id
      ) !== -1
  );
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "data_form"]);

  const handleChoose = (value: DropDownItem) => {
    if (!item && value.id !== EMPTY_ITEM.id) {
      dispatch(addSnowbanksTaxiway(value));
      return;
    }
    if (item && value.id !== item.id) {
      if (value.id !== EMPTY_ITEM.id) {
        dispatch(updateSnowbanksTaxiway({ data: value, prevId: item.id }));
      } else {
        dispatch(removeSnowbanksTaxiwayById(item.id));
      }
    }
  };

  if (!item && isAllSelected) {
    return null;
  }

  return (
    <DropDownContainer>
      <DropDown
        name={"twy_snowbanks"}
        label={t`data_form:twy_snowbanks`}
        items={items}
        value={item}
        notChosenText={t`common:not_chosen`}
        onChoose={handleChoose}
      />
    </DropDownContainer>
  );
};
export default memo(SnowBankItem);
