import { SnowtamFormStateTypeV2 } from "../../../../@types/form";
import { RunwaysType } from "../../../../@types/models";
import dayjs from "dayjs";
import { DATE_FORMAT, TIME_FORMAT } from "../../../settings/app.constants";
import settings from "../../../settings/form";

export const appendRunwayToState = (
  data: SnowtamFormStateTypeV2,
  runway: RunwaysType,
  timestamp: number
) => {
  const serverTime = dayjs.utc(timestamp, "x");
  const result = data;
  result.reduced.push({
    id: runway.id,
    runway: runway.name,
    value: runway.length,
  });
  result.snowstorm.push({
    id: runway.id,
    runway: runway.name,
    value: false,
  });
  result.sand.push({
    id: runway.id,
    runway: runway.name,
    value: false,
  });
  result.treated.push({
    id: runway.id,
    runway: runway.name,
    value: false,
  });
  result.snowbanks_near_runway.push({
    id: runway.id,
    runway: runway.name,
    value: false,
  });
  result.snowbank_runway_position.push({
    position: {
      id: 0,
      value: "",
      data: "",
    },
    value: Math.round(runway.width / 2),
    name: runway.name,
    runwayId: runway.id,
  });
  result.runways.push({
    runwayId: runway.id,
    width: runway.width,
    name: runway.name,
    date: serverTime.format(DATE_FORMAT),
    time: serverTime.format(TIME_FORMAT),
    timestamp: serverTime.unix().toString(),
    active: true,
    thirds: {
      1: {
        pollution: settings.pollutionDepthItems.find((item) => item.id === 0),
        pollutionDepth: 0,
        status: {
          id: 1,
          value: "NR",
        },
        status_code: {
          id: 6,
          value: "6",
        },
      },
      2: {
        pollution: settings.pollutionDepthItems.find((item) => item.id === 0),
        pollutionDepth: 0,
        status: {
          id: 1,
          value: "NR",
        },
        status_code: {
          id: 6,
          value: "6",
        },
      },
      3: {
        pollution: settings.pollutionDepthItems.find((item) => item.id === 0),
        pollutionDepth: 0,
        status: {
          id: 1,
          value: "NR",
          data: "NR",
        },
        status_code: {
          id: 6,
          value: "6",
        },
      },
    },
  });
  return result;
};
