import { FC } from "react";
import styled from "styled-components";
import Title from "./Title";
import Profile from "./Profile";
import Language from "./Language";
import Theme from "./Theme";
import FontSize from "./FontSize";
import NewForm from "./NewForm";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { UserRoles } from "../../Router";
import About from "./About";

const Container = styled.div`
  padding: 1rem;
  position: relative;
  @media (max-width: 1199px) {
    padding: 0;
  }
`;

const Paper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  align-items: center;
  background: ${({ theme }) => theme.colors.blocks};
  border: 1px solid ${({ theme }) => theme.colors.stroke_block};
  box-sizing: border-box;
  @media (max-width: 1199px) {
    background: none;
    padding: 1.5rem;
    border: none;
  }
  @media (max-width: 767px) {
    padding: 0.5rem;
  }
`;

const Settings: FC = () => {
  const role = useSelector((state: RootState) => state.general.user?.role);

  return (
    <Container>
      <Title />
      <Paper>
        <Profile />
        <Theme />
        <Language />
        <FontSize />
        {role === UserRoles.Operator ? <NewForm /> : null}
        <About />
      </Paper>
    </Container>
  );
};

export default Settings;
