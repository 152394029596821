import { FC, memo } from "react";
import SubTitle from "../../SubTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import styled from "styled-components";
import ApronItem from "./ApronItem";
import { Group } from "../styles";
import { useTranslation } from "react-i18next";
import RawInput from "./RawInput";
import { InfoPopup } from "@snowtam/ui";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const ApronStatus: FC = () => {
  const items = useSelector(
    (state: RootState) => state.snowtamFormV2.apron_status
  );

  const aprons = useSelector(
    (state: RootState) => state.general.user?.airport.aprons
  );
  const { t } = useTranslation("data_form");

  return (
    <Group>
      <SubTitle
        label={t`status_apron`}
        endAdornment={
          <InfoPopup
            title={t`helper:aprons.title`}
            text={
              <>
                {t`helper:aprons.line1`}
                <br />
                {t`helper:aprons.line2`}
              </>
            }
          />
        }
      />
      <Row>
        {aprons && aprons.length > 0 ? (
          <>
            {items && items.length > 0
              ? items.map((item, index) => {
                  return (
                    <ApronItem
                      key={`sbi${item.id}`}
                      item={item}
                      withAll={index === 0 && items.length === 1}
                    />
                  );
                })
              : null}

            <ApronItem withAll={items.length === 0} />
          </>
        ) : (
          <RawInput />
        )}
      </Row>
    </Group>
  );
};

export default memo(ApronStatus);
