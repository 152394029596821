import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { DropDownItem, DropDownNaked } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const RunwaysSelector: FC<{ onSelect: (id: number) => void }> = ({
  onSelect,
}) => {
  const runways = useSelector(
    (state: RootState) => state.general.user?.airport.runways
  );
  const { t } = useTranslation("data_form");

  const items: DropDownItem[] | undefined = useMemo(
    () =>
      runways?.map((runway) => ({
        id: runway.id,
        value: runway.name,
      })),
    [runways]
  );
  const [value, setValue] = useState<DropDownItem>();

  useEffect(() => {
    if (items) {
      setValue(items[0]);
    }
  }, [items]);

  return (
    <DropDownNaked
      items={items ?? []}
      value={value}
      onChoose={(v) => {
        onSelect(v.id);
        setValue(v);
      }}
      _id={"runway_selector"}
      renderItem={(items) => `${t`rwy`} ${items.value}`}
      renderValue={(item) => (item ? `${t`rwy`} ${item.value}` : "")}
    />
  );
};
export default RunwaysSelector;
