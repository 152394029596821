import { FC, useEffect, useState } from "react";
import TopBar from "../../components/TopBar";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import printJS from "print-js";
import ConnectionErrorCard from "../../components/ConnectionErrorCard";
import { useTranslation } from "react-i18next";
import RequestCard from "../../components/RequestCard";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { IconButton, IconHistory, IconPrint } from "@snowtam/ui";
import UploadToForm from "./UploadToForm";
import SimpleBar from "simplebar-react";
import { UserRoles } from "../../Router";

const Container = styled.div`
  background: ${(props) => props.theme.colors.blocks};
  margin: 8px 16px;
  border: 1px solid ${(props) => props.theme.colors.stroke_block};
  border-radius: 4px;
`;

const Title = styled.div`
  display: flex;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};
  align-items: center;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};

  @media (max-width: 1199px) {
    padding: 1rem 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

const SnowtamText = styled.div`
  margin: 8px;
  padding: 8px;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;

  span {
    white-space: nowrap;
  }

  @media (max-width: 767px) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const UploadButtonDesktop = styled.div`
  display: flex;
  margin-left: auto;
  gap: 16px;
  @media (max-width: 1199px) {
    display: none;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

const UploadContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.colors.blocks};
  box-shadow: ${({ theme }) => theme.shadows.tablet_bottom};
  padding: 0.875rem 0;
  width: 100%;
  z-index: 1000;
  margin-top: auto;
  @media (min-width: 1200px) {
    display: none;
  }
`;

type RequestPageProps = {
  data: any;
  error?: any;
};

const RequestPage: FC<RequestPageProps> = ({ data, error }) => {
  const history = useHistory();
  const [errorCard, setErrorCard] = useState(false);
  const user = useSelector((state: RootState) => state.general.user);
  const { t } = useTranslation("requests");
  useEffect(() => {
    if (error?.type === "connection") {
      setErrorCard(true);
    }
  }, [error]);
  const snowtamtext = data?.snowtam ? data.snowtam : data?.snowtam_initial;

  const handlePrint = () => {
    printJS({
      printable: `<span>${snowtamtext.split("#").join("<br />")}</span>`,
      documentTitle: "SNOWTAM " + data.snowtam_id,
      type: "raw-html",
    });
  };

  return (
    <>
      {data ? (
        <>
          <TopBar
            title={t`request`}
            onBack={() => {
              history.goBack();
            }}
          />
          <Container style={{ marginTop: "16px" }}>
            <RequestCard data={data} />
          </Container>
          {snowtamtext ? (
            <Container>
              <Title>
                <IconHistory
                  style={{
                    marginRight: "16px",
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                />
                <span style={{ padding: "0.5rem 0" }}>{t`snowtam_text`}</span>
                <UploadButtonDesktop>
                  {user?.role === UserRoles.Operator ? (
                    <UploadToForm request_id={data.id} />
                  ) : null}
                  {data?.snowtam ? (
                    <IconButton
                      icon={() => <IconPrint />}
                      color={"blue"}
                      onClick={handlePrint}
                    />
                  ) : null}
                </UploadButtonDesktop>
              </Title>
              <SnowtamText>
                <SimpleBar autoHide={false}>
                  <TextWrapper>
                    {snowtamtext
                      .split("#")
                      .map((line: string, index: number) => (
                        <span key={index}>{line}&nbsp;</span>
                      ))}
                  </TextWrapper>
                </SimpleBar>
              </SnowtamText>
            </Container>
          ) : null}
          {user?.role === UserRoles.Operator ? (
            <UploadContainerMobile>
              <UploadToForm request_id={data.id} />
            </UploadContainerMobile>
          ) : null}
        </>
      ) : null}
      {errorCard ? <ConnectionErrorCard /> : null}
    </>
  );
};
export default RequestPage;
