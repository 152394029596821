import { FC, memo } from "react";
import SubTitle from "../../SubTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import styled from "styled-components";
import SnowBankItem from "./SnowBankItem";
import { Group } from "../styles";
import { useTranslation } from "react-i18next";
import RawInput from "./RawInput";
import { InfoPopup } from "@snowtam/ui";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const SnowbanksTaxiway: FC = () => {
  const items = useSelector(
    (state: RootState) => state.snowtamFormV2.snowbanks_taxiway
  );

  const taxiways = useSelector(
    (state: RootState) => state.general.user?.airport.taxiways
  );

  const { t } = useTranslation("data_form");

  return (
    <Group>
      <SubTitle
        label={t`snowbanks_twy`}
        endAdornment={
          <InfoPopup
            title={t`helper:snowbanks_twy.title`}
            text={t`helper:snowbanks_twy.line1`}
          />
        }
      />
      <Row>
        {taxiways && taxiways?.length > 0 ? (
          <>
            {items && items.length > 0
              ? items.map((item, index) => {
                  return (
                    <SnowBankItem
                      key={`sbi${item.id}`}
                      item={item}
                      withAll={index === 0 && items.length === 1}
                    />
                  );
                })
              : null}
            <SnowBankItem withAll={items.length === 0} />
          </>
        ) : (
          <RawInput />
        )}
      </Row>
    </Group>
  );
};

export default memo(SnowbanksTaxiway);
