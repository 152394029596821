import { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IconTemplate } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const RefillLink = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.active};
  text-decoration: underline;
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
  @media (max-width: 767px) {
    font-size: 0.875rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin-right: 1rem;
`;

type RefillProps = {
  request_id: number;
};

const Refill: FC<RefillProps> = ({ request_id }) => {
  const { t } = useTranslation("requests");
  return (
    <Link to={`/form?request=${encodeURIComponent(request_id)}`} id="refill">
      <RefillLink>
        <IconWrapper>
          <IconTemplate />
        </IconWrapper>
        {t`correct_resubmit`}
      </RefillLink>
    </Link>
  );
};

export default Refill;
