import { FC, useRef, useState } from "react";
import styled from "styled-components";
import MenuPopup from "./MenuPopup";
import WidthPopup from "./WidthPopup";
import { IconButton, IconInfo } from "@snowtam/ui";
import ReducedPopup from "./ReducedPopup";

const Container = styled.div`
  position: relative;
`;

const InfoButton = styled(IconButton)`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 4px;
    left: 4px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.active_light};
    opacity: 0;
  }
  &:hover {
    &:before {
      transition: 0.125s ease;
      opacity: 1;
    }
  }
  svg {
    z-index: 0;
  }
`;

const DimensionsHelper: FC = () => {
  const togglerRef = useRef(null);
  const [open, setOpen] = useState<number | null>(null);
  return (
    <Container>
      <InfoButton
        buttonRef={togglerRef}
        color="transparent"
        icon={() => <IconInfo />}
        onClick={() => setOpen((prev) => (prev === 0 ? null : 0))}
      />
      {open === 0 ? (
        <MenuPopup
          toggler={togglerRef}
          onClose={() => {
            setOpen(null);
          }}
          onSelect={(value) => {
            setOpen(value);
          }}
        />
      ) : null}

      {open === 1 ? (
        <WidthPopup toggler={togglerRef} onClose={() => setOpen(null)} />
      ) : null}
      {open === 2 ? (
        <ReducedPopup toggler={togglerRef} onClose={() => setOpen(null)} />
      ) : null}
    </Container>
  );
};

export default DimensionsHelper;
