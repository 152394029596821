import axios, { Axios, AxiosRequestConfig, AxiosResponse } from "axios";
import store, { RootState } from "../redux";
import { setSettings } from "../redux/generalReducer";
import { setExperimentalState } from "../redux/experimentalReducer";

const server = axios.create();

class API {
  protected readonly server: Axios;

  constructor() {
    this.server = server;
    server.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const token = this._getAccessToken();
        if (token && config?.headers) {
          config.headers.auth = "Bearer " + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error).then();
      }
    );
    server.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;
        const initialRefreshToken = localStorage.getItem("refresh_token");
        if (
          originalConfig.url !== "/api/auth/refresh" &&
          err.response &&
          initialRefreshToken
        ) {
          if (err.response.status === 403 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              const { token, refresh_token } = await server
                .post("/api/auth/refresh", {
                  token: initialRefreshToken,
                })
                .then((res: any) => res.data);
              localStorage.setItem("token", token);
              localStorage.setItem("refresh_token", refresh_token);
              return server(originalConfig);
            } catch (_error) {
              this.throwOut(_error);
              return Promise.reject(_error);
            }
          } else if (err.response.status === 403) {
            this.throwOut(err);
          }
        }
        return Promise.reject(err);
      }
    );
  }

  private throwOut(err: any) {
    localStorage.clear();
    sessionStorage.clear();
    if (err.response.data.logged) {
      sessionStorage.setItem("auth_error", "logged");
    }
    window.location.reload();
  }

  public post<P, D>(path: string, payload: P) {
    return this.server.request<P, D>({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload,
    });
  }

  public put<P, D>(path: string, payload: P) {
    return this.server.request<P, D>({
      method: "PUT",
      url: path,
      responseType: "json",
      data: payload,
    });
  }

  public patch<P, D>(path: string, payload?: P) {
    return this.server.request<P, D>({
      method: "PATCH",
      url: path,
      responseType: "json",
      data: payload,
    });
  }

  public delete<P, D>(path: string, payload?: P) {
    return this.server.request<P, D>({
      method: "DELETE",
      url: path,
      responseType: "json",
      data: payload,
    });
  }

  public get<P, D>(path: string): Promise<D> {
    return this.server.get<P, D>(path);
  }

  public head<P, D>(path: string, payload?: P) {
    return this.server.request<P, D>({
      method: "HEAD",
      url: path,
      responseType: "json",
      data: payload,
    });
  }

  protected _getAccessToken() {
    return localStorage.getItem("token");
  }

  protected dispatch(action: any) {
    store.dispatch(action);
  }

  protected getState(): RootState {
    return store.getState();
  }

  fetchSettings() {
    this.server.get("/api/user/settings").then((res: AxiosResponse<any>) => {
      const { experimental, ...settings } = res.data;
      this.dispatch(setSettings(settings));
      this.dispatch(setExperimentalState(experimental));
    });
  }

  putSetting(data: any) {
    return this.server.put("/api/user/settings", data);
  }

  getServerTime() {
    return this.server
      .get("/api/user/time")
      .then<{ time: number }>((res) => res.data);
  }
}

export default new API();
