import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import DataFormV2 from "../../components/DataFormV2";
import DataForm from "../../components/DataForm";

const FormPage: FC = () => {
  const new_form = useSelector(
    (state: RootState) => state.general.settings?.new_form
  );

  return new_form ? <DataFormV2 /> : <DataForm />;
};
export default FormPage;
