import styled from "styled-components";
import { FC, PropsWithChildren } from "react";
import { IconClose, useKeyPress } from "@snowtam/ui";
import SimpleBar from "simplebar-react";

const BackDrop = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.backdrop};
  z-index: 2000;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.popup_background};
  box-shadow: ${({ theme }) => theme.shadows.popup};
  border-radius: 4px;
  margin: auto;
  width: 100%;
  padding: 0;
  position: relative;
  max-width: 690px;
  box-sizing: border-box;
  max-height: 100vh;
`;

const Title = styled.h2`
  margin: 0;
  padding: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};
`;

const CloseButton = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  position: absolute;
  top: 0;
  right: 0;
  outline: none;

  svg {
    color: ${(props) => props.theme.colors.font2};
  }
`;

const Body = styled.div<{ noInnerPadding?: boolean }>`
  padding: ${({ noInnerPadding }) =>
    !noInnerPadding ? "0 24px 24px 24px" : null};
  width: 100%;
  box-sizing: border-box;
`;

interface AddModalProps extends PropsWithChildren<any> {
  title: string;
  onClose?: () => void;
  noInnerPadding?: boolean;
}

const AddModal: FC<AddModalProps> = ({
  title,
  onClose,
  children,
  noInnerPadding,
}) => {
  useKeyPress("Escape", (e) => {
    e.preventDefault();
    if (typeof onClose !== "undefined") onClose();
  });
  return (
    <BackDrop>
      <Modal>
        <Title>{title}</Title>
        <CloseButton type="button" onClick={onClose}>
          <IconClose />
        </CloseButton>
        <SimpleBar
          autoHide={false}
          style={{ maxHeight: "calc(100vh - 48px - 1.5rem)" }}
        >
          <Body noInnerPadding={noInnerPadding}>{children}</Body>{" "}
        </SimpleBar>
      </Modal>
    </BackDrop>
  );
};

export default AddModal;
