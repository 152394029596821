import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setActiveAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{ runway_id: number; active: boolean }>
) => {
  const index = state.runways.findIndex(
    (item) => item.runwayId === action.payload.runway_id
  );
  if (index === -1) return;
  state.runways[index].active = action.payload.active;
};

export default setActiveAction;
