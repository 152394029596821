const login = {
  user: "Пользователь",
  password: "Пароль",
  sign_in: "Войти",
  unauthorized: "Не верные данные.",
  error: {
    logged: "Пользователь был авторизован с другого устройства!",
  },
};

export default login;
