import { FC } from "react";
import SubTitle from "../../SubTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import styled from "styled-components";
import AddApron from "./AddApron";
import ApronItem from "./ApronItem";
import Helper from "./Helper";
import { Group } from "../../styles";
import { useTranslation } from "react-i18next";
import SoloInput from "./SoloInput";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const ApronStatus: FC = () => {
  const selected = useSelector(
    (state: RootState) => state.snowtamForm.apron_status
  );

  const aprons = useSelector(
    (state: RootState) => state.general.user?.airport.aprons
  );
  const items = Object.values(selected);
  const { t } = useTranslation("data_form");

  const rendered: number[] = [];
  return (
    <Group>
      <SubTitle label={t`status_apron`} endAdornment={<Helper />} />
      <Row>
        {aprons && aprons.length > 0 ? (
          <>
            {items && items.length > 0
              ? items.map((item, index) => {
                  const selected = [...rendered];
                  rendered.push(item.id);
                  return (
                    <ApronItem
                      key={`sbi${item.id}`}
                      item={item}
                      selected={selected}
                      index={index}
                    />
                  );
                })
              : null}

            <AddApron />
          </>
        ) : (
          <SoloInput />
        )}
      </Row>
    </Group>
  );
};

export default ApronStatus;
