import { UserRoles } from "../Router";
import API from "./API";

type CreateUserInput = {
  name: string;
  phone?: string;
  login: string;
  password: string;
  role: UserRoles;
};

type UpdateUserInput = {
  id: number;
  name?: string;
  phone?: string;
  login?: string;
  password?: string;
  status?: any;
  role: UserRoles;
};

class Users {
  private api: typeof API;

  constructor() {
    this.api = API;
  }

  async createOne(data: CreateUserInput) {
    return this.api.post("/api/users", {
      ...data,
    });
  }

  async updateOne(data: UpdateUserInput) {
    return this.api.put("/api/users", {
      ...data,
    });
  }

  async deleteOne(id: number) {
    return this.api.delete("/api/users/" + id);
  }

  async findAll() {
    return this.api.get(`/api/users`).then((res: any) => res?.data);
  }
}

export default new Users();
