import { FC } from "react";
import { DialogYesNo } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

interface ConnectionLostProps {
  onClose: () => void;
}
const ConnectionLost: FC<ConnectionLostProps> = (props) => {
  const { t } = useTranslation(["data_form", "common"]);

  return (
    <DialogYesNo
      title={t`common:errors.warning`}
      onApply={props.onClose}
      yesText={"OK"}
    >
      <p
        style={{
          textAlign: "center",
          fontSize: "20px",
          lineHeight: "24px",
          marginBottom: "0",
        }}
      >
        {t`common:errors.connection_lost`} <br />
        {t`common:errors.repeat_later`}
      </p>
    </DialogYesNo>
  );
};

export default ConnectionLost;
