import { FC } from "react";
import styled from "styled-components";
import { LabeledInput } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setFrictionFormValue } from "../../../../redux/snowtamformReducer";

const Container = styled.div`
  width: 48%;
  display: flex;

  & > div:not(:last-child) {
    margin-right: 24px;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 478px) {
    width: 100%;
    flex-direction: column;
    & > div:not(:last-child) {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
`;
const Values: FC<{ runwayId: number }> = ({ runwayId }) => {
  const values = useSelector((state: RootState) => {
    return state.snowtamForm.friction_form?.find(
      (item) => item.runwayId === runwayId
    );
  });

  const dispatch = useDispatch();

  // e.target.value.replace(/[^0-9]/gi, "")
  return (
    <Container>
      <LabeledInput
        name={"friction_third_1"}
        startLabel={"1/3"}
        value={values?.first ?? ""}
        onChange={(e: any) => {
          dispatch(
            setFrictionFormValue({
              runwayId,
              third: 1,
              value: e.target.value.replace(/[^0-9]/gi, ""),
            })
          );
        }}
        inputProps={{
          maxLength: 2,
        }}
      />
      <LabeledInput
        name={"friction_third_2"}
        startLabel={"2/3"}
        value={values?.second ?? ""}
        inputProps={{
          maxLength: 2,
        }}
        onChange={(e: any) => {
          dispatch(
            setFrictionFormValue({
              runwayId,
              third: 2,
              value: e.target.value.replace(/[^0-9]/gi, ""),
            })
          );
        }}
      />
      <LabeledInput
        name={"friction_third_3"}
        startLabel={"3/3"}
        value={values?.third ?? ""}
        inputProps={{
          maxLength: 2,
        }}
        onChange={(e: any) => {
          dispatch(
            setFrictionFormValue({
              runwayId,
              third: 3,
              value: e.target.value.replace(/[^0-9]/gi, ""),
            })
          );
        }}
      />
    </Container>
  );
};

export default Values;
