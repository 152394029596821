import { createContext, FC } from "react";
import { FrictionEquipmentEntity } from "../../../api/friction-equipment.types";
import useGetFrictionEquipment from "../hooks/useGetFrictionEquipment";

type FrictionEquipmentContextType = {
  refetch: () => Promise<void>;
  equipment?: FrictionEquipmentEntity[];
  loading_equipment: boolean;
  airport_code: string;
};

type DataProviderProps = {
  code: string;
};

export const DataContext = createContext<FrictionEquipmentContextType>(
  {} as FrictionEquipmentContextType
);

const DataProvider: FC<DataProviderProps> = ({ code, children }) => {
  const {
    friction_equipment,
    refetch,
    loading: loading_equipment,
  } = useGetFrictionEquipment({
    airport_code: code,
  });

  return (
    <DataContext.Provider
      value={{
        refetch,
        loading_equipment,
        equipment: friction_equipment,
        airport_code: code,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
