import { FC } from "react";
import { LabeledCheckbox } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setSnowstorm } from "../../../../redux/snowtamformReducer";

const SnowStormCheck: FC<{ title: string; name: string; runwayId: number }> = ({
  title,
  name,
  runwayId,
}) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamForm.snowstorm.findIndex(
      (item) => item.id === runwayId
    );
    return state.snowtamForm.snowstorm[index];
  });
  const dispatch = useDispatch();
  return (
    <LabeledCheckbox
      _id={"drifting_snow"}
      title={title}
      caption={name}
      value={value?.value}
      onChange={(val) => dispatch(setSnowstorm({ runwayId, value: val }))}
    />
  );
};
export default SnowStormCheck;
