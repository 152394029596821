import { FC } from "react";
import { CheckButton } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setSand } from "../../../../redux/snowtamform/snowtamformv2Reducer";

const SandCheck: FC<{ title: string; runwayId: number }> = ({
  title,
  runwayId,
}) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamFormV2.sand.findIndex(
      (obj) => obj.id === runwayId
    );
    return state.snowtamFormV2.sand[index];
  });
  const dispatch = useDispatch();
  return (
    <CheckButton
      _id={"loose_sand"}
      label={title}
      value={value?.value}
      onChange={(val) => dispatch(setSand({ runwayId, value: val }))}
    />
  );
};
export default SandCheck;
