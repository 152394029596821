import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IconDiagram } from "@snowtam/ui";
import { RequestStatus } from "../../types/status";
import { setCanAdd } from "../../redux/appReducer";
import PublicationCard from "../PublicationCard";

const Container = styled.div`
  margin-bottom: 40px;
  background: ${(props) => props.theme.colors.blocks};
  border: 1px solid ${(props) => props.theme.colors.stroke_block};
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
`;

const ErrorParagraph = styled.p`
  padding: 0 24px;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

const Title = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};
  align-items: center;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};

  svg {
    margin-right: 16px;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
  }

  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

const blockedStatuses = [
  RequestStatus.New,
  RequestStatus.InProgress,
  RequestStatus.Sent,
  RequestStatus.NotSent,
];

interface ControlPublicationProps {
  data?: any;
  refetch?: () => void;
  error?: any;
}

const ControlPublication: FC<ControlPublicationProps> = ({
  data,
  refetch,
  error,
}) => {
  const [errorCard, setErrorCard] = useState(false);
  const { t } = useTranslation(["common", "requests"]);

  useEffect(() => {
    if (error?.type === "connection") {
      setErrorCard(true);
    }
  }, [error]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (data && typeof data.status !== "undefined") {
      if (blockedStatuses.includes(data.status)) {
        dispatch(
          setCanAdd({
            canAdd: false,
            last_id: data.id,
            last_status: data.status,
          })
        );
      } else {
        dispatch(setCanAdd({ canAdd: true }));
      }
    }
  }, [data, dispatch]);
  return (
    <Container>
      {data && !data.error && !errorCard ? (
        <PublicationCard data={data} refetch={refetch} />
      ) : null}
      {(!data || data.error) && !errorCard ? (
        <>
          <Title>
            <IconDiagram />
            {t`requests:publication_control`}
          </Title>
          <ErrorParagraph>{t`requests:no_request`}</ErrorParagraph>
        </>
      ) : null}
      {errorCard ? (
        <>
          <Title>
            <IconDiagram />
            {t`requests:publication_control`}
          </Title>
          <ErrorParagraph>{t`connection_error`}</ErrorParagraph>
        </>
      ) : null}
    </Container>
  );
};
export default ControlPublication;
