import { useDispatch, useSelector } from "react-redux";
import { setState, setUserLoading } from "../redux/generalReducer";
import { useEffect } from "react";
import { RootState } from "../redux";

const useQueryUser = (): void => {
  const user = useSelector((state: RootState) => state.general.user);
  const loading = useSelector((state: RootState) => state.general.userLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!user?.role && loading && !!token) {
      fetch(`/api/user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          auth: `Bearer ${token}`,
        },
        cache: "no-store",
      })
        .then((res) => {
          if (!res.ok) {
            localStorage.removeItem("token");
            dispatch(setUserLoading(false));
            window.location.replace("/");
            return false;
          }

          return res.json();
        })
        .then((res) => {
          if (res) {
            dispatch(setState({ user: res, userLoading: false }));
          } else {
            localStorage.removeItem("token");
            dispatch(setUserLoading(false));
          }
        });
    }
    if (!token) {
      dispatch(setUserLoading(false));
    }
  }, [user, loading, dispatch]);
};
export default useQueryUser;
