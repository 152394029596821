import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { DropDownItem } from "@snowtam/ui";
import { useMemo } from "react";

export const EMPTY_ITEM = { id: 0, value: "" };

export const ALL_ITEM = {
  id: -1,
  value: "ALL",
};

type GetSelectionItemsOptions = {
  withAll?: boolean;
};
const useGetSelectionItems = (options?: GetSelectionItemsOptions) => {
  const twyStatusItems = useSelector(
    (state: RootState) => state.snowtamFormV2.taxiway_status
  );
  const taxiways = useSelector(
    (state: RootState) => state.general.user?.airport.taxiways
  );

  let items: DropDownItem[] = useMemo(
    () => [
      ...(taxiways?.map((item) => ({ id: item.id, value: item.code })) || []),
    ],
    [taxiways]
  );

  const selected = twyStatusItems.map((item) => item.id);

  if ((!selected || selected.length <= 1) && options?.withAll) {
    items = [ALL_ITEM, ...items];
  }
  items = [EMPTY_ITEM, ...items];

  items = items.filter((item) => !selected.includes(item.id));

  return items;
};

export default useGetSelectionItems;
