const requests = {
  publication_control: "Контроль издания",
  sending_data: "Данные отправки",
  operator: "Оператор",
  send_at: "Дата и время отправки",
  processing_data: "Данные обработки",
  navigator: "Штурман",
  phone: "Телефон",
  snowtam: "SNOWTAM",
  snowtam_number: "Номер SNOWTAM",
  snowtam_text: "Текст SNOWTAM",
  published_date: "Дата и время публикации",
  comment: "Комментарий",
  no_request: "Нет действующего SNOWTAM",
  decline: "Отменить заявку",
  show: "Посмотреть",
  request: "Заявка",
  status_caption: "Статус",
  date_time: "Дата / Время",
  published_date_short: "Дата публикации",
  request_declined: "Заявка отменена",
  correct_resubmit: "Внести исправления и отправить повторно",
  upload_to_form: "Загрузить в форму",
  empty: "Заявки не найдены",
  filter: "Сортировка списка",
  filter_mobile: "Сортировка",
  show_history: "Показать историю",
  filter_status: {
    all: "Все заявки",
    sent: "Отправлена",
    published: "Издана",
    in_progress: "В работе",
    declined: "Отклонена",
    expired: "Завершена",
    canceled: "Аннулирована",
  },
  filter_date: {
    all: "Все время",
    today: "Сегодня",
    week: "Неделя",
    month: "Месяц",
    year: "Год",
  },
  status_text: {
    new: "Заявка отправлена",
    declined: "Заявка отменена",
    rejected: "Заявка отклонена",
    in_progress: "Заявка в работе",
    published: "Заявка издана",
    expired: "Заявка завершена",
    canceled: "Заявка аннулирована",
  },
  status: {
    new: "Отправлена",
    declined: "Отменена",
    rejected: "Отклонена",
    in_progress: "В работе",
    published: "Издана",
    expired: "Завершена",
    canceled: "Аннулирована",
  },
  hours_short: "ч",
  minutes_short: "мин",
  seconds_short: "сек",
  until_expire: "До окончания",
};

export default requests;
