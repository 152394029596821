import { FC } from "react";
import { Body, BoldSpan, Container, TextBlock } from "./labelStyles";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { UTC_DATETIME_FORMAT } from "../../settings/app.constants";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

type DeclinedProps = {
  operator: string;
  date: string;
  comment?: string;
};

const Declined: FC<DeclinedProps> = ({ operator, date, comment }) => {
  const { t } = useTranslation("requests");
  return (
    <Container>
      <Body>
        {!comment ? (
          <TextBlock>
            <span style={{ marginRight: "16px" }}>{t`operator`}</span>
            <BoldSpan>{operator}</BoldSpan>
          </TextBlock>
        ) : null}
        <TextBlock>
          <span style={{ marginRight: "16px" }}>{t`send_at`}</span>
          <BoldSpan>
            {dayjs.utc(date, "X").format(UTC_DATETIME_FORMAT)}
          </BoldSpan>
        </TextBlock>
        {comment ? (
          <TextBlock>
            <span style={{ marginRight: "16px" }}>{t`comment`}</span>
            <BoldSpan>{comment}</BoldSpan>
          </TextBlock>
        ) : null}
      </Body>
    </Container>
  );
};

export default Declined;
