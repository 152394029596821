import { FC } from "react";
import Created from "./Created";
import Navigator from "./Navigator";
import Snowtam from "./Snowtam";
import Comment from "./Comment";
import { RequestModel } from "../../../@types/models";
import { RequestStatus } from "../../types/status";

type DataProps = {
  data: RequestModel;
};

const Data: FC<DataProps> = ({ data }) => {
  return (
    <>
      <Created operator={data.operator} date={data.created_date} />
      {data.navigator_id ? (
        <Navigator
          navigator={data.navigator_name || ""}
          phone={data.navigator_phone || ""}
        />
      ) : null}
      {(data.status === RequestStatus.Published ||
        data.status === RequestStatus.Expired) &&
      data.snowtam_id ? (
        <Snowtam snowtam={data.snowtam_id || ""} date={data.published_date} />
      ) : null}
      {data.status === RequestStatus.Rejected && data.decline_comment ? (
        <Comment comment={data.decline_comment} />
      ) : null}
    </>
  );
};
export default Data;
