import { FC } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import RunwayItem from "./RunwayItem";

const Container = styled.div`
  display: flex;
`;

interface RunwaysSelectorProps {
  onChange: () => void;
}

const RunwaysSelector: FC<RunwaysSelectorProps> = (props) => {
  const runways = useSelector(
    (state: RootState) => state.general.user?.airport.runways
  );

  return (
    <Container>
      {runways?.map((runway) => (
        <RunwayItem
          key={runway.id}
          runway_id={runway.id}
          name={runway.name}
          onChange={props.onChange}
        />
      ))}
    </Container>
  );
};

export default RunwaysSelector;
