import { FC, memo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 2px solid ${(props) => props.theme.colors.rcam_outline}; ;;
`;

const TTopHeadCol = styled.th`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  background: ${(props) => props.theme.colors.rcam_header};
  border: 2px solid ${(props) => props.theme.colors.rcam_outline};
  text-align: center;
`;

const TSubHeadCol = styled.th`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};
  background: ${(props) => props.theme.colors.rcam_subheader1};
  border-left: 2px solid ${(props) => props.theme.colors.rcam_outline};
  border-right: 2px solid ${(props) => props.theme.colors.rcam_outline};
  text-align: center;
`;

const TableColumnTop = styled.td`
  border: 1px solid ${(props) => props.theme.colors.rcam_outline};
  padding: 10px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};

  &:nth-child(2) {
    border-right: 2px solid ${(props) => props.theme.colors.rcam_outline};
  }
`;

const TableColumn = styled.td`
  border: 1px solid ${(props) => props.theme.colors.rcam_outline};
  padding: 10px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};
  vertical-align: top;

  &:nth-child(2) {
    border-right: 2px solid ${(props) => props.theme.colors.rcam_outline};
    text-align: left;
  }

  ul {
    padding: 0 0 0 15px;
    margin: 0;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
const Annotation = styled.h4`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.font1};
  padding: 8px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};
  margin-bottom: 8px;
  b {
    padding: 8px 0;
  }
`;

const AnnotationText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  b {
    padding: 8px 0;
  }
  hr {
    border: none;
    height: 1px;
    background: ${(props) => props.theme.colors.separator};
    margin: 16px 0 8px 0;
  }
`;
const RCAMTablet: FC = () => {
  const { t } = useTranslation("helper");

  return (
    <Body>
      <Table>
        <thead>
          <tr>
            <TTopHeadCol colSpan={4}>{t`rcam.maintitle`}</TTopHeadCol>
          </tr>
          <tr>
            <TSubHeadCol colSpan={2}>{t`rcam.subtitle1`}</TSubHeadCol>
            <TSubHeadCol colSpan={2}>{t`rcam.subtitle2`}</TSubHeadCol>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableColumnTop align="center">{t`rcam.rwy_status_code`}</TableColumnTop>
            <TableColumnTop align="center">{t`rcam.rwy_descr`}</TableColumnTop>
            <TableColumnTop align="center">{t`rcam.rwy_comment`}</TableColumnTop>
            <TableColumnTop align="center">{t`rcam.pilot_report`}</TableColumnTop>
          </tr>
          <tr>
            <TableColumn>6</TableColumn>
            <TableColumn>
              <ul>
                <li>{t`rcam.dry_rwy`}</li>
              </ul>
            </TableColumn>
            <TableColumn>--------</TableColumn>
            <TableColumn>--------</TableColumn>
          </tr>
          <tr>
            <TableColumn>5</TableColumn>
            <TableColumn>
              <ul>
                <li>{t`rcam.frost`}</li>
                <li>{t`rcam.wet`}</li>
              </ul>
              <b>
                <i>{t`rcam.under3mm`}:</i>
              </b>
              <ul>
                <li>{t`rcam.slush`}</li>
                <li>{t`rcam.drysnow`}</li>
                <li>{t`rcam.wetsnow`}</li>
              </ul>
            </TableColumn>
            <TableColumn>{t`rcam.comment1`}</TableColumn>
            <TableColumn>{t`rcam.good`}</TableColumn>
          </tr>
          <tr>
            <TableColumn>4</TableColumn>
            <TableColumn>
              <b>
                <i>{t`rcam.temp15`}:</i>
              </b>
              <ul>
                <li>{t`rcam.comp_snow`}</li>
              </ul>
            </TableColumn>
            <TableColumn>{t`rcam.comment2`}</TableColumn>
            <TableColumn>{t`rcam.fromgoodtomiddle`}</TableColumn>
          </tr>
          <tr>
            <TableColumn>3</TableColumn>
            <TableColumn>
              <ul>
                <li>{t`rcam.wet_rwy`}</li>
                <li>{t`rcam.dry_wet_snow`}</li>
              </ul>
              <b>
                <i>{t`rcam.more3mm`}:</i>
              </b>
              <ul>
                <li>{t`rcam.drysnow`}</li>
                <li>{t`rcam.wetsnow`}</li>
              </ul>
              <b>
                <i>{t`rcam.tempmore15`}:</i>
              </b>
              <ul>
                <li>{t`rcam.comp_snow`}</li>
              </ul>
            </TableColumn>
            <TableColumn>{t`rcam.comment3`}</TableColumn>
            <TableColumn>{t`rcam.medium`}</TableColumn>
          </tr>
          <tr>
            <TableColumn>2</TableColumn>
            <TableColumn>
              <b>
                <i>{t`rcam.more3mmwater`}:</i>
              </b>
              <ul>
                <li>{t`rcam.std_water`}</li>
                <li>{t`rcam.slush`}</li>
              </ul>
            </TableColumn>
            <TableColumn>{t`rcam.comment4`}</TableColumn>
            <TableColumn>{t`rcam.frommedtobad`}</TableColumn>
          </tr>
          <tr>
            <TableColumn>1</TableColumn>
            <TableColumn>
              <ul>
                <li>{t`rcam.ice`}</li>
              </ul>
            </TableColumn>
            <TableColumn>{t`rcam.comment5`}</TableColumn>
            <TableColumn>{t`rcam.bad`}</TableColumn>
          </tr>
          <tr>
            <TableColumn>0</TableColumn>
            <TableColumn>
              <ul>
                <li>{t`rcam.wetice`}</li>
                <li>{t`rcam.water_comp_snow`}</li>
                <li>{t`rcam.dry_wet_comp_snow`}</li>
              </ul>
            </TableColumn>
            <TableColumn>{t`rcam.comment6`}</TableColumn>
            <TableColumn>{t`rcam.verybad`}</TableColumn>
          </tr>
        </tbody>
      </Table>
      <Annotation>{t`rcam.annotation.header`}</Annotation>
      <AnnotationText>
        <b>
          <i>{t`rcam.annotation.header1`}</i>
        </b>
        <i>{t`rcam.annotation.annotation1`}</i>
        <hr />
        <b>
          <i>{t`rcam.annotation.header2`}</i>
        </b>
        <i>{t`rcam.annotation.annotation2`}</i>
      </AnnotationText>
    </Body>
  );
};

export default memo(RCAMTablet);
