import { FC } from "react";
import styled from "styled-components";
import { IconConnectionError } from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.colors.blocks};
  border: 1px solid ${(props) => props.theme.colors.stroke_block};
  box-sizing: border-box;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Header = styled.h1`
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.font1};
  margin: 0;
  padding: 16px 0;
`;

const Caption = styled.p`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.font1};
`;
const ConnectionErrorCard: FC = () => {
  const { t } = useTranslation("common");
  return (
    <Container>
      <IconConnectionError />
      <Header>{t`errors.warning`}</Header>
      <Caption>{t`errors.connection_lost`}</Caption>
    </Container>
  );
};

export default ConnectionErrorCard;
