import { FC, memo } from "react";
import styled from "styled-components";
import {
  GroupTitle,
  IconInformation,
  IconAirplane,
  IconBurger,
  IconApron,
  IconCard,
} from "@snowtam/ui";
import { ApronsType, TaxiwaysType } from "../../../@types/models";
import LastRequest from "./LastRequest";
import { useTranslation } from "react-i18next";
import RunwaysTable from "./RunwaysTable";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

const Container = styled.div`
  padding: 0 24px;
  background: ${(props) => props.theme.colors.blocks};
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.stroke_block};
`;

const BorderedContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};
  &:last-child {
    border-bottom: none;
  }
`;

const Wrapper = styled.div`
  padding: 1rem;
`;
const Title = styled(GroupTitle)`
  margin-bottom: 0;
`;
const AdminInfo: FC = () => {
  const airport = useSelector(
    (state: RootState) => state.general.user?.airport
  );
  const { t } = useTranslation("information");
  return (
    <>
      {airport ? (
        <Wrapper>
          <Container>
            <Title
              label={`${airport.name} (${airport.code})`}
              icon={<IconInformation />}
            />
            <BorderedContainer>
              <IconCard
                title={`${t`runways`} (${airport.runways.length})`}
                icon={<IconAirplane />}
              >
                <RunwaysTable runways={airport.runways} />
              </IconCard>
            </BorderedContainer>
            <BorderedContainer>
              <IconCard
                title={`${t`taxiways`} (${airport.taxiways.length})`}
                caption={airport.taxiways
                  .map((item: TaxiwaysType) => item.code)
                  .join(" | ")}
                icon={<IconBurger />}
              />
            </BorderedContainer>
            <BorderedContainer>
              <IconCard
                title={`${t`aprons`} (${airport.aprons.length})`}
                caption={airport.aprons
                  .map((item: ApronsType) => item.name)
                  .join(" | ")}
                icon={<IconApron />}
              />
            </BorderedContainer>
          </Container>
          <LastRequest />
        </Wrapper>
      ) : null}
    </>
  );
};
export default memo(AdminInfo);
