import { FC, PropsWithChildren, ReactElement } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 50%;
  padding-right: 1rem;
  @media (max-width: 767px) {
    width: 100%;
    min-width: 100%;
    padding-right: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Title = styled.h4`
  margin: 0 0 0 1rem;
  padding: 0;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.font1};
  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

const Caption = styled.p`
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.font3};
`;

interface CardLeftProps extends PropsWithChildren<any> {
  icon: () => ReactElement;
  title: string;
  caption: string;
}

const CardLeft: FC<CardLeftProps> = ({ icon, title, caption, children }) => {
  return (
    <Container>
      <TitleContainer>
        {icon()}
        <Title>{title}</Title>
      </TitleContainer>
      <Caption>{caption}</Caption>
      {children}
    </Container>
  );
};

export default CardLeft;
