import { FC } from "react";
import { CounterInput } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setThirdWidth } from "../../../../redux/snowtamformReducer";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

const CounterContainer = styled.div`
  min-width: 260px;
  width: 100%;
`;
const Width: FC<{ runwayId: number }> = ({ runwayId }) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamForm.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamForm.runways[index]?.width;
  });
  const runways = useSelector(
    (state: RootState) => state.general.user?.airport.runways
  );
  const dispatch = useDispatch();

  const runwayWidth: number | undefined = runways
    ?.filter((item) => item.id === runwayId)
    .pop()?.width;
  const { t } = useTranslation(["data_form"]);

  return (
    <CounterContainer>
      <CounterInput
        name={"reduced_width"}
        label={t`runway_width`}
        value={value || 0}
        onChange={(val) => dispatch(setThirdWidth({ runwayId, value: val }))}
        minValue={0}
        step={5}
        textAdornment={t`common:meter`}
        maxValue={runwayWidth ? runwayWidth : 1000}
        containerStyle={css`
          max-width: unset;
        `}
      />
    </CounterContainer>
  );
};

export default Width;
