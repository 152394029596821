import { FC } from "react";
import SnowStormCheck from "./SnowStormCheck";
import SandCheck from "./SandCheck";
import SnowBankCheck from "./SnowBankCheck";
import TreatedCheck from "./TreatedCheck";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Temperature from "./Temperature";
import { GroupTitle } from "@snowtam/ui";
import MeteoHelper from "../../../DataForm/InfoDialogs/MeteoHelper";

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin-bottom: 24px;
  & > div {
    flex: 1;
  }
  @media (max-width: 992px) {
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 10px;
    & > div:nth-child(-1n + 3) {
      grid-column: span 4;
    }
    & > div:nth-last-child(2) {
      grid-row-start: 2;
      grid-column: 3 / span 4;
    }
    & > div:nth-last-child(1) {
      grid-row-start: 2;
      grid-column: 7 / span 4;
    }
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 8px;
    grid-column-gap: 8px;
    & > div:first-child {
      grid-column: span 2 !important;
    }
    & > div:nth-child(-1n + 3) {
      grid-column: auto;
    }
    & > div:nth-last-child(2) {
      grid-row-start: auto;
      grid-column: auto;
    }
    & > div:nth-last-child(1) {
      grid-row-start: auto;
      grid-column: auto;
    }
  }
`;

const Meteo: FC<{ runwayId: number }> = ({ runwayId }) => {
  const { t } = useTranslation("data_form");

  return (
    <>
      <GroupTitle
        label={t`meteo_conditions`}
        endAdornmentButton={<MeteoHelper />}
      />
      <Row>
        <Temperature title={t`low_temperature`} />
        <SnowStormCheck title={t`snowstorm`} runwayId={runwayId} />
        <SandCheck title={t`sand`} runwayId={runwayId} />
        <SnowBankCheck runwayId={runwayId} />
        <TreatedCheck title={t`treated`} runwayId={runwayId} />
      </Row>
    </>
  );
};
export default Meteo;
