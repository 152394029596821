import { FC } from "react";
import { DropDown } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux";
import { setPollution } from "../../../../../redux/snowtamformReducer";
import { useTranslation } from "react-i18next";
import settings from "../../../../../settings/form";

const Pollution: FC<{ third: 1 | 2 | 3; runwayId: number }> = ({
  third,
  runwayId,
}) => {
  const value = useSelector((state: RootState) => {
    const index = state.snowtamForm.runways.findIndex(
      (item) => item.runwayId === runwayId
    );
    return state.snowtamForm.runways[index]?.thirds[third]?.pollution;
  });
  const dispatch = useDispatch();

  const { t } = useTranslation(["data_form", "common"]);

  const val =
    settings.pollutionDepthItems.find((item) => item.id === value?.id) ??
    settings.pollutionDepthItems.find((item) => item.id === 0);

  return (
    <DropDown
      name={"polution"}
      label={t`data_form:thirds.coverage`}
      items={settings.pollutionDepthItems}
      value={val}
      notChosenText={t`common:not_chosen`}
      onChoose={(val) =>
        dispatch(setPollution({ runwayId, third, value: val }))
      }
    />
  );
};
export default Pollution;
