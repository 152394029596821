import { FC, useState } from "react";
import { IconButton, IconBurger } from "@snowtam/ui";
import Menu from "./Menu";

const BurgerMenu: FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton
        icon={() => <IconBurger />}
        color="transparent"
        onClick={() => setOpen((prev) => !prev)}
      />
      {open ? <Menu onClose={() => setOpen(false)} /> : null}
    </>
  );
};
export default BurgerMenu;
