import { FC, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import New from "./icons/New";
import InProgress from "./icons/InProgress";
import Declined from "./icons/Declined";
import Published from "./icons/Published";
import Expired from "./icons/Expired";
import { IconButton } from "@snowtam/ui";
import Chevron from "./icons/Chevron";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { UserRoles } from "../../Router";
import { RequestStatus } from "../../types/status";
import ExpirationCountdown from "./ExpirationCountdown";

const Container = styled.div`
  display: flex;
  padding: 8px;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};
  align-items: center;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.font1};
  cursor: pointer;

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
  }

  @media (max-width: 767px) {
    font-size: 1rem;
    padding: 8px;
  }
`;

const StatusIcon = styled.div`
  display: flex;
  margin-right: 16px;
  margin-left: 8px;
  width: 1.5rem;
  height: 1.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const RightContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

type TitleProps = {
  status: number;
  request_id: number;
  snowtam_expire?: string;
};

const Title: FC<TitleProps> = ({ status, request_id, snowtam_expire }) => {
  const { t } = useTranslation("requests");
  const history = useHistory();
  const user = useSelector((state: RootState) => state.general.user);
  const statusText = useMemo(() => {
    switch (status) {
      case RequestStatus.New:
        return t`status_text.new`;
      case RequestStatus.InProgress:
      case RequestStatus.Sent:
      case RequestStatus.NotSent:
        return t`status_text.in_progress`;
      case RequestStatus.Void:
        return t`status_text.canceled`;
      case RequestStatus.Declined:
        return t`status_text.declined`;
      case RequestStatus.Rejected:
        return t`status_text.rejected`;
      case RequestStatus.Published:
        return t`status_text.published`;
      case RequestStatus.Expired:
        return t`status_text.expired`;
    }
  }, [status, t]);

  const statusIcon = () => {
    switch (status) {
      case RequestStatus.New:
        return <New />;
      case RequestStatus.InProgress:
      case RequestStatus.Sent:
      case RequestStatus.NotSent:
        return <InProgress />;
      case RequestStatus.Void:
      case RequestStatus.Declined:
      case RequestStatus.Rejected:
        return <Declined />;
      case RequestStatus.Published:
        return <Published />;
      case RequestStatus.Expired:
        return <Expired />;
    }
  };

  return (
    <Container
      onClick={() =>
        history.push(
          `${
            user?.role === UserRoles.Manager ? "/admin" : ""
          }/history/${encodeURIComponent(request_id)}`,
          { fromMain: true }
        )
      }
    >
      <StatusIcon>{statusIcon()}</StatusIcon>
      {statusText}
      <RightContainer>
        {status === RequestStatus.Published &&
        snowtam_expire &&
        window.innerWidth > 767 ? (
          <ExpirationCountdown snowtam_expire={snowtam_expire} />
        ) : null}
        <IconButton
          size="small"
          icon={() => <Chevron />}
          className="request_link"
          color="transparent"
        />
      </RightContainer>
    </Container>
  );
};
export default Title;
