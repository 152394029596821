import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";
import initialState from "../initialState";

const setFormAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<SnowtamFormStateTypeV2>
) => {
  return {
    ...initialState,
    ...action.payload,
    version: 2,
    pause: true,
  };
};
export default setFormAction;
