import {FC, useMemo} from "react";
import {Helmet} from "react-helmet-async";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import {useTranslation} from "react-i18next";
import {UserRoles} from "../../Router";

const TitleProvider: FC = () => {
  const role = useSelector((state: RootState) => state.general.user?.role);
  const {t} = useTranslation('common');

  const title = useMemo(() => {
    switch (role) {
      case UserRoles.Manager:
        return t`title_manager`
      case UserRoles.Operator:
        return t`title_operator`
      default:
        return t`title_common`
    }
  }, [role, t])

  return <Helmet>
    <title>{title}</title>
  </Helmet>
}

export default TitleProvider
