import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { DropDownItem } from "@snowtam/ui";
import { SnowtamFormStateTypeV2 } from "../../../../@types/form";

const setSnowbankRunwayPositionAction = (
  state: Draft<SnowtamFormStateTypeV2>,
  action: PayloadAction<{
    runwayId: number;
    value: DropDownItem;
  }>
) => {
  const index = state.snowbank_runway_position.findIndex(
    (item) => item.runwayId === action.payload.runwayId
  );
  state.snowbank_runway_position[index].position = action.payload.value;
  state.pause = true;
};

export default setSnowbankRunwayPositionAction;
