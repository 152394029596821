const information = {
  information: "Information",
  operator: "Operator",
  airport: "Airport",
  runways: "Runways",
  taxiways: "Taxiways",
  aprons: "Aprons",
  length: "Length",
  width: "Width",
  meter: "m",
};

export default information;
