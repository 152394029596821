import {
  DesktopMenu,
  DesktopMenuItemsType,
  DialogYesNo,
  IconExit,
  IconHistory,
  IconInformation,
  IconPerson,
  IconRadar,
  IconSettings,
} from "@snowtam/ui";
import preval from "preval.macro";
import React, { FC, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import packageJson from "../../../package.json";
import API from "../../api/API";
import { RootState } from "../../redux";
import { collapseSidebar, setLanguage } from "../../redux/generalReducer";
import Auth from "../../api/auth";

const menuItems: (t: TFunction<"menu">) => DesktopMenuItemsType[] = (t) => [
  {
    link: "/admin",
    label: t`admin_info`,
    icon: IconInformation,
    id: "link_information",
  },
  {
    link: "/admin/history",
    label: t`admin_history`,
    icon: IconHistory,
    id: "link_history",
  },
  {
    link: "/admin/users",
    label: t`admin_users`,
    icon: IconPerson,
    id: "link_operators",
  },
  {
    link: "/friction_equipment",
    label: t`Оборудование Ксц`,
    icon: IconRadar,
    id: "link_friction_equipment",
  },
  {
    link: "/admin/settings",
    label: t`menu:settings`,
    icon: IconSettings,
    id: "link_settings",
  },
  {
    link: "#",
    label: t`exit`,
    icon: IconExit,
    isExit: true,
    id: "link_exit",
  },
];

const buildDate = preval`module.exports = new Date().toUTCString()`;

const pipeLineId = preval`module.exports = process.env.CI_PIPELINE_ID`;

const version = `${packageJson.version}${
  pipeLineId ? "-" + pipeLineId : ""
} ${buildDate}`;

const AdminMenu: FC = () => {
  const language = useSelector(
    (state: RootState) => state.general.settings?.language
  );
  const collapsed = useSelector(
    (state: RootState) => state.general.settings.sidebar_collapsed
  );
  const [exitDialog, setExitDialog] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation("menu");

  const handleCollapse = (val: boolean) => {
    API.putSetting({
      sidebar_collapsed: !collapsed,
    }).then();
    dispatch(collapseSidebar(val));
  };

  return (
    <>
      <DesktopMenu
        items={menuItems(t)}
        collapsible
        withLanguageSelector={false}
        language={language || "ru"}
        onChangeLanguage={(val) => {
          dispatch(setLanguage(val));
        }}
        onExit={() => setExitDialog(true)}
        buildString={version}
        collapsed={collapsed}
        onCollapse={handleCollapse}
        hideOn={"mobile"}
      />
      {exitDialog ? (
        <DialogYesNo
          title={t`common:exit_title`}
          onApply={() => {
            Auth.logout().finally(() => {
              localStorage.clear();
              sessionStorage.clear();
              window.location.replace("/");
            });
          }}
          caption={t`common:exit_label`}
          yesText={t`common:yes`}
          noText={t`common:no`}
          onClose={() => setExitDialog(false)}
        />
      ) : null}
    </>
  );
};

export default AdminMenu;
