import { FC, MutableRefObject, useRef, useState } from "react";
import AddModal from "../../../AddModal";
import {
  CommonButton,
  MoreParagraph,
  InfoPopupWindow,
  useOutsideClicker,
} from "@snowtam/ui";
import { useTranslation } from "react-i18next";

const PollutionDepthPopup: FC<{
  onClose: () => void;
  toggler?: MutableRefObject<any>;
}> = ({ onClose, toggler }) => {
  const popupRef = useRef(null);
  const [more, setMore] = useState(false);
  useOutsideClicker(popupRef, onClose, toggler);
  const { t } = useTranslation("helper");

  return (
    <div ref={popupRef}>
      <InfoPopupWindow
        title={t`pollution_depth.title`}
        text={<>{t`pollution_depth.line1`}</>}
        onClose={onClose}
        more
        moreText={t`common.annotation`}
        onMoreClick={() => setMore(true)}
        noOutSideClick
        hide={more}
      />
      {more ? (
        <AddModal title={t`common.annotation`} onClose={() => onClose()}>
          <MoreParagraph>{t`pollution_depth.note1`}</MoreParagraph>
          <MoreParagraph>
            {t`pollution_depth.note2`}
            <br />
            {t`pollution_depth.note3`}
            <br />
            {t`pollution_depth.note4`}
            <br />
            {t`pollution_depth.note5`}
          </MoreParagraph>
          <MoreParagraph>{t`pollution_depth.note6`}</MoreParagraph>
          <MoreParagraph>{t`pollution_depth.note7`}</MoreParagraph>
          <MoreParagraph>{t`pollution_depth.note8`}</MoreParagraph>
          <CommonButton label={t`common.close`} onClick={() => onClose()} />
        </AddModal>
      ) : null}
    </div>
  );
};

export default PollutionDepthPopup;
