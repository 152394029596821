import { FC } from "react";
import styled from "styled-components";
import { DropDown, DropDownItem } from "@snowtam/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { setFrictionFormDevice } from "../../../../redux/snowtamformReducer";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 48%;
  @media (max-width: 1024px) {
    margin-top: 12px;
    width: 100%;
  }
`;

const items: DropDownItem[] = [
  {
    id: 0,
    value: "",
    data: "",
  },
  {
    id: 1,
    value: "ATT2",
    data: "ATT2",
  },
  {
    id: 2,
    value: "SKIDDOMETER BV11",
    data: "SKIDDOMETER BV11",
  },
  {
    id: 3,
    value: "DEP5A",
    data: "DEP5A",
  },
];
const DeviceSelector: FC<{ runwayId: number }> = ({ runwayId }) => {
  const value = useSelector((state: RootState) => {
    return state.snowtamForm.friction_form?.find(
      (item) => item.runwayId === runwayId
    );
  });

  const { t } = useTranslation("data_form");
  const val = items.find((item) => item.data === value?.device);

  const dispatch = useDispatch();

  const handleChange = (val: DropDownItem) => {
    dispatch(
      setFrictionFormDevice({
        runwayId,
        value: val.data,
      })
    );
  };

  const detectError = (): boolean => {
    return !!(
      value &&
      ((value.first !== "0" && value.first !== "") ||
        (value.second !== "0" && value.second !== "") ||
        (value.third !== "0" && value.third !== "")) &&
      (!value.device || value.device.length === 0)
    );
  };

  return (
    <Container>
      <DropDown
        name={"friction_device"}
        label={t`measuring_device`}
        items={items}
        value={val}
        onChoose={handleChange}
        error={detectError()}
        notChosenText={t`not_chosen`}
      />
    </Container>
  );
};

export default DeviceSelector;
