import { FC, memo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";

const Button = styled.button`
  display: flex;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  background: ${(props) => props.theme.colors.active_light};
  color: ${(props) => props.theme.colors.font_passive};
  margin: 0 24px 0 auto;
  cursor: pointer;
  transition: all 0.125s ease;
  &:hover {
    background: ${(props) => props.theme.colors.active};
    color: ${(props) => props.theme.colors.font_active};
  }
  svg {
    margin-left: 8px;
  }

  @media (max-width: 767px) {
    font-size: 0.75rem;
    margin: 0 16px 0 auto;
    padding: 4px 16px;
  }
`;

const TextSpanDesktop = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;
const TextSpanMobile = styled.span`
  @media (min-width: 767px) {
    display: none;
  }
`;
const ClearButton: FC = () => {
  const [approve, setApprove] = useState(false);
  const { t } = useTranslation(["data_form"]);
  return (
    <>
      <Button id={"clear_form"} type="button" onClick={() => setApprove(true)}>
        <TextSpanDesktop>{t`clean_desktop`}</TextSpanDesktop>
        <TextSpanMobile>{t`clean`}</TextSpanMobile>
      </Button>
      {approve ? <Modal onClose={() => setApprove(false)} /> : null}
    </>
  );
};
export default memo(ClearButton);
